import React, { useState } from 'react'
import { Row, Col, Typography, Form, Radio, Input } from 'antd'
import DropZoneUploader from '../../components/DropZoneUploader/DropZoneUploader';

const { TextArea } = Input 

export default function AdCreative({ adObj, setAdObj, form }){
  const [ adType, setAdType ] = useState('link')

  const handleAdTypeChange = (e) => {
    e.preventDefault()
    setAdType(e.target.value)
  }

  const setDropZoneFile = (file) => {
    setAdObj({...adObj, sponsorLogo: file})
    form.setFieldsValue({
      sponsorLogo:file
    })
  }

  return(
    <Row style={adCreativeStyleProps}>
      <Col span={24}>
        <Typography.Title 
          level={4} 
          style={{
            margin:0
          }}
        >
          Ad Creative
        </Typography.Title>
        <Typography.Text 
          type='secondary'
        >
          What do you want your audience to see?
        </Typography.Text>
      </Col>

      <Col span={24} style={{marginTop:10}}>
        <Form.Item 
          label={<Typography.Text strong>Ad Thumbnail</Typography.Text>}
          name='sponsorLogo'
          rules={[
            {
              required: true,
              message: 'Please add an ad thumbnail',
            },
          ]}
        >
          <DropZoneUploader file={adObj.sponsorLogo} setFile={setDropZoneFile}/>
        </Form.Item>
        <Form.Item 
          label={<Typography.Text strong>Call to Action</Typography.Text>}
          name='callOutText'
          rules={[
            {
              required: true,
              message: 'Please add a CTA',
            },
          ]}
        >
          <TextArea showCount maxLength={25} placeholder="CTA Text" autoSize={{ maxRows: 1 }}/>
        </Form.Item>
        <Form.Item
          label={<Typography.Text strong>Ad Type</Typography.Text>}
          name='sponsorUrlType'
          rules={[
            {
              required: true,
              message: 'Please add an ad type',
            },
          ]}
        >
          <Radio.Group optionType='button' onChange={handleAdTypeChange}>
            <Radio.Button value="link">Link</Radio.Button>
            <Radio.Button value="video">Video</Radio.Button>
            <Radio.Button value="image">Image</Radio.Button>
            <Radio.Button value="coupon">Coupon</Radio.Button>
          </Radio.Group>
        </Form.Item>
        <AdTypeInput adObj={adObj} setAdObj={setAdObj} form={form} adType={adType}/>
      </Col>
    </Row>
  )
}

const AdTypeInput = ({ adObj, setAdObj, form, adType }) => {

  // const handleLinkChange = e => {
  //   setAdDestinationUrl(e.target.value)
  // }
  const setAdDestinationUrl = (file) => {
    setAdObj({...adObj, sponsorUrl: file})
    form.setFieldsValue({
      sponsorUrl:file
    })
  }

  if(adType === 'link'){
    return(
      <Form.Item 
        label={<Typography.Text strong>URL</Typography.Text>}
        name='sponsorUrl'
        rules={[
          {
            required: true,
            message: 'Please add an ad type',
          },
        ]}
        style={{textTransform: 'capitalize'}}
      >
        <Input placeholder="AD URL"/>
      </Form.Item>
    )
  }else{
    return(
      <Form.Item 
        label={<Typography.Text strong>{adType}</Typography.Text>}
        name='sponsorUrl'
        rules={[
          {
            required: true,
            message: 'Please add an ad destination',
          },
        ]}
        style={{textTransform:'capitalize'}}
      >
        <DropZoneUploader file={adObj.sponsorUrl} setFile={setAdDestinationUrl}/>
      </Form.Item>
    )
  }
}

const adCreativeStyleProps = {
  marginTop:20,
  width:'100%',
  background:'white', 
  borderRadius:8, 
  boxShadow:'0px 1px 2px rgba(0,0,0,0.25)', 
  paddingTop: 15,
  paddingBottom: 20,
  paddingLeft: 20,
  paddingRight: 20,
}