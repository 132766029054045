import React,{ useState, useEffect, useContext } from 'react'
import { Col, Empty, Row, Select, Card, Skeleton, Typography } from 'antd'

import BrowseEventCard from './BrowseEventCard'

import { FirebaseContext } from '../../utils/Firebase';

const { Title } = Typography

export default function EventRow(props) {
  const firebase = useContext(FirebaseContext)

  const { title, league, type, limit, end } = props
  const [ events, setEvents ] = useState([])
  const [ isFetchingEvents, setIsFetchingEvents ] = useState(false)
  const [ values, setValues ] = useState(0);
  // const [ showEventRow, setShowEventRow ] = useState(false)
  const [ queryType, setQueryType ] = useState()
  const [ queryValue, setQueryValue ] = useState()

  useEffect(()=>{
    const leagueOrType = type ? 'type' : 'league'
    const leagueOrTypeValue = type ? type : league
    setQueryType(leagueOrType)
    setQueryValue(leagueOrTypeValue)
  },[league, type])

  useEffect(() => {
    setIsFetchingEvents(true)
    if(!queryType || !queryValue) return
    let unsubscribe = firebase.getUpcomingEvents(values,queryType,queryValue,limit,end,setEvents,setIsFetchingEvents)
    return () => unsubscribe
  },[limit, queryType, queryValue, setEvents, setIsFetchingEvents, values])

  // useEffect(() => {
  //   if(events){ 
  //     if(events.length > 0){
  //       setShowEventRow(true)
  //     } 
  //   }
  // },[events])

  const handleChange = value => {
    setValues(value)
  }

  return(
    <Row style={{ marginBottom: 40, width:'100%', overflow:'hidden' }}>
      <Col span={24}>
        <Title level={2}>{title}</Title>
        <Select
          defaultValue={0}
          onChange={handleChange}
          style={{width:120}}
          bordered={false}
          size='large'
        >
          <Select.Option value={0}>This Week</Select.Option>
          <Select.Option value={7}>Next Week</Select.Option>
        </Select>
      </Col>
      {isFetchingEvents 
        ?
          <Row style={{width:'100%', padding:20 }}>
            <Col span={24}>
              {/* <Spin indicator={loadingIcon}/> */}
              <Card
                style={{ width: 300 }}
                actions={[
                  <Skeleton.Button active type='text'/>,
                  <Skeleton.Button active type='text'/>,
                ]}
              >
                <Skeleton avatar active />
              </Card>
            </Col>
          </Row>
        :
          <Row style={{ width:'100%' }}>
            <Row gutter={[16,16]} style={{overflowX:'scroll', width:'100%', flexWrap:'nowrap'}}>
              { events.length > 0 
                ? 
                  events.map(event => {
                    return(
                      <Col key={event.id}>
                        <BrowseEventCard event={event}/>
                      </Col>
                    )
                  }) 
                :
                  <Empty description='No Upcoming Events' />
                }
            </Row>
          </Row>
        }
    </Row>
  )
}