import React,{ useState, useEffect } from 'react'
import { Typography, Row, Col, Image } from 'antd'

export default function BrowseEventsCardCover({partyOne, partyTwo, setImagesLoading, isLoading}){
  const [ coverType, setCoverType ] = useState('split')

  useEffect(()=>{
    if(partyTwo === 'single'){
      setCoverType('single')
    } else {
      setCoverType('split')
    }
  },[partyOne, partyTwo])

  return(
    coverType === 'single' ?
      <SinglePartyEvent partyOne={partyOne} setImagesLoading={setImagesLoading} isLoading={isLoading}/>
    :
      <TwoPartyEvent partyOne={partyOne} partyTwo={partyTwo} setImagesLoading={setImagesLoading} isLoading={isLoading}/>
  )
}

function TwoPartyEvent({partyOne, partyTwo, setImagesLoading, isLoading}) {
  const [ imgOneLoaded, setImgOneLoaded ] = useState(false)
  const [ imgTwoLoaded, setImgTwoLoaded ] = useState(false)

  useEffect(()=>{
    if( partyOne && partyTwo){
      if( !partyOne.logoExists ){
        setImgOneLoaded(true)
      }
      if( !partyTwo.logoExists ){
        setImgTwoLoaded(true)
      }
    }
  },[partyOne, partyTwo])

  useEffect(()=>{
    if( imgOneLoaded && imgTwoLoaded){
      setImagesLoading(false)
    }
  },[ imgOneLoaded, imgTwoLoaded])



  return(
    isLoading ? 
      null
    :
      <Row style={twoPartySplitStyle}>
        <Row style={partyOneContainerStyle}>
          {partyOne.logoExists ? 
              <Col style={{background:partyOne.color, height:'100%'}}>
                <Image 
                  height='120%' 
                  src={partyOne.logo} 
                  alt={partyOne.displayName} 
                  onLoad={()=>setImgOneLoaded(true)} 
                  style={imageStyle}
                />
                <Col style={gradientStyle}/>
              </Col>
            :
              <Row style={{backgroundColor:'white', height:'100%'}}>
                <Col span={24}>
                  <Typography.Text style={teamNameStyle}>
                    {partyOne.name}
                  </Typography.Text>
                </Col>
                <Col span={24}>
                  <Typography.Text style={teamMascotStyle}>
                    {partyOne.mascot}
                  </Typography.Text>
                </Col>
                <Row style={gradientStyle}/>
              </Row>
          }
        </Row>
        <Row style={partyTwoContainerStyle}>
          {partyTwo.logoExists ? 
              <Col style={{background:partyTwo.color, height:'100%'}}>
                <Image 
                  height='120%' 
                  src={partyTwo.logo} 
                  alt={partyTwo.displayName} 
                  onLoad={()=>setImgTwoLoaded(true)} 
                  style={imageStyle}
                />
                <Col style={gradientStyle}/>
              </Col>
            : 
              <Row style={{backgroundColor:'black', height:'100%'}}>
                <Col span={24}>
                  <Typography.Text style={teamNameStyle}>
                    {partyTwo.name}
                  </Typography.Text>
                </Col>
                <Col span={24}>
                  <Typography.Text style={teamMascotStyle}>
                    {partyTwo.mascot}
                  </Typography.Text>
                </Col>
                <Row style={gradientStyle}/>
              </Row>
          }
        </Row>
        <Col style={dividerStyle}/>
      </Row>
  )
}

function SinglePartyEvent({partyOne, setImagesLoading, isLoading}) {
  const [ imgOneLoaded, setImgOneLoaded ] = useState(false)

  useEffect(()=>{
    if(imgOneLoaded) return setImagesLoading(false)
  },[imgOneLoaded])

  return(
    isLoading ? 
      null
    :
      <Row className='single-party'>
          {!partyOne.logoExists 
            ? 
              <Col style={{background:partyOne.color, height:'100%'}}>
                <Image 
                  height='120%' 
                  src={partyOne.logo} 
                  alt={partyOne.displayName} 
                  onLoad={()=>setImgOneLoaded(true)} 
                  style={imageStyle}
                />
                <Col style={gradientStyle}/>
              </Col>
            : 
              <Row style={{backgroundColor:'black', height:'100%'}}>
                <Col span={24}>
                  <Typography.Text style={teamNameStyle}>
                    {partyOne.name}
                  </Typography.Text>
                </Col>
                <Col span={24}>
                  <Typography.Text style={teamMascotStyle}>
                    {partyOne.mascot}
                  </Typography.Text>
                </Col>
                <Row style={gradientStyle}/>
              </Row>
          }
      </Row>
  )
}

const twoPartySplitStyle = {
  position: 'relative', 
  width: '100%',
  height: 150,
  overflow: 'hidden',
  borderRadius: '5px 5px 0 0',
}

const partyOneContainerStyle = {
  color:'white',
  position: 'absolute',
  width: '100%',
  height: '100%',
  clipPath: 'polygon(0% 0%, 60% 0%, 40% 100%, 0% 100%)',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  overflow:'hidden'
}

const partyTwoContainerStyle = {
  color:'white',
  position: 'absolute',
  width: '100%',
  height: '100%',
  clipPath: 'polygon(60% 0%, 100% 0%, 100% 100%, 40% 100%)',
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'right',
}

const imageStyle = {
  position:'relative',
  objectFit:'contain',
  top:'-20%',
}

const teamNameStyle = {
    whiteSpace: 'nowrap',
    position: 'relative',
    fontSize:30,
    fontWeight:500,
    color:'rgba(255,255,255,0.4)',
    padding: 20,
    top: 10,
}

const teamMascotStyle = {
  whiteSpace: 'nowrap',
  position: 'relative',
  fontSize:75, 
  fontWeight:800, 
  lineHeight:0.5, 
  color:'rgba(255,255,255,0.9)',
  padding: 20,
  top: 10,
}

const gradientStyle = {
  position: 'absolute',
  top: 0,
  width: '100%',
  height:'100%',
  background:'rgba(0,0,0,0.5)'
}

const dividerStyle = {
  position:'absolute',
  height: '100%',
  clipPath: 'polygon(60% 0%, 61% 0%, 41% 100%, 40% 100%)',
  background: 'white',
  width:'100%',
}