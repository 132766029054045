import React,{ useState, useEffect, useContext } from 'react'
import { Col, Empty, Row, Card, Skeleton, Typography } from 'antd'
import BrowseEventCard from './BrowseEventCard'
import { FirebaseContext } from '../../utils/Firebase';
import { formatTimestamp } from '../../helpers/Utils';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment'
const { Title } = Typography

export default function TodaysEventsRow({ title, league, type, limit, end }) {
  const firebase = useContext(FirebaseContext)
  const dispatch = useDispatch()
  const events = useSelector(state => state.ducks.todaysEventsList)
  const [ isFetchingEvents, setIsFetchingEvents ] = useState(false)
  // const [ showEventRow, setShowEventRow ] = useState(false)
  const [ queryType, setQueryType ] = useState()
  const [ queryValue, setQueryValue ] = useState()

  useEffect(()=>{
    const leagueOrType = type ? 'type' : 'league'
    const leagueOrTypeValue = type ? type : league
    setQueryType(leagueOrType)
    setQueryValue(leagueOrTypeValue)
  },[league, type])

  useEffect(() => {
    if(events.length > 0){
      setIsFetchingEvents(false)
      return
    }
    setIsFetchingEvents(true)
    if(!queryType || !queryValue) return
    const queryStart = moment().add(0,'day').startOf('day').utc().format()
    const queryEnd = moment(queryStart).add(end,'day').startOf('day').utc().format()
  
    let unsubscribe = firebase.getCollection('events')
      .where(queryType, '==', queryValue)
      .where('scheduledTime', '>=', queryStart)
      .where('scheduledTime', '<', queryEnd)
      .orderBy('scheduledTime', 'asc')
      // .limit(limit)
      .onSnapshot(async snapshot => {
        let eventArr = []
        snapshot.forEach(async snap => {
          let doc = snap.data()
          // console.log('doc',doc)
          if(doc.status === 'unnecessary' || doc.status === 'postponed' || doc.status === 'closed' || doc.status === 'ended' || doc.status === 'abondoned' || doc.status === 'final' || doc.status === 'cancelled') return
          let formattedTimestamp = formatTimestamp(doc.scheduledTimestamp)
          doc = {...doc, id:snap.id, formattedTimestamp }
          eventArr.push(doc)
        })
        // GET BROADCASTER DOCS FROM COLLECTION
        let broadcasterFormattedEventArr = await Promise.all(eventArr.map(async doc => {
          return await firebase.getAllEventBroadcasterDocs(doc.allBroadcasters)
          .then(res=>{
            if(res){
              doc = {...doc, allBroadcasters:res}
            }else {
              doc = {...doc, allBroadcasters: []}
            }
            return doc
          }).catch(err => console.log(err))
        }))
        // GET AGGREGATE HUDDLE COUNT FOR EACH EVENT
        let eventArrWithHuddleCount = await Promise.all(broadcasterFormattedEventArr.map(async doc=>{
          return await firebase.getEventHuddleCount(doc)
          .then(res => {
            if(res){
              doc = {...doc, huddleCount: res.huddleCount}
            }else{
              doc = {...doc, huddleCount: 0}
            }
            return doc
          }).catch(err => console.log(err))
        }))
        dispatch({type:'SET_TODAYS_EVENTS_LIST', payload:eventArrWithHuddleCount})
        setIsFetchingEvents(false)
      },err => console.log(err))

    return () => unsubscribe
  },[limit, queryType, queryValue, events, setIsFetchingEvents])

  // useEffect(() => {
  //   if(events){ 
  //     if(events.length > 0){
  //       setShowEventRow(true)
  //     } 
  //   }
  // },[events])

  return(
    <Row style={{ marginBottom: 40, width:'100%', overflow:'hidden' }}>
      <Col span={24}>
        <Title level={2}>{title}</Title>
      </Col>
      {isFetchingEvents 
        ?
          <Row style={{width:'100%', padding:20 }}>
            <Col span={24}>
              <Card
                style={{ width: 300 }}
                actions={[
                  <Skeleton.Button active type='text'/>,
                  <Skeleton.Button active type='text'/>,
                ]}
              >
                <Skeleton avatar active />
              </Card>
            </Col>
          </Row>
        :
          <Row gutter={[16,16]} style={{overflowX:'scroll', width:'100%', flexWrap:'nowrap'}}>
            { events.length > 0 
              ? 
                events.map(event => {
                  return(
                    <Col key={event.id}>
                      <BrowseEventCard event={event}/>
                    </Col>
                  )
                })
              :
                <Empty description='No Events For this Week' />
            }
          </Row>
      }
    </Row>
  )
}