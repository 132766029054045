import { CheckCircleTwoTone } from '@ant-design/icons';
import { Button, Col, Row, Table, Typography } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FirebaseContext } from '../../utils/Firebase';

export default function SavedAdModal({
  setFormValid,
  selectedAdsArr,
  setSelectedAdsArr
}){
  const firebase = useContext(FirebaseContext)
  const publisherId = useSelector(
    (state) => state.publishing.publishingAs?.id ?? 0
  )
  const [ savedAds, setSavedAds ] = useState()
  const [ isFetching, setIsFetching ] = useState(true)

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => {
        return <Typography.Text>{text}</Typography.Text>
      }
    },
    {
      title: 'Thumbnail',
      dataIndex: 'sponsorLogo',
      key: 'sponsorLogo',
      // align: 'right',
      render: (text,record) => (
        <Col>
          <img style={{height: 50, borderRadius: 5}} src={record.sponsorLogo}/>
        </Col>
      )
    },
    {
      title: 'CTA',
      dataIndex: 'callOutText',
      key: 'callOutText',
      // align: 'right'
    },
    {
      title: 'Ad Type',
      dataIndex: 'sponsorUrlType',
      key: 'sponsorUrlType',
      // align: 'right'
    },
    {
      title: 'Ad Destination',
      dataIndex: 'sponsorUrl',
      key: 'sponsorUrl',
      width: 200,
      ellipsis:true,
      render: (text,record) => {
        if(record.sponsorUrlType === 'link'){
          return(
            <Col>
              <Typography.Text >{text}</Typography.Text>
            </Col>
          )
        }else{
          return(
            <Col>
              <img src={record.sponsorUrl} style={{height:50, borderRadius:5}}/>
            </Col>
          )
        }
      }
      // align: 'right'
    },
    {
      title: 'Audience',
      dataIndex: ['audience','name'],
      key: 'audienceName',
      // align: 'right'
    },
    {
      dataIndex: 'selectAd',
      key: 'selectAd',
      align: 'right',
      width: 120,
      render: (text,record) => (
          <Button type='default' onClick={()=>handleSponsorClick(record)}>Use This Ad</Button>
      )
    },
    {
      dataIndex: '',
      key: 'selected',
      width:50,
      align: 'right',
      render: (text,record) => {
        let match = false
        if(selectedAdsArr){
          selectedAdsArr.map(adObj => {
            if(record.id === adObj.id){
              match = true
            }
          })
        }
        return <CheckCircleTwoTone style={{fontSize:24}} twoToneColor={match ? "#52c41a" : '#F0F0F0'} />
      }
    },
  ]

  useEffect(()=>{
    // if(!adObj.sponsorLogo || !adObj.callOutText || !adObj.sponsorUrlType ){
      // setFormValid(false)
    // }else{
      setFormValid(true)
    // }
  },[])

  // FETCH SAVED ADS + AD SET DATA + AUDIENCE DATA
  useEffect(()=>{
    if(!publisherId)return
    firebase.getCollection('publisher').doc(publisherId).collection('ads')
      .get()
      .then( async snap => {
        let arr = await Promise.all(snap.docs.map(async docRef => {
          let doc, adSet, audience
          doc = docRef.data()
          
          try{
            let adSetRef = await firebase.getCollection('publisher').doc(publisherId).collection('ad-sets').doc(doc.adSetId).get()
            adSet = adSetRef.data()
          }catch(err){
            console.log('ad set Collection error:', err)
          }

          if(adSet && adSet.audienceId){
            try{
              let audienceRef = await firebase.getCollection('publisher').doc(publisherId).collection('audiences').doc(adSet.audienceId).get()
              audience = audienceRef.data()
            }catch(err){
              console.log('audience Collection error:', err)
            }
          }

          doc = {...doc, id: docRef.id, adSet: adSet, audience: audience}
          return doc
        }))
        setSavedAds(arr)
        setIsFetching(false)
      }).catch(err => console.log('ads Collection error:', err))
  },[publisherId])

  const handleSponsorClick = record => {
    let newAdsArr = [...selectedAdsArr]
    let index = newAdsArr.findIndex(el => el.id === record.id)
    if(index >= 0){
      newAdsArr.splice(index,1)
    }else{
      let newObj = {
        sponsorUrlType: record.sponsorUrlType,
        sponsorLogo: record.sponsorLogo,
        callOutText: record.callOutText,
        sponsorUrl: record.sponsorUrl,
        id: record.id
      }
      newAdsArr.push(newObj)
    }
    setSelectedAdsArr(newAdsArr)
  }

  return(
    isFetching
    ?
      <Row>
        ...loading
      </Row>
    :
      <Row>
        <Col
          span={24} 
          style={{
            display:'flex', 
            flexDirection:'column', 
            background:'white', 
            borderRadius:8, 
            padding:20, 
            boxShadow:'0px 1px 2px rgba(0,0,0,0.25)', 
            marginBottom:10
          }}
        >
          <Typography.Title level={4} style={{margin:0}}>Saved Ads</Typography.Title>
          <Typography.Text type='secondary'>Select an ad to attach to your prompt</Typography.Text>
          <Col style={{marginTop: 20}}>
            <Table 
              columns={columns} 
              dataSource={savedAds} 
              rowKey="id"
            />
          </Col>
        </Col>
      </Row>
  )
}
