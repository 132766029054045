import React, { useState, useEffect } from 'react'
import { Row, Form, Col, Input } from 'antd';

import DropZoneUploader from '../../../../components/DropZoneUploader/DropZoneUploader'

const { TextArea } = Input;

function MediaTwoUp ({ prompt, setPrompt }){
  const [ resOneImage, setResOneImage ] = useState()
  const [ resTwoImage, setResTwoImage ] = useState()

  useEffect(()=>{
    if(resOneImage){
      let newImgArr = [...prompt.responses]
      newImgArr.splice(0,1,resOneImage)
      setPrompt({...prompt, responses: newImgArr})
    }else if(!resOneImage && prompt.responses[0]){
      let newImgArr = [...prompt.responses]
      newImgArr.splice(0,1,'')
      setPrompt({...prompt, responses: newImgArr})
    }
  },[resOneImage])

  useEffect(()=>{
    if(resTwoImage){
      let newImgArr = [...prompt.responses]
      newImgArr.splice(1,1,resTwoImage)
      setPrompt({...prompt, responses: newImgArr})
    }else if(!resTwoImage && prompt.responses[1]){
      let newImgArr = [...prompt.responses]
      newImgArr.splice(1,1,'')
      setPrompt({...prompt, responses: newImgArr})
    }
  },[resTwoImage])

  const handleStatementChange = e => {
    setPrompt({...prompt, text:e.target.value})
  };

  const handleCaptionChange = (e,index) => {
    let newArr = [...prompt.responseCaptions]
    newArr[index] = e.target.value
    setPrompt({...prompt, responseCaptions: newArr})
  };

  return (
    <Row>
      <Col span={24}>
      <Form.Item
        label="Feed Name"
        name="feedName"
        rules={[
          {
            required: true,
            message: 'Please add a Statement',
          },
        ]}
        onChange={handleStatementChange}
      >
        <Input 
          bordered={false}
          style={{borderBottom:'1px solid rgba(0,0,0,0.1'}} 
          placeholder='Enter your statement'
        />
      </Form.Item>
      </Col>
      <Row gutter={[16,16]}>
        <Col span={24}>
          <Form.Item>
            <DropZoneUploader 
              file={resOneImage}
              setFile={setResOneImage}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Caption"
            name={`caption0`}
            rules={[
              {
                required: true,
                message: 'Please add a Caption',
              },
            ]}
            onChange={(e)=>handleCaptionChange(e,0)}
          >
            <TextArea style={{borderBottom:'1px solid rgba(0,0,0,0.1'}} bordered={false} placeholder='Enter your statement' autoSize />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16,16]}>
        <Col span={24}>
          <Form.Item>
            <DropZoneUploader 
              file={resTwoImage}
              setFile={setResTwoImage}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Caption"
            name={`caption1`}
            rules={[
              {
                required: true,
                message: 'Please add a Caption',
              },
            ]}
            onChange={(e)=>handleCaptionChange(e,1)}
          >
            <TextArea style={{borderBottom:'1px solid rgba(0,0,0,0.1'}} bordered={false} placeholder='Enter your statement' autoSize />
          </Form.Item>
        </Col>
      </Row>
    </Row>
  )
}

export default MediaTwoUp