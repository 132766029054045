import React, { useEffect, useState, useContext } from 'react'

import { Typography, Avatar, Row, Col, Image } from 'antd'
import { useSelector, connect } from 'react-redux';
import { buildParties } from '../../utils/helpers/buildTeams'
import { formatTimestamp } from '../../helpers/Utils'
import { FirebaseContext } from '../../utils/Firebase'
import { switchActiveEvent } from '../../redux/publishing/actions';

function EventBar({pastOrFutureEvent,switchActiveEventAction}){
  const firebase = useContext(FirebaseContext)
  const futureFeed = useSelector(state => state.publishing.activeCurrentFeed)
  const pastFeed = useSelector(state => state.publishing.activePastFeed)
  const activeEvent = useSelector(state => state.publishing.activeEvent)

  const [ parties, setParties ] = useState([])
  const [ gameStatus, setGameStatus ] = useState()
  const [ gameSegment, setGameSegment ] = useState()
  const [ feed, setFeed ] = useState()

  useEffect(()=>{
    if(pastOrFutureEvent === 'future'){
      if(futureFeed !== 'none'){
        setFeed(futureFeed)
      }else{
        setFeed(null)
      }
    } else {
      if(pastFeed !== 'none'){
        setFeed(pastFeed)
      }else{
        setFeed(null)
      }
    }
  },[futureFeed, pastFeed, pastOrFutureEvent])

  // GET EVENT DATA
  useEffect(() => {
    if(!feed)return
    const unsubscribe = firebase.getCollection('events').doc(feed.eventId)
      .onSnapshot(snapshot => {
        if(snapshot.exists){
          let doc = snapshot.data()
          let formattedTimestamp = formatTimestamp(doc.scheduledTime)
          doc = {...doc, formattedTimestamp:formattedTimestamp}
          switchActiveEventAction(doc)
        }else{
          console.log('No Event Doc Found')
        }
      })
    return () => unsubscribe()
  },[feed])

  // BUILD PARTIES
  useEffect(() => {
    if(!activeEvent || !activeEvent.parties)return
    buildParties(activeEvent.parties, activeEvent)
      .then(partyArr => setParties(partyArr))
  },[activeEvent])

  useEffect(()=>{
    if(!activeEvent) return
    setGameStatus(activeEvent.status)
    if(activeEvent && activeEvent.eventDetails){
      setGameSegment(activeEvent.eventDetails.gameSegment)
    }
  },[activeEvent])

  return(
    <Col span={24} style={{overflow:'hidden'}}>
      <Row 
        justify='space-between' 
        align='middle' 
        style={{
          background:'white', 
          zIndex:1,
          boxShadow:'6px 0 12px rgba(0,0,0,0.10)',
          width:'100%'
      }}>
        <Row>
          <Col
            style={{
              // borderLeft:`${partyOne.color} 3px solid`,
              // borderRight:`${partyOne.color} 3px solid`,
              margin: '0 1.5em',
              padding: 10,
              overflow: 'hidden',
              transform: 'skew(-15deg, 0deg)',
              background: parties[0] ? parties[0].color : null,
              width:100,
              height:75,
              display:'flex',
              justifyContent:'center',
              alignItems:'center',
              marginLeft:-10,
              borderRight:'1px solid white',
              position: 'relative',
            }}
          >
            {parties[0] && parties[0].logoExists
              ?
                <Image 
                  preview={false} 
                  src={parties[0] ? parties[0].logo : null} 
                  alt=''
                  style={{
                    width:100, 
                    transform: 'skew(15deg, 0deg)',
                  }} 
                />
              :
                <Avatar 
                  shape='square'
                  style={{
                    color: '#fff', 
                    // backgroundColor: '#fff', 
                    lineHeight:'80px', 
                    fontSize:64, 
                    width:150, 
                    height:75,
                  }}>
                    {parties[0] ? parties[0].displayName.split('')[0] : null}
                </Avatar>
            }
            <div style={{borderRight:'3px solid white', right:8, position:'absolute', height:'100%'}} />
          </Col>
          <Col style={{display:'flex', alignItems:'center'}}>
            <Typography.Text 
              style={{
                fontSize:20,
                fontWeight: 600,
              }}
            >
              {parties[0] ? parties[0].displayName : null}
            </Typography.Text>
            <Typography
              style={{
                fontSize:30,
                fontWeight: 700,
                marginLeft:10
              }}
            >
              {activeEvent.eventDetails ? activeEvent.eventDetails.team1Score : null}
            </Typography>
          </Col>
        </Row>

        {/* MIDDLE SECTION */}
        <Col span={5} style={{
          display:'flex', 
          flexDirection:'column', 
          alignItems:'center',
          fontWeight: 600,
          paddingTop:10,
          minWidth:200
        }}>
          <Typography.Text>
            {activeEvent.formattedTimestamp ? `${activeEvent.formattedTimestamp.day} ${activeEvent.formattedTimestamp.month} ${activeEvent.formattedTimestamp.date}, ${activeEvent.formattedTimestamp.time}` : null}
          </Typography.Text>
          <Typography.Text strong>
            {gameStatus === 'final' || gameStatus === 'scheduled' ? gameStatus : gameSegment}
          </Typography.Text>
        </Col>



        {/* RIGHT SIDE */}
        {parties[1]
          ?
              <Row>
                <Col style={{display:'flex', alignItems:'center'}}>
                  <Typography
                    style={{
                      fontSize:30,
                      fontWeight: 700,
                      marginRight:10
                    }}
                  >
                    {activeEvent.eventDetails ? activeEvent.eventDetails.team2Score : null}
                  </Typography>
                  <Typography.Text 
                    style={{
                      fontSize:20,
                      fontWeight: 600,
                    }}>
                    {parties[1].displayName}
                  </Typography.Text>
                </Col>

                <Col
                  style={{
                    // borderLeft:`${partyOne.color} 3px solid`,
                    // borderRight:`${partyOne.color} 3px solid`,
                    margin: '0 1.5em',
                    transform: 'skew(15deg, 0deg)',
                    background: parties[1].color,
                    width:100,
                    height:75,
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center',
                    marginRight:-10,
                    overflow:'hidden'
                  }}
                >
                {parties[1].logoExists
                  ?
                    <Image 
                      preview={false} 
                      src={parties[1].logo} 
                      alt=''
                      style={{
                        width:100, 
                        transform: 'skew(-15deg, 0deg)',
                      }} 
                    />
                  :
                    <Avatar
                      shape='square'
                      style={{
                        color: '#fff', 
                        // backgroundColor: '#fff', 
                        lineHeight:'80px', 
                        fontSize:64, 
                        width:90, 
                        height:75
                      }}>
                        {parties[1].displayName.split('')[0]}
                    </Avatar>
                }
                  <div style={{borderLeft:'3px solid white', left:8, position:'absolute', height:'100%'}} />
                </Col>
              </Row>
            :
            null
          }
      </Row>
    </Col>
  )
}

export default connect(null, {
  switchActiveEventAction: switchActiveEvent,
})(EventBar)