export const authorizedRoot = '/publisher';
export const enrollmentRoot = '/user/enrollment';
export const newUserRoot = '/auth/new-user';

export const UserRole = {
  Admin: 0,
  Editor: 1,
};

export const currentUser = {
  uid:''
};

export const firebaseConfig = {
  apiKey: "AIzaSyA_DF-ON3Lj23OavKdIvNKREFC9xJto5hs",
  authDomain: "opine-188b9.firebaseapp.com",
  databaseURL: "https://opine-188b9.firebaseio.com",
  projectId: "opine-188b9",
  storageBucket: "opine-188b9.appspot.com",
  messagingSenderId: "148522717630",
  appId: "1:148522717630:web:aaf3a3ec528b8734",
  measurementId: "G-VJ6LRW6G8X"
};

export const FIREBASE = {
  VERSION: 'v1-prod',
};