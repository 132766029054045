import React, { useState, useEffect, useContext } from 'react'
import { FirebaseContext } from '../../utils/Firebase'
import moment from 'moment'

import { Carousel, Row, Skeleton } from 'antd';
import CarouselSlide from './CarouselSlideV2'
import { useSelector, useDispatch } from 'react-redux';
import { formatTimestamp } from '../../helpers/Utils';

export default function BrowseEventsCarousel(){
  const firebase = useContext(FirebaseContext)
  const dispatch = useDispatch()
  const [ eventsLoaded, setEventsLoaded ] = useState(false)
  const featuredEvents = useSelector(state => state.ducks.featuredEventsList)

  useEffect(()=>{
    if(featuredEvents.length > 0){
      setEventsLoaded(true)
      return
    }
    const start = moment().startOf('day').utc().format()
    const end = moment().add(1,'week').startOf('day').utc().format()
  
    const unsubscribe = firebase.getCollection('events')
      .where('featured', '==', true)
      .where('scheduledTime', '>=', start)
      .where('scheduledTime', '<', end)
      .onSnapshot(async snapshot => {
        let eventArr = []
        snapshot.forEach(snap=>{
          let doc = snap.data()
          eventArr.push({...doc, id:snap.id})
        })
        let formattedEvents = await Promise.all(eventArr.map(async event => {
          let formattedEventTime = formatTimestamp(event.scheduledTimestamp)
          let broadcasterDocsArr = await firebase.getAllEventBroadcasterDocs(event.allBroadcasters)
          event = {...event, allBroadcasters: broadcasterDocsArr, formattedEventTime}
          return event
        }))
        dispatch({type:'SET_FEATURED_EVENTS_LIST', payload:formattedEvents})
        setEventsLoaded(true)
      },error => console.log(error))

    return () => unsubscribe
  },[firebase])

  return(
    eventsLoaded && featuredEvents.length > 0 
    ?
    <Carousel 
      dotPosition='bottom' 
      autoplay 
      effect="fade"
      style={{  
        position: 'relative', 
        // marginBottom: '35px', 
        width:'100%',
        height: '550px',
        // maxWidth: '1000px',
        // left: '50%', 
        // transform: 'translate(-50%)',
        }}
    >
      {featuredEvents.map((event, index) => {
          return <CarouselSlide
            key={event.id}
            index={index}
            event={event}
          />
      })}
    </Carousel>
    :
    <Row style={{width:'100%', height: 500, padding:20}}>
      <Skeleton loading={!eventsLoaded} active />
    </Row>
  )
}