import {
  Avatar,
  Button,
  Card,
  Col,
  Empty,
  Input,
  List,
  Row,
  Skeleton,
  Typography,
} from 'antd'
import React, { useContext, useEffect, useState } from 'react'

import {
  PlusOutlined,
  StarFilled,
  StarOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { connect, useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  formatTimestamp,
  getCurrentFeedsListFromLocalStorage,
} from '../../helpers/Utils'
import { switchCurrentFeed } from '../../redux/publishing/actions'
import { FirebaseContext } from '../../utils/Firebase'

function PastFeedsSidebar({ sidebarTime, switchCurrentFeedAction }) {
  const firebase = useContext(FirebaseContext)
  const history = useHistory()
  const dispatch = useDispatch()

  const publishingAs = useSelector((state) => state.publishing.publishingAs)
  const currentFeeds = useSelector((state) => state.publishing.currentFeedsList)
  const activeCurrentFeed = useSelector(
    (state) => state.publishing.activeCurrentFeed
  )
  const lastVisiblePastFeed = useSelector(
    (state) => state.ducks.lastVisiblePastFeed
  )

  const [searchText, setSearchText] = useState()
  const [filteredList, setFilteredList] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  console.log('currentFeeds', currentFeeds)
  console.log(
    'getCurrentFeedsListFromLocalStorage',
    getCurrentFeedsListFromLocalStorage()
  )

  // SET ACTIVE PAST FEED

  // SEARCH FEEDS FUNCTIONALITY
  useEffect(() => {
    if (!currentFeeds) return
    setFilteredList(currentFeeds)
  }, [currentFeeds])

  Array.prototype.unique = function () {
    var a = this.concat()
    for (var i = 0; i < a.length; ++i) {
      for (var j = i + 1; j < a.length; ++j) {
        if (a[i] === a[j]) a.splice(j--, 1)
      }
    }
    return a
  }

  const searchFeedList = (e) => {
    setSearchText(e.target.value)
    let titleList = [...currentFeeds]
    let eventNameList = [...currentFeeds]
    titleList = titleList.filter((feedObj) => {
      return (
        feedObj.title.toLowerCase().search(e.target.value.toLowerCase()) !== -1
      )
    })
    eventNameList = eventNameList.filter((feedObj) => {
      return (
        feedObj.eventName.toLowerCase().search(e.target.value.toLowerCase()) !==
        -1
      )
    })
    let updatedList = titleList.concat(eventNameList).unique()
    setFilteredList(updatedList)
  }

  const handleFeedClick = (feed) => {
    switchCurrentFeedAction(feed)
  }

  const handleFavoriteClick = (val) => {
    if (val.favorited) {
      firebase
        .getCollection('feeds')
        .doc(val.id)
        .update({ favorited: false })
        .then(() => console.log('Document successfully updated with Favorite!'))
        .catch((err) => console.error('Error updating document: ', err))
    } else {
      firebase
        .getCollection('feeds')
        .doc(val.id)
        .update({ favorited: true })
        .then(() => console.log('Document successfully updated with Favorite!'))
        .catch((err) => console.error('Error updating document: ', err))
    }
  }

  const fetchMorePastFeeds = () => {
    firebase
      .getCollection('feeds')
      .where('publisherId', '==', publishingAs?.id ?? 0)
      .orderBy('eventScheduledTime', 'desc')
      .startAfter(lastVisiblePastFeed)
      .limit(20)
      .get()
      .then((snapshot) => {
        let newPastFeeds = [...currentFeeds]
        snapshot.docs.map((doc) => {
          newPastFeeds.push({
            ...doc.data(),
            id: doc.id,
            formattedTimestamp: formatTimestamp(doc.data().eventScheduledTime),
          })
        })
        dispatch({
          type: 'SET_LAST_VISIBLE_PAST_FEED',
          payload: snapshot.docs[snapshot.docs.length - 1],
        })
        dispatch({ type: 'SET_PAST_FEEDS', payload: newPastFeeds })
      })
  }

  return (
    <Row
      style={{
        paddingTop: 20,
        minWidth: 350,
        height: '100%',
        overflow: 'hidden',
        paddingBottom: 110,
      }}
    >
      <Col span={24} style={{ paddingLeft: 20 }}>
        <Typography.Title level={3}>Publisher Feeds</Typography.Title>
      </Col>
      {isLoading ? (
        <Col span={24}>
          <Skeleton loading={isLoading} active avatar />
        </Col>
      ) : (currentFeeds && currentFeeds.length > 0) || activeCurrentFeed ? (
        <Row style={{ height: '100%' }}>
          <Col span={24}>
            <Input
              value={searchText}
              bordered={false}
              size="large"
              placeholder={`Search Feeds`}
              prefix={<UserOutlined />}
              onChange={searchFeedList}
              style={{ padding: 20 }}
            />
          </Col>
          <Col span={24} style={{ height: '100%', overflowY: 'scroll' }}>
            <List
              itemLayout="horizontal"
              dataSource={filteredList ? filteredList : []}
              rowKey="id"
              renderItem={(item) => (
                <List.Item
                  style={{
                    position: 'relative',
                    borderLeft: `${
                      activeCurrentFeed && activeCurrentFeed.id === item.id
                        ? '5px black solid'
                        : '5px white solid'
                    }`,
                  }}
                >
                  <Card
                    bordered={false}
                    style={{ width: '100%', cursor: 'pointer' }}
                    onClick={() => handleFeedClick(item)}
                  >
                    <Card.Meta
                      avatar={<Avatar src={item.picture} size={50} />}
                      title={item.title}
                      description={
                        <Row>
                          <Col span={24}>{item.eventName},</Col>
                          <Col>
                            {item.formattedTimestamp.day}{' '}
                            {item.formattedTimestamp.month}{' '}
                            {item.formattedTimestamp.date}{' '}
                            {item.formattedTimestamp.year} at{' '}
                            {item.formattedTimestamp.time}
                          </Col>
                        </Row>
                      }
                    />
                  </Card>
                  <Row
                    style={{
                      position: 'absolute',
                      right: 10,
                      top: 10,
                      cursor: 'pointer',
                      zIndex: 10,
                    }}
                    onClick={() => handleFavoriteClick(item)}
                  >
                    {item.favorited ? <StarFilled /> : <StarOutlined />}
                  </Row>
                </List.Item>
              )}
            />
            {sidebarTime === 'past' ? (
              <Button
                type="primary"
                size="large"
                block
                onClick={fetchMorePastFeeds}
              >
                Get More Past Feeds
              </Button>
            ) : null}
          </Col>
        </Row>
      ) : (
        <Row>
          <Col span={24} style={{ paddingTop: 50 }}>
            <Empty description={'No Feeds'} />
          </Col>
          <Col
            span={24}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              paddingTop: 25,
            }}
          >
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => history.push('/browse-events')}
            >
              Add a New Feed
            </Button>
          </Col>
        </Row>
      )}
    </Row>
  )
}

export default connect(null, {
  switchCurrentFeedAction: switchCurrentFeed,
})(PastFeedsSidebar)
