import React from 'react';
import BrowseEvents from '../../Pages/BrowseEvents/BrowseEvents';
import AuthorizedLayout from '../../layouts/AuthorizedLayout';


const BrowseEventsView = () => {
  return (
    <AuthorizedLayout>
      <BrowseEvents />
    </AuthorizedLayout>
  );
};

export default BrowseEventsView;
