import React from 'react'

import SingleChoice from './PromptCreationTypes/SingleChoice.js'
import MediaTwoUp from './PromptCreationTypes/MediaTwoUp'
import Rank from './PromptCreationTypes/Rank'
import Scale from './PromptCreationTypes/Scale'

export default function PromptContent({ prompt, setPrompt }){
  const singleChoiceCase='single choice',
        multipleChoiceCase='multiple choice',
        slidingScaleCase='sliding scale',
        rankingCase='ranking'

  if(prompt && prompt.responseContentType === 'text'){
    switch(prompt.responseType){
      case singleChoiceCase: 
        return(<SingleChoice 
          prompt={prompt}
          setPrompt={setPrompt}
        />)

      case multipleChoiceCase: 
        return(<SingleChoice   
          prompt={prompt}
          setPrompt={setPrompt}
        />)

      case slidingScaleCase: 
        return(<Scale 
          prompt={prompt}
          setPrompt={setPrompt}
        />)

      case rankingCase: 
        return(<Rank 
          prompt={prompt}
          setPrompt={setPrompt}
        />)

      default: return(null)
      }
  }else if(prompt && prompt.responseContentType === 'image'){
    return(<MediaTwoUp 
      prompt={prompt}
      setPrompt={setPrompt}
    />)
  }else{
    return(
      <p>Loading...</p>
    )
  }
}