export const mapOrder = (array, order, key) => {
  // eslint-disable-next-line func-names
  array.sort(function (a, b) {
    const A = a[key]
    const B = b[key]
    if (order.indexOf(`${A}`) > order.indexOf(`${B}`)) {
      return 1
    }
    return -1
  })
  return array
}

export const getDateWithFormat = () => {
  const today = new Date()
  let dd = today.getDate()
  let mm = today.getMonth() + 1 // January is 0!

  const yyyy = today.getFullYear()
  if (dd < 10) {
    dd = `0${dd}`
  }
  if (mm < 10) {
    mm = `0${mm}`
  }
  return `${dd}.${mm}.${yyyy}`
}

export const getCurrentTime = () => {
  const now = new Date()
  return `${now.getHours()}:${now.getMinutes()}`
}

export const setToLocalStorage = (item, val) => {
  try {
    if (val) {
      localStorage.setItem(item, JSON.stringify(val))
    } else {
      localStorage.removeItem(item)
    }
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : setToLocalStorage -> error',
      error
    )
  }
}

export const getFromLocalStorage = (item) => {
  let data = null
  try {
    data =
      localStorage.getItem(item) != null
        ? JSON.parse(localStorage.getItem(item))
        : null
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js  : getFromLocalStorage -> error',
      error
    )
    data = null
  }
  return data
}

export const getCurrentUserFromLocalStorage = () => {
  let user = null
  try {
    user =
      localStorage.getItem('opine_current_user') != null
        ? JSON.parse(localStorage.getItem('opine_current_user'))
        : null
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js  : getCurrentUser -> error', error)
    user = null
  }
  return user
}

export const setCurrentUserToLocalStorage = (user) => {
  try {
    if (user) {
      localStorage.setItem('opine_current_user', JSON.stringify(user))
    } else {
      localStorage.removeItem('opine_current_user')
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setCurrentUser -> error', error)
  }
}

export const getCurrentFeedFromLocalStorage = () => {
  let user = null
  try {
    user =
      localStorage.getItem('opine_current_feed') != null
        ? JSON.parse(localStorage.getItem('opine_current_feed'))
        : null
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js  : getCurrentFeed -> error', error)
    user = null
  }
  return user
}

export const setCurrentFeedToLocalStorage = (feed) => {
  console.log('set current feed')
  try {
    if (feed) {
      localStorage.setItem('opine_current_feed', JSON.stringify(feed))
    } else {
      localStorage.removeItem('opine_current_feed')
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setCurrentFeed -> error', error)
  }
}

export const getPastFeedFromLocalStorage = () => {
  let user = null
  try {
    user =
      localStorage.getItem('opine_past_feed') != null
        ? JSON.parse(localStorage.getItem('opine_past_feed'))
        : null
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js  : getPastFeed -> error', error)
    user = null
  }
  return user
}

export const setPastFeedToLocalStorage = (feed) => {
  console.log('set past feed')
  try {
    if (feed) {
      localStorage.setItem('opine_past_feed', JSON.stringify(feed))
    } else {
      localStorage.removeItem('opine_past_feed')
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setPastFeed -> error', error)
  }
}

export const getPastFeedsListFromLocalStorage = () => {
  let user = null
  try {
    user =
      localStorage.getItem('opine_past_feeds_list') != null
        ? JSON.parse(localStorage.getItem('opine_past_feeds_list'))
        : null
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js  : getPastFeedsList -> error',
      error
    )
    user = null
  }
  return user
}

export const setPastFeedsListToLocalStorage = (feedList) => {
  console.log('set past feeds list')
  try {
    if (feedList) {
      localStorage.setItem('opine_past_feeds_list', JSON.stringify(feedList))
    } else {
      localStorage.removeItem('opine_past_feeds_list')
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setPastFeedsList -> error', error)
  }
}

export const getCurrentFeedsListFromLocalStorage = () => {
  let feeds = null
  try {
    feeds =
      localStorage.getItem('opine_current_feeds_list') != null
        ? JSON.parse(localStorage.getItem('opine_current_feeds_list'))
        : null
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js  : getCurrentFeedsList -> error',
      error
    )
    feeds = null
  }
  return feeds
}

export const setCurrentFeedsListToLocalStorage = (feedList) => {
  console.log('set current feeds list')
  try {
    if (feedList?.length > 0) {
      localStorage.setItem('opine_current_feeds_list', JSON.stringify(feedList))
    } else {
      localStorage.removeItem('opine_current_feeds_list')
    }
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : setCurrentFeedsList -> error',
      error
    )
  }
}

export const formatTimestamp = (scheduledTime) => {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  const dayNames = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Firday',
    'Saturday',
  ]
  let t
  if (!scheduledTime.seconds) {
    let scheduledTimeArr = scheduledTime.split('')
    scheduledTimeArr.splice(19, 3)
    let joinedDate = scheduledTimeArr.join('')
    let newDate = new Date(joinedDate)
    t = newDate
  } else {
    t = new Date(1970, 0, 1) // Epoch
    t.setSeconds(scheduledTime.seconds)
    t.setHours(t.getHours() - 7)
  }
  let date = t.getDate()
  let year = t.getFullYear()
  let monthNum = t.getMonth()
  let dayNum = t.getDay()
  let hours = t.getHours()
  let minutes = t.getMinutes()
  var ampm = hours >= 12 ? 'pm' : 'am'
  hours = hours % 12
  hours = hours ? hours : 12 // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes
  var strTime = hours + ':' + minutes + ' ' + ampm
  const dateObj = {
    time: strTime,
    date,
    day: dayNames[dayNum],
    month: monthNames[monthNum],
    year,
    timestamp: t,
  }
  // console.log('dateObj',dateObj)
  return dateObj
}
