import React,{ useEffect, useState } from 'react'
import '../StatementCards.scss'
import numeral from 'numeral'

// import ResultCardFooter from './ResultCardFooter';
import { Card, Typography, Image, Row, Col, Empty, Slider, List, Avatar } from 'antd';
import { ClipLoader } from 'react-spinners';
import { ResponsivePie } from '@nivo/pie'
import CustomPollResults from './CustomPollResults';


// const COLORS = ['#0D0569', '#061A73', '#002A5C', '#065373', '#056669'];
// const COLORS = ['#056669', '#065373', '#061A73', '#002A5C', '#0D0569'];
// const COLORS = ['#1D2D60', '#40CEE2', '#FFBC47', '#F65E5D'];

export default function MultipleChoiceResultChart_V2({ prompt }){
  const [ pollAnswers, setPollAnswers ] = useState()

  useEffect(()=>{
    if(!prompt.responseCountByChoice)return
    let pollAnswersArr = []

    prompt.responses.map( (response,i) => {
      let obj = {}
      if(prompt.responseContentType === 'image'){
        obj = {
          ...obj, 
          image:response,
          barWidth:  prompt.responseCountByChoice[response] / prompt.responseCount * 100,
          response: prompt.responseCaptions[i], 
          count: prompt.responseCountByChoice[response]
        }
      }else{
        obj = {
          ...obj, 
          barWidth:  prompt.responseCountByChoice[response] / prompt.responseCount * 100,
          response: response, 
          count: prompt.responseCountByChoice[response]
        }
      }
      pollAnswersArr.push(obj)
    })

    // console.log('calculatedResponseCount',calculatedResponseCount)

    setPollAnswers(pollAnswersArr)
  },[prompt])

  return(
    prompt.responseCountByChoice && pollAnswers
      ? 
        <Col span={24}>
          {pollAnswers.map(response => {
            // console.log('res', response)
            return <CustomPollResults key={response.response} responseObj={response}/>
          })}
        </Col>
      :
        <ClipLoader/>
  )
}