import React,{} from "react";

import SingleChoiceResultChart from './charts/SingleChoiceResultChart_V2'
import Scale from './charts/SlidingScale'
// import MultipleChoiceResultChart from './charts/MultipleChoiceResultChart'
// import MediaTwoUp from '../PromptCreationTypes/MediaTwoUp'
import Rank from './charts/Rank'
import MultipleChoiceStatementCard from "./charts/MultipleChoiceResultChart_V2";

export default ({ prompt }) => {
  const singleChoiceCase='single choice',
        multipleChoiceCase='multiple choice',
        slidingScaleCase='sliding scale',
        rankingCase='ranking'

  if(prompt && prompt.responseContentType === 'text'){
    switch(prompt.responseType){
      case singleChoiceCase: 
        return <SingleChoiceResultChart prompt={prompt}/>

      case multipleChoiceCase: 
        return <MultipleChoiceStatementCard prompt={prompt}/>

      case slidingScaleCase: 
        return <Scale prompt={prompt}/>

      case rankingCase: 
        return <Rank prompt={prompt} />

      default: return(null)
    }
  } else if(prompt && prompt.responseContentType === 'image'){
    return <SingleChoiceResultChart prompt={prompt}/>
  }
  else return null
}