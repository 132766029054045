import React from 'react';
import Firebase from './firebaseController';

const firebase = new Firebase();
const FirebaseContext = React.createContext(null);

// console.log('new firebase')
// export const withFirebase = Component => props => (
//   <FirebaseContext.Consumer>
//     {() => <Component {...props} firebase={firebase} />}
//   </FirebaseContext.Consumer>
// );

export default FirebaseContext;
