import React from 'react';
import './reset.css'
import 'antd/dist/antd.css'
import './main.scss'

// DEPENDENCIES
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import { Row } from 'antd'

// VIEWS
import AuthView from './views/auth';
// import DashboardView from './views/dashboard';
import PublisherView from './views/publisher';
import PastEventsView from './views/pastEvents';
import BrowseEventsView from './views/browse';
import AdsManagerView from './views/adsManager';
import ErrorView from './views/error';
import UnauthorizedView from './views/unauthorized';
import { ProtectedRoute } from './helpers/authHelper';

function App() {
  return(
    <Row className='h-100'>
      <Router>
        <Switch>
          {/* <ProtectedRoute
            path="/dashboard"
            component={DashboardView}
            roles={['user', 'admin', 'superAdmin']}
          /> */}
          <ProtectedRoute
            path="/publisher"
            component={PublisherView}
            roles={['user', 'admin', 'superAdmin']}
          />
          <ProtectedRoute
            path="/browse-events"
            component={BrowseEventsView}
            roles={['user', 'admin', 'superAdmin']}
          />
          <ProtectedRoute
            path="/past-events"
            component={PastEventsView}
            roles={['user', 'admin', 'superAdmin']}
          />
          <ProtectedRoute
            path="/ads-manager"
            component={AdsManagerView}
            roles={['user', 'admin', 'superAdmin']}
          />
          <Route
            path="/auth"
            render={(props) => <AuthView {...props} />}
          />
          {/* <Route
            path="/enrollment"
            render={(props) => <EnrollmentView {...props} />}
          /> */}
          <Route
            path="/error"
            exact
            render={(props) => <ErrorView {...props} />}
          />
          <Route
            path="/unauthorized"
            exact
            render={(props) => <UnauthorizedView {...props} />}
          />
          <Redirect exact from="/" to="/publisher" />
          <Redirect to="/error" />
        </Switch>
      </Router>
    </Row>
  )
}

export default App