import React from 'react';
import './AppBar.scss';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

// COMPONENTS
import UserAppBarRight from './UserAppBarRight/UserAppBarRight';

// ASSETS
import opineLogo from '../../assets/images/OpineLogo.svg'
// import { Typography } from 'antd';

export default function AppBar(){
  const history = useHistory()
  
  const handleLogoClick = () => {
    history.push('/publisher')
  }

  return( 
    <div className= 'app-bar'>
        <div className='app-bar-left' onClick={handleLogoClick}>
          <img src={opineLogo} alt="Opine, Inc" id='app-bar-logo'/>
          {/* <h1 id='app-title'>Publisher</h1> */}
        </div>
      {/* <Typography.Title>{publishingAs}</Typography.Title> */}
      <UserAppBarRight/>
    </div>
  )
}