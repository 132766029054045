import React, { useState } from 'react'
import { Col, Row, Radio, Typography, Divider } from 'antd'

import Promptbuilder from './PromptBuilder_V3'

export default function NewPrompts(){
  const [ selectedType, setSelectedType ] = useState('Two Up')
  
  const handleClick = (e) => {
    e.preventDefault()
    setSelectedType(e.target.value)
  }

  return (
    <Row style={{marginTop:20}}>
      <Col 
        // xl={{ span: 10, offset: 7 }}
        // lg={{ span: 12, offset: 6 }}
        // md={{ span: 12, offset: 6 }}
        md={{ span: 18, offset: 3 }}
        sm={{ span: 12, offset: 6 }}
        style={{display:'flex', flexDirection:'column', alignItems:'center', background:'white', borderRadius: 10, padding:20}}
      >
        <Typography.Title level={3}>Create a New Prompt</Typography.Title>
        <Radio.Group size='large' buttonStyle='solid' centered value={selectedType} defaultValue='Two Up' onChange={handleClick}>
          <Radio.Button value='Two Up'>
            Single Choice Two
          </Radio.Button>
          <Radio.Button value='Four Up'>
            Single Choice Four
          </Radio.Button>
          <Radio.Button value='Checklist'>
            Multiple Selection
          </Radio.Button>
          <Radio.Button value='Scale'>
            Sliding Scale
          </Radio.Button>
          <Radio.Button value='Rank'>
            Ranking
          </Radio.Button>
          <Radio.Button value='Media Two Up'>
            Media Two Choice
          </Radio.Button>
        </Radio.Group>
        <Divider/>
        <Promptbuilder selectedType={selectedType}/>
      </Col>
    </Row>
  )
}