import * as Vibrant from 'node-vibrant'

const buildFeaturedTeams = (event) => {
  // console.log('event', event)

  let teamOne = {...event.parties[0]}
  let teamTwo = {...event.parties[1]}

  let formattedTeamOne = {
    nameOne:'',
    nameTwo:'',
    city:'',
    state:'',
    arena:''
  }
  let formattedTeamTwo = {
    nameOne:'',
    nameTwo:'',
    city:'',
    state:'',
    arena:''
  }

  
  if(event.league === 'ncaa_fb' || event.league === 'ncaa_mb'){
    formattedTeamOne.nameOne = teamOne.mascot
    formattedTeamTwo.nameOne = teamTwo.mascot
    formattedTeamOne.nameTwo = teamOne.name
    formattedTeamTwo.nameTwo = teamTwo.name
  }

  if(event.league === 'nflp'){
    let cityOne = teamOne.name.split(" ")
    let cityTwo = teamTwo.name.split(" ")

    let newCityOne = cityOne.splice(0, cityOne.length - 1)
    let newCityTwo = cityTwo.splice(0, cityTwo.length - 1)

    formattedTeamOne.nameOne = teamOne.displayName
    formattedTeamTwo.nameOne = teamTwo.displayName
    formattedTeamOne.nameTwo = newCityOne.join(' ')
    formattedTeamTwo.nameTwo = newCityTwo.join(' ')
  }

  if(event.league === 'mls'){
    let cityOne = teamOne.name.split(" ")
    let cityTwo = teamTwo.name.split(" ")

    let newCityOne = cityOne.splice(0, 1)
    let newCityTwo = cityTwo.splice(0, 1)
    
    let newNameOne = cityOne.splice(0, cityOne.length)
    let newNameTwo = cityTwo.splice(0, cityTwo.length)
    
    formattedTeamOne.nameTwo = newCityOne.join(' ')
    formattedTeamTwo.nameTwo = newCityTwo.join(' ')
    formattedTeamOne.nameOne = newNameOne.join(' ')
    formattedTeamTwo.nameOne = newNameTwo.join(' ')
    formattedTeamOne.city = teamOne.city
    formattedTeamTwo.city = teamTwo.city
    formattedTeamOne.state = teamOne.state
    formattedTeamTwo.state = teamTwo.state
  }

  if(event.league === 'mlb'){
    formattedTeamOne.nameOne = teamOne.mascot
    formattedTeamTwo.nameOne = teamTwo.mascot
    formattedTeamOne.nameTwo = teamOne.name
    formattedTeamTwo.nameTwo = teamTwo.name
    formattedTeamOne.city = teamOne.name
    formattedTeamTwo.city = teamTwo.name
  }

  if(event.league === 'nba'){
    formattedTeamOne.nameOne = teamOne.displayName
    formattedTeamTwo.nameOne = teamTwo.displayName
    formattedTeamOne.nameTwo = teamOne.city
    formattedTeamTwo.nameTwo = teamTwo.city
    formattedTeamOne.city = teamOne.city
    formattedTeamTwo.city = teamTwo.city
    formattedTeamOne.state = teamOne.state
    formattedTeamTwo.state = teamTwo.state
    formattedTeamOne.arena = teamOne.arena
    formattedTeamTwo.arena = teamTwo.arena
  }


  return { formattedTeamOne, formattedTeamTwo }
}

const buildParties = async (partiesArr, event) => {
  return await Promise.all(partiesArr.map(async party => {
    return await buildParty(party, event)
  }))
}

const buildParty = async (team, event) => {
  if(!team) return 'single'

  let teamData = {
    logoExists: false,
    color: '',
    logo: '',
    displayName: '',
    league: event.league,
    name: team.name,
    mascot: team.mascot
  }

  // SET EACH TEAM DISPLAY NAME
  if(team.name === team.mascot || !team.mascot){
    teamData.displayName = team.name
  } else {
    teamData.displayName = `${team.name} ${team.mascot}`
  }

  // SET TEAM LOGO
  if(team.logo){
    teamData.logoExists = true
    teamData.logo = team.logo
    let colorSwatches = await Vibrant.from(team.logo).getPalette((err, palette) => {
      return palette
    })
    teamData.color = colorSwatches.Vibrant.hex
  }
  return await teamData
}

const buildEventName = (partyOne, partyTwo) => {
  let eventName
  if(partyOne && partyTwo){
    eventName = `${partyOne.displayName} vs ${partyTwo.displayName}`
  }else if(partyOne && !partyTwo) {
    eventName = `${partyOne.displayName}`
  }
  return eventName
}

export {
  buildFeaturedTeams,
  buildParty,
  buildEventName,
  buildParties
}