import React from 'react';
import AdsManager from '../../Pages/AdsManager/AdsManager';
import AuthorizedLayout from '../../layouts/AuthorizedLayout';

const AdsManangerView = () => {
  return (
    <AuthorizedLayout>
      <AdsManager />
    </AuthorizedLayout>
  );
};

export default AdsManangerView;
