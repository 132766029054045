import React, { useState } from 'react'

import { Button, Modal, Table, Col, message } from 'antd';
import { DeleteOutlined, ClockCircleOutlined } from '@ant-design/icons';

import CreateNewScheduleModal from '../../../modals/CreateNewScheduleModal/CreateNewScheduleModal';

const scheduleInitialState = {      
  sendInPeriod: null,
  sendAtMinutes: null,
  sendAtSeconds: null,
  delivered: false
}

export default function AttachScheduleToPrompt({ prompt, setPrompt, handleDelete }){
  const [ modalOpen, setModalOpen ] = useState(false)
  const [ schedule, setSchedule ] = useState(scheduleInitialState)

  const handleModalSubmit = async () => {
    if(!schedule.sendInPeriod || !schedule.sendAtMinutes || !schedule.sendAtSeconds){
      message.error('Please fill out all fields')
    }else{
      setPrompt({...prompt, scheduling:schedule})
      setSchedule(scheduleInitialState)
      setModalOpen(false)
    }
  }

  const handleModalCancel = () => {
    setSchedule(scheduleInitialState)
    setModalOpen(false)
  }

  const columns = [
    {
      title: 'Period',
      dataIndex: ['sendInPeriod'],
      key: 'sendInPeriod',
    },
    {
      title: 'Minutes',
      dataIndex: ['sendAtMinutes'],
      key: 'sendAtMinutes',
      // align: 'right'
    },
    {
      title: 'Seconds',
      dataIndex: ['sendAtSeconds'],
      key: 'sendAtSeconds',
      // align: 'right'
    },
    {
      key: 'deleteButton',
      width: 50,
      render:(text, record) => <Button type='text' danger icon={<DeleteOutlined/>} onClick={() => handleDelete(record)}/>
      // align: 'right'
    },
  ]

  return(
    prompt && prompt.scheduling 
      ?
        <Col span={24} style={{zIndex:100}}>
          <Table 
            columns={columns} 
            dataSource={[prompt.scheduling]} 
            rowKey="sendInPeriod"
            pagination={false}
          />
        </Col>
      :
        <Col span={24}>
          <Button type='link' block onClick={()=>setModalOpen(true)} icon={<ClockCircleOutlined/>}>Schedule prompt</Button>
          <Modal
            title={'Schedule a Prompt'}
            visible={modalOpen}
            centered
            onOk={handleModalSubmit}
            onCancel={handleModalCancel}
            width={500}
            okText='Schedule Prompt'
          >
            <CreateNewScheduleModal schedule={schedule} setSchedule={setSchedule}/>
          </Modal>
        </Col>
  )
}