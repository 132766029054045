import React from 'react'
import PerpetualFeed from './PerpetualFeed';
import { Form, Tabs, Input, Row, Col } from 'antd'
import { MailOutlined, AppstoreOutlined } from '@ant-design/icons';
import DropZoneUploader from '../../components/DropZoneUploader/DropZoneUploader';

const { TabPane } = Tabs;

export default function AddFeedToEventModal({ setFeedDescription, setFeedName, setFeedImg, feedImg, feedName, feedDescription, event, setSubmittingModal }){

  const handleNameChange = event => {
    setFeedName( event.target.value );
  }

  const handleDescChange = event => {
    setFeedDescription( event.target.value );
  }

  // console.log('event',event)

  return(
    <>
      <Tabs defaultActiveKey='new'>
        <TabPane key="new" icon={<MailOutlined />} tab='Create New Feed'>
          <Row gutter={[16,16]}>
            <Col span={24}>
              <DropZoneUploader file={feedImg} setFile={setFeedImg}/>
            </Col>
            <Col span={24}>
              <Form layout="vertical">
                <Form.Item
                  label="Feed Name"
                  name="feedName"
                  rules={[
                    {
                      required: true,
                      message: 'Please input a Feed Name!',
                    },
                  ]}
                  onChange={handleNameChange}
                >
                  <Input placeholder='Feed Name' value={feedName}/>
                </Form.Item>
                <Form.Item
                  label="Feed Description"
                  name="feedDescription"
                  value={feedDescription}
                  rules={[
                    {
                      required: true,
                      message: 'Please input a Feed Description!',
                    },
                  ]}
                  onChange={handleDescChange}
                >
                  <Input placeholder='Feed Description' value={feedDescription}/>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </TabPane>
        <TabPane key="perpetual" icon={<AppstoreOutlined/> }tab='Use Perpetual Feed'>
          <PerpetualFeed event={event} setSubmittingModal={setSubmittingModal}/>
        </TabPane>
      </Tabs>
    </>
  )
}