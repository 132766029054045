import React, { useState, useContext } from 'react'
import { Card, Typography, message, Row, Col, Button, Modal } from 'antd'
import { updateDraft, deleteDraft, handleWriteNewPrompt } from '../PromptCreator/PromptWriter'

import { FirebaseContext } from '../../../utils/Firebase'
import AttachAdToPrompt from '../PromptCreator/AttachAdToPrompt';
import AttachScheduleToPrompt from '../PromptCreator/AttachScheduleToPrompt';
import EditPromptModal from '../../../modals/EditPromptModal/EditPromptModal';

export default function DraftCard({ prompt }){
  const firebase = useContext(FirebaseContext)
  const [ modalOpen, setModalOpen ] = useState(false)
  const [ isPosting, setIsPosting ] = useState(false)
  const [ editedDraft, setEditedDraft ] = useState()
  const [ img, setImg ] = useState()
  const [ mediaTwoUpOne, setMediaTwoUpOne ] = useState()
  const [ mediaTwoUpTwo, setMediaTwoUpTwo ] = useState()

  const handlePostNow = () => {
    if(prompt.scheduling){
      delete prompt.scheduling
    }
    handleWriteNewPrompt(prompt, 'prompts')
      .then(()=>{
          deleteDraft(prompt)
        })
  }

  const handleDraftClick = () => {
    setEditedDraft(prompt)
    setModalOpen(true)
  }

  const handleModalSubmit = async () => {
    setIsPosting(true)

    let DbPrompt = {...editedDraft}
    if(img) DbPrompt = {...DbPrompt, photoURL: await firebase.handleFirebaseImgUpload(img) }
    if(mediaTwoUpOne){
      let newObj = {...DbPrompt}
      let imgUrl = await firebase.handleFirebaseImgUpload(mediaTwoUpOne)
      newObj.responses[0] = imgUrl
      DbPrompt = {...newObj}
    } 
    if(mediaTwoUpTwo){
      let newObj = {...DbPrompt}
      let imgUrl = await firebase.handleFirebaseImgUpload(mediaTwoUpTwo)
      newObj.responses[1] = imgUrl
      DbPrompt = {...newObj}
    } 
    
    updateDraft(DbPrompt, 'prompt_drafts')
      .then(() => {
        setIsPosting(false)
        setModalOpen(false)
      }).catch(err => {
        message.error('Error sending prompt, please try again')
        console.log('error:', err)
        setIsPosting(false)
      })
  }

  const handleAttachSchedule = (newPrompt) => {
    handleWriteNewPrompt(newPrompt, 'scheduled_prompts')
      .then(()=>{
        deleteDraft(prompt)
          .then(()=>{
            console.log('draft deleted successfully')
          }).catch(err=>console.log('error:', err))
      }).catch(err => {
        message.error('Error sending prompt, please try again')
        console.log('error:', err)
      })
  }

  const handleScheduleDelete = (prompt) => {
    let newScheduleObj = {...prompt}
    delete newScheduleObj.scheduling
    updateDraft(newScheduleObj)
  }

  const handleModalCancel = () => {
    setModalOpen(false)
    setEditedDraft(null)
  }

  return(
    <Row>
      <Card
        hoverable
        style={{width:'100%'}}
        title={
          <Row justify='space-between'>
            <Typography.Text type='secondary'>Draft</Typography.Text>
            <Button danger onClick={()=>deleteDraft(prompt)}>Delete</Button>
          </Row>
        }
        actions={[
          <Col span={24}>
            <Button type='primary' size='large' block loading={isPosting} onClick={()=>handlePostNow(prompt)}>Post Now</Button>
          </Col>
        ]}
      >
        <Row gutter={[16,16]} 
          onClick={()=>handleDraftClick(true)}
        >
          <Col span={24}>
            <Typography.Title level={3}>
              {prompt.text}
            </Typography.Title>
          </Col>
          {prompt.responses.map((response, index) => (
            <Col span={24} key={index}>
              <Button shape='round' style={{width:'100%'}}>
                <Typography.Text strong>
                  {prompt.responses[index]}
                </Typography.Text>
              </Button>
            </Col>
          ))}
          </Row>
          <Row style={{marginTop:10}}>
            <AttachAdToPrompt
              prompt={prompt}
              setPrompt={updateDraft}
              />
          </Row>
          <Row style={{marginTop:10}}>
            <AttachScheduleToPrompt
              prompt={prompt}
              setPrompt={handleAttachSchedule}
              handleDelete={handleScheduleDelete}
            />
          </Row>
      </Card>
      <Modal
        title={'Edit Draft'}
        visible={modalOpen}
        centered
        onOk={handleModalSubmit}
        onCancel={handleModalCancel}
        width={500}
        okText='Update Draft'
        confirmLoading={isPosting}
      >
        <EditPromptModal prompt={editedDraft} setPrompt={setEditedDraft} setImg={setImg} setMediaTwoUpOne={setMediaTwoUpOne} setMediaTwoUpTwo={setMediaTwoUpTwo} handleDelete={handleScheduleDelete} />
      </Modal>
    </Row>
  )
}