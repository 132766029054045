import React from 'react';
import { Button, Row, Col, Typography } from 'antd';
import { NavLink } from 'react-router-dom';
import UnauthorizedLayout from '../layouts/UnauthorizedLayout';
import '../main.scss'


const Error = () => {
  return (
    <UnauthorizedLayout>
      <Row className='auth-container'>
      <Col className='column-center'>
        <Typography.Title>
          Oops... we can't find the page you're looking for.
        </Typography.Title>
      </Col>
      <Col className='column-center'>
        <NavLink to='/publisher'>
          <Button type='primary' shape='round'>
            Go Home
          </Button>
        </NavLink>
      </Col>
      </Row>
    </UnauthorizedLayout>
  );
};

export default Error;
