import { CloseOutlined, FlagOutlined, PlusOutlined } from '@ant-design/icons'
import {
  Button,
  Card,
  Col,
  Image,
  Modal,
  Row,
  Skeleton,
  Typography,
  message,
} from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import AddFeedToEvent from '../../modals/AddFeedToEventModal/AddFeedToEvent'
import BrowseEventCardCover from './BrowseEventCardCover'

import { connect, useSelector } from 'react-redux'
import { getPublisherUpcomingFeeds } from '../../helpers/Firebase'
import {
  setCurrentFeedsList,
  switchCurrentFeed,
} from '../../redux/publishing/actions'
import { FirebaseContext } from '../../utils/Firebase'
import { buildEventName, buildParty } from '../../utils/helpers/buildTeams'

const { Meta } = Card

function BrowseEventCard({
  event,
  setCurrentFeedsListAction,
  switchCurrentFeedAction,
}) {
  // console.log('event',event, event.sport, event.formattedTimestamp)
  const history = useHistory()
  const firebase = useContext(FirebaseContext)

  const publisherId = useSelector(
    (state) => state.publishing.publishingAs?.id ?? 0
  )

  const { parties } = event
  const [isLoading, setIsLoading] = useState(true)
  const [imagesLoading, setImagesLoading] = useState(true)
  const [partyOne, setPartyOne] = useState()
  const [partyTwo, setPartyTwo] = useState()
  const [eventName, setEventName] = useState()
  const [modalOpen, setModalOpen] = useState(false)
  const [featuredLoading, setFeaturedLoading] = useState(false)
  const [submittingModal, setSubmittingModal] = useState(false)
  const [newFeedImg, setNewFeedImg] = useState()
  const [newFeedName, setNewFeedName] = useState()
  const [newFeedDescription, setNewFeedDescription] = useState()

  useEffect(() => {
    buildParty(parties[0], event).then((res) => {
      setPartyOne(res)
    })
    buildParty(parties[1], event).then((res) => {
      setPartyTwo(res)
    })
  }, [parties])

  useEffect(() => {
    if ((partyOne && partyTwo) || (partyOne && partyTwo === 'single')) {
      setIsLoading(false)
    }
  }, [partyOne, partyTwo])

  useEffect(() => {
    setEventName(buildEventName(partyOne, partyTwo))
  }, [partyOne, partyTwo])

  const handleModalSubmit = async () => {
    setSubmittingModal(true)
    firebase
      .createNewRegularFeed(
        event,
        newFeedName,
        newFeedDescription,
        newFeedImg,
        publisherId
      )
      .then(async (res) => {
        if (!res) {
          setSubmittingModal(false)
          message.error(
            'There was an error creating a new feed, please try again.'
          )
        } else {
          await firebase
            .getCollection('publisher')
            .doc(publisherId)
            .update({ lastUsedCurrentFeedId: res.id })

          getPublisherUpcomingFeeds(publisherId)
            .then((res) => {
              switchCurrentFeedAction(res[0])
              setCurrentFeedsListAction(res, publisherId)
              console.log('res', res)
              setSubmittingModal(false)
              setNewFeedName(null)
              setNewFeedDescription(null)
              setNewFeedImg(null)
              history.push('/publisher')
            })
            .catch((err) => console.log(err))
        }
      })
  }

  const handleClick = (bol) => {
    setModalOpen(bol)
  }

  return (
    <>
      <Card
        hoverable
        style={{ width: 300 }}
        actions={[
          imagesLoading ? (
            <Skeleton.Button active={isLoading} type="text" />
          ) : (
            <Button
              type="text"
              icon={<PlusOutlined />}
              key="addFeed"
              onClick={() => handleClick(true, event)}
            >
              Add a Feed
            </Button>
          ),
          imagesLoading ? (
            <Skeleton.Button active={isLoading} type="text" />
          ) : event.featured ? (
            <Button
              type="text"
              icon={<CloseOutlined />}
              key="unfeature"
              onClick={() => firebase.unfeature(event.id, setFeaturedLoading)}
              loading={featuredLoading}
            >
              Unfeature
            </Button>
          ) : (
            <Button
              type="text"
              icon={<FlagOutlined />}
              key="feature"
              onClick={() =>
                firebase.makeFeatured(event.id, setFeaturedLoading)
              }
              loading={featuredLoading}
            >
              Feature
            </Button>
          ),
        ]}
        cover={
          <BrowseEventCardCover
            isLoading={isLoading}
            partyOne={partyOne}
            partyTwo={partyTwo}
            setImagesLoading={setImagesLoading}
          />
        }
      >
        <Skeleton loading={imagesLoading}>
          <Meta
            title={eventName}
            description={
              <Typography.Text>
                {event.formattedTimestamp.day} {event.formattedTimestamp.month}{' '}
                {event.formattedTimestamp.date} at{' '}
                {event.formattedTimestamp.time}
                {/* {event.allBroadcasters.logoUrl
                ?
                  <Typography.Text> on <Image src={event.allBroadcasters.logoUrl} /></Typography.Text>
                : 
                null
              } */}
              </Typography.Text>
            }
          />
          <Row gutter={[0, 6]} style={{ marginTop: 20 }}>
            <Col span={24}>
              <FlagOutlined />{' '}
              <Typography.Text>
                <span style={{ fontWeight: 600 }}>
                  {event.officialSubscribedUserCount
                    ? event.officialSubscribedUserCount
                    : 0}
                </span>{' '}
                Subscribers
              </Typography.Text>
            </Col>
            <Col span={24}>
              <FlagOutlined />{' '}
              <Typography.Text>
                <span style={{ fontWeight: 600 }}>{event.huddleCount}</span>{' '}
                Huddles
              </Typography.Text>
            </Col>
          </Row>
          {event.allBroadcasters.logoUrl ? (
            <Row>
              <Col
                style={{
                  position: 'absolute',
                  top: 10,
                  right: 10,
                  height: 30,
                  maxWidth: 100,
                  background: 'rgba(255,255,255,0.8)',
                  borderRadius: 20,
                  padding: '5px 10px',
                }}
              >
                <Image
                  preview={false}
                  height="100%"
                  style={{ objectFit: 'contain' }}
                  src={event.allBroadcasters.logoUrl}
                />
              </Col>
            </Row>
          ) : null}
        </Skeleton>
      </Card>
      {/* <ModalSwitcher modalName={'ADD_FEED'} setModalOpen={setModalOpen} modalOpen={modalOpen} event={event}/> */}
      <Modal
        title={'Add a Feed'}
        visible={modalOpen}
        centered
        onOk={handleModalSubmit}
        onCancel={() => setModalOpen(false)}
        width={500}
        okText="Create Feed"
        confirmLoading={submittingModal}
      >
        <AddFeedToEvent
          setFeedDescription={setNewFeedDescription}
          setFeedName={setNewFeedName}
          setFeedImg={setNewFeedImg}
          feedImg={newFeedImg}
          feedName={newFeedName}
          feedDescription={newFeedDescription}
          event={event}
        />
      </Modal>
    </>
  )
}

export default connect(null, {
  setCurrentFeedsListAction: setCurrentFeedsList,
  switchCurrentFeedAction: switchCurrentFeed,
})(BrowseEventCard)
