import { CheckCircleOutlined, CheckCircleTwoTone, PushpinTwoTone } from '@ant-design/icons';
import { Avatar, Col, Empty, Form, List, Row, Tooltip, Typography } from 'antd';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FirebaseContext } from '../../utils/Firebase';

const beginningOfToday = moment().startOf('day').utc().local().format('YYYY-MM-DD')

export default function AdSetFilteredFeeds({ form, adSetData }){
  const firebase = useContext(FirebaseContext)
  const publisherId = useSelector(
    (state) => state.publishing.publishingAs?.id ?? 0
  )
  const [ upcomingFeeds, setUpcomingFeeds ] = useState([])
  const [ combinedFeedsList, setCombinedFeedsList ] = useState([])
  const [ allFeedsWithEventData, setAllFeedsWithEventData ] = useState([])
  const [ filteredFeeds, setFilteredFeeds ] = useState([])
  const [ selectedFeeds, setSelectedFeeds ] = useState([])

  const getEventData = async (eventIdArr) => {
    if(eventIdArr.length > 0 && allFeedsWithEventData.length === 0){
      let arr = await Promise.all(eventIdArr.map(obj => {
        return firebase.getCollection('events').doc(obj.eventId)
          .get()
          .then(docRef => {
            let doc = docRef.data()
            let eventObj = {
              sport:doc.sport,
              type:doc.type,
              league:doc.league,
              parties:doc.parties,
            }
            return {event:eventObj, feed:obj.feed}
          })
      }))
      setAllFeedsWithEventData(arr)
    }
  }

  const filterFeeds = async (feedsWithEventData) => {
    let adSetEventTypes = [...adSetData.events]
    let adSetLeagues = [...adSetData.leagues]
    let adSetTeams = [...adSetData.teams]
    let filtered = []

    // FILTER EVENT TYPES
    if(adSetEventTypes.length > 0 && feedsWithEventData.length > 0){
      let arr = []
      adSetEventTypes.map(adSetEvent => {
        return feedsWithEventData.map(eventAndFeedObj => {
          if(eventAndFeedObj.event.sport === adSetEvent.toLowerCase()){
            arr.push(eventAndFeedObj)
          }
        })
      })
      filtered = [...arr]
    }

    if(adSetLeagues.length > 0 && filtered.length > 0){
      let arr = []
      adSetLeagues.map(league => {
        return filtered.map(eventAndFeedObj => {
          if(eventAndFeedObj && eventAndFeedObj.event.league === league){
            arr.push(eventAndFeedObj)
          }
        })
      })
      filtered = [...arr]
    }

    if(adSetTeams.length > 0 && filtered.length > 0){
      let arr = []
      adSetTeams.map(team => {
        return filtered.map(eventAndFeedObj => {
          if(eventAndFeedObj){
            return eventAndFeedObj.event.parties.map(filteredTeamObj => {
              if(filteredTeamObj.id === team.id){
                arr.push(eventAndFeedObj)
              }
            })
          }
        })
      })
      filtered = [...arr]
    }

    return filtered
  }

  const filterForUniqueFeeds = async (arr) => {
    let uniqueEventAndFeeds = arr.filter((obj, index) => {
      let i = arr.findIndex(obj2 => {
        return obj.feed.id === obj2.feed.id
      })
      return i === index;
    })
    let uniqueFeeds = uniqueEventAndFeeds.map(u => u.feed)
    return uniqueFeeds
  }

  useEffect(()=>{
    if(!publisherId)return
    const unsubscribe = firebase.getCollection('feeds')
    .where('publisherId', '==', publisherId)
    .where('eventScheduledTime', '>=', beginningOfToday)
    .onSnapshot(snap => {
        let feedArr = []
        snap.docs.map(async docRef => {
          let doc = docRef.data()
          feedArr.push(doc)
        })
        setUpcomingFeeds(feedArr)
      })
    return () => unsubscribe()
  },[publisherId])

  useEffect(()=>{
    if(!publisherId)return
    const unsubscribe = firebase.getCollection('perpetual_feeds')
    .where('publisherId', '==', publisherId)
    .onSnapshot(snap => {
        let feedsArr = [...upcomingFeeds]
        snap.docs.map(docRef => {
          let doc = docRef.data()
          feedsArr.push(doc)
        })
        setCombinedFeedsList(feedsArr)
      })
    return () => unsubscribe()
  },[publisherId, upcomingFeeds])

  useEffect(()=>{
    let allFeedsEventIdsArr = []
    if(combinedFeedsList && combinedFeedsList.length > 0){
      combinedFeedsList.map(feed => {
        if(feed.eventIds){
          feed.eventIds.map(eventId => {
            allFeedsEventIdsArr.push({feed:feed, eventId})
          })
        }else if(feed.eventId){
          allFeedsEventIdsArr.push({feed:feed, eventId:feed.eventId})
        }
      })
    }
    if(allFeedsEventIdsArr.length > 0){
      getEventData(allFeedsEventIdsArr)
    }
  },[combinedFeedsList])

  useEffect(()=>{
    filterFeeds(allFeedsWithEventData).then(filteredFeedsAndEventsArr => {
      filterForUniqueFeeds(filteredFeedsAndEventsArr).then(unique => {
        setFilteredFeeds(unique)
      })
    })
  },[allFeedsWithEventData, adSetData])

  const handleFeedClick = (feed) => {
    let newSelection = [...selectedFeeds]
    let selectedFeedIndex = newSelection.indexOf(feed)
    if(selectedFeedIndex !== -1){
      newSelection.splice(selectedFeedIndex, 1)
    }else{
      newSelection.push(feed)
    }
    form.setFieldsValue({
      feeds:newSelection
    })
    setSelectedFeeds(newSelection)
  }

  const showHideCheck = (feed) => {
    let i = selectedFeeds.findIndex(selectedFeed => {
      return selectedFeed === feed
    })
    if(i !== -1){
      return <CheckCircleTwoTone style={{fontSize:20}}/> 
    }else{
      return <CheckCircleOutlined style={{fontSize:20, opacity:0.25}}/> 
    }
  }

  return(
    <Row style={adSetFeedsStyleProps}>
      <Col span={24}>
        <Typography.Title
          level={4} 
          style={{
            margin:0
          }}
        >
          Available Feeds
        </Typography.Title>
        <Typography.Text
          type='secondary'
        >
          Select which feeds to run ads on
        </Typography.Text>
      </Col>
      <Col span={24}>
      {filteredFeeds && filteredFeeds.length > 0
        ?
          <Form.Item
            name='feeds'
          >
          <List
            style={{marginTop:20}}
            itemLayout="horizontal"
            dataSource={filteredFeeds}
            size='small'
            rowKey={record => record.id}
            renderItem={feed => (
              <List.Item 
                style={{cursor:'pointer', paddingLeft:20, paddingRight:20}}
                onClick={() => handleFeedClick(feed)} 
              >
                <List.Item.Meta
                  avatar={
                    feed.picture 
                      ? 
                        <Avatar size={50} src={feed.picture} style={{borderRadius:4}}/> 
                      : 
                        <Avatar size={50}>f</Avatar>
                        // <Avatar size={50}>{feed.title.split('')[0]}</Avatar>
                  }
                  title={<Typography.Text strong>{feed.title} {feed.isPerpetual ? <Tooltip title='This is a perpetual Feed'><PushpinTwoTone/></Tooltip> : null}</Typography.Text>}
                  description={feed.description}
                />
                <Row>
                  {showHideCheck(feed)}
                </Row>

              </List.Item>
            )}
          />
          </Form.Item>
        :
          <Col span={24}>
            <Empty 
              image={Empty.PRESENTED_IMAGE_SIMPLE} 
              description='Begin by Selecting an Event Type to See Available Feeds'
            />
          </Col>
      }
    </Col>
    </Row>
  )
}

const adSetFeedsStyleProps = {
  width:'100%',
  background:'white', 
  borderRadius:8, 
  boxShadow:'0px 1px 2px rgba(0,0,0,0.25)', 
  paddingTop: 15,
  paddingBottom: 20,
  paddingLeft: 20,
  paddingRight: 20,
  marginTop:20,
}
