import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/firestore'
import 'firebase/functions'
import moment from 'moment'

import { FIREBASE, firebaseConfig } from '../constants/defaultValues'
import { formatTimestamp } from '../helpers/Utils'

firebase.initializeApp(firebaseConfig)

const auth = firebase.auth()
const database = firebase.database()
// const functions = firebase.functions().useEmulator("localhost", 5001);
const firestore = firebase
  .firestore()
  .collection('versions')
  .doc(FIREBASE.VERSION)
const googleProvider = new firebase.auth.GoogleAuthProvider()
const facebookProvider = new firebase.auth.FacebookAuthProvider()
const appleProvider = new firebase.auth.OAuthProvider('apple.com')

const getOpineUser = (providerData) =>
  firestore
    .collection('publisher')
    .doc(providerData.uid)
    .get()
    .then(async (snap) => {
      if (snap.exists) {
        return snap.data()
      } else {
        console.log('nope')
        return {
          id: providerData.uid,
          noProfile: true,
          description: 'Provider: Google',
          name: providerData.displayName,
          profilePic: providerData.photoURL,
          publishingAs: providerData.uid,
        } //TODO: uid is inconsistent with the 'id' field of the Email based user that uses 'id' field.
      }
    })
    .catch((error) => error)

const getPublishingAs = async (publisherId) => {
  try {
    const snap = await firestore.collection('publisher').doc(publisherId).get()
    if (snap.exists) {
      return snap.data()
    } else {
      return Promise.reject(
        new Error(
          'Trouble Finding Publisher. Please Try again in a few minutes.'
        )
      )
    }
  } catch (err) {
    return err
  }
}

export const getFeedById = async (feedId) =>
  firestore
    .collection('feeds')
    .doc(feedId)
    .get()
    .then((snap) => {
      if (snap.exists) {
        console.log('exists')
        return snap.data()
      }
    })
    .catch((error) => error)

export const getPublisherPastFeeds = async (publisherId) => {
  let start = moment().endOf('day').utc().format()
  try {
    let snap = await firestore
      .collection('feeds')
      .orderBy('eventScheduledTime', 'desc')
      .where('publisherId', '==', publisherId)
      .where('eventScheduledTime', '<=', start)
      .limit(100)
      .get()

    let feeds = []
  
    if (snap.exists) {
      snap.docs.map((docRef) => {
        let doc = docRef.data()
        // console.log('doc',doc)
        let formattedTimestamp = formatTimestamp(doc.eventScheduledTime)
        doc = { ...doc, formattedTimestamp, id: docRef.id }
        feeds.push(doc)
      })
    }
    return feeds
    // else{
    //   return Promise.reject( new Error("Trouble Finding Publisher Past Feeds. Please Try again in a few minutes."))
    // }
  } catch (err) {
    return err
  }
}

export const getPublisherUpcomingFeeds = async (publisherId) => {
  let start = moment().startOf('day').utc().format()
  let end = moment().add(2, 'week').endOf('day').utc().format()
  return (
    firestore
      .collection('feeds')
      .where('publisherId', '==', publisherId)
      .where('eventScheduledTime', '>=', start)
      .where('eventScheduledTime', '<=', end)
      .orderBy('eventScheduledTime', 'asc')
      // .limit(200)
      .get()
      .then((snap) => {
        // console.log('snap', snap)
        let feeds = []
        snap.docs.map((docRef) => {
          let doc = docRef.data()
          const formattedTimestamp = formatTimestamp(doc.eventScheduledTime)
          doc = { ...doc, formattedTimestamp, id: docRef.id }
          feeds.push(doc)
        })
        return feeds
      })
      .catch((err) => err)
  )
}

export {
  appleProvider,
  auth,
  database,
  facebookProvider,
  firestore,
  getOpineUser,
  getPublishingAs,
  googleProvider
}

