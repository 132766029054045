import React, { useState } from 'react';
import { Upload, Image, message, Tooltip, Button, Row, Col, Modal, Spin } from 'antd'
import ImgCropper from '../ImgCropper/ImgCropper'
import Resizer from "react-image-file-resizer";

import { PictureOutlined, PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';

const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      800,
      800,
      "JPEG",
      70,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64",
      500,
      500
    );
  });

export default function IconUploader({ setFile }){
  const [ uploadedFile, setUploadedFile ] = useState(null)
  const [ croppedData, setCroppedData ] = useState()
  const [ modalOpen, setModalOpen ] = useState(false)
  const [ isUploading, setIsUploading ] = useState(false)

  const handleUpload = async (upload) => {
    try {
      const image = await resizeFile(upload.file);
      upload.onSuccess(image)
    } catch (err) {
      upload.onError(err)
    }
  }

  const handleChange = async (info) => {
    const isImage = info.file.type === 'image/jpeg' || info.file.type === 'image/png'
    if (!isImage) {
      return message.error('Please try agian, the file type you tried to upload is not currently supported')
    } else {
      const { status } = info.file;
      if (status !== 'uploading') {
        setUploadedFile(null)
        setIsUploading(true)
      }
      if (status === 'done') {
        setModalOpen(true)
        setUploadedFile(info.file.response)
        setIsUploading(false)
      } else if (status === 'error') {
        console.log('error',info.file)
        message.error(`${info.file.name} file upload failed.`);
        message.error(`info ${info.file}`);
        setIsUploading(false)
        setModalOpen(false)
        setUploadedFile(null)
      }
    }
  }

  const handleModalSubmit = () => {
    setFile(croppedData)
    setModalOpen(false)
    setUploadedFile(null)
    setCroppedData(null)
  }
  
  const handleModalCancel = () => {
    setUploadedFile(null)
    setCroppedData(null)
    setModalOpen(false)
  }

  const uploadProps = {
    name: 'file',
    multiple: false,
    showUploadList: false,
    style: { padding: '0 2em' },
    customRequest: handleUpload,
    onChange: handleChange,
  }

  const modalProps = {
    title: 'Image Crop',
    visible: modalOpen,
    width: 700,
    centered: true,
    okText: 'Done',
    onOk: handleModalSubmit,
    onCancel: handleModalCancel,
    destroyOnClose: true,
  }

  return (
      <Spin spinning={isUploading}>
        <Row justify='center'>
          <Upload {...uploadProps}>
              <Button
                block
                type='link'
                icon={<PlusOutlined />}
                style={{marginBottom:20}}
              >
                Add an Image
              </Button>
          </Upload>
        </Row>
        <Modal {...modalProps}>
          <ImgCropper
            src={uploadedFile}
            setCroppedData={setCroppedData}
            isUploading={isUploading}
          />
        </Modal>
      </Spin>
  )
};