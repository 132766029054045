import React, { useState, useContext } from 'react'
import { Card, Typography, message, Row, Col, Button, Modal } from 'antd'
import { updateScheduled, deleteScheduled, handleWriteNewPrompt } from '../PromptCreator/PromptWriter'
import EditPromptModal from '../../../modals/EditPromptModal/EditPromptModal';

import { FirebaseContext } from '../../../utils/Firebase'
import AttachAdToPrompt from '../PromptCreator/AttachAdToPrompt';
import AttachScheduleToPrompt from '../PromptCreator/AttachScheduleToPrompt';

export default function ScheduledPromptCard({ prompt }){
  const firebase = useContext(FirebaseContext)
  const [ modalOpen, setModalOpen ] = useState(false)
  const [ isPosting, setIsPosting ] = useState(false)
  const [ editedDraft, setEditedDraft ] = useState()
  const [ img, setImg ] = useState()
  const [ mediaTwoUpOne, setMediaTwoUpOne ] = useState()
  const [ mediaTwoUpTwo, setMediaTwoUpTwo ] = useState()

  const handlePostNow = () => {
    if(prompt.scheduling){
      delete prompt.scheduling
    }
    handleWriteNewPrompt(prompt, 'prompts')
      .then(()=>{
          deleteScheduled(prompt)
        })
  }

  const handlePromptClick = () => {
    setEditedDraft(prompt)
    setModalOpen(true)
  }

  const handleModalSubmit = async () => {
    setIsPosting(true)

    let DbPrompt = {...editedDraft}
    if(img) DbPrompt = {...DbPrompt, photoURL: await firebase.handleFirebaseImgUpload(img) }
    if(mediaTwoUpOne){
      let newObj = {...DbPrompt}
      let imgUrl = await firebase.handleFirebaseImgUpload(mediaTwoUpOne)
      newObj.responses[0] = imgUrl
      DbPrompt = {...newObj}
    } 
    if(mediaTwoUpTwo){
      let newObj = {...DbPrompt}
      let imgUrl = await firebase.handleFirebaseImgUpload(mediaTwoUpTwo)
      newObj.responses[1] = imgUrl
      DbPrompt = {...newObj}
    } 
    
    updateScheduled(DbPrompt, 'prompt_drafts')
      .then(() => {
        setIsPosting(false)
        setModalOpen(false)
      }).catch(err => {
        message.error('Error sending prompt, please try again')
        console.log('error:', err)
        setIsPosting(false)
      })
  }

  const handleScheduleDelete = () => {
    delete prompt.scheduling
    handleWriteNewPrompt(prompt, 'prompt_drafts')
      .then(()=>{
        deleteScheduled(prompt)
          .then(()=>{
            console.log('scheduled prompt deleted successfully')
          }).catch(err=>console.log('error:', err))
      }).catch(err => {
        message.error('Error sending prompt, please try again')
        console.log('error:', err)
      })
  }

  const handleModalCancel = () => {
    setModalOpen(false)
    setEditedDraft(null)
  }

  return(
    <Row>
      <Card
        hoverable
        style={{width:'100%'}}
        title={
          <Row justify='space-between'>
            <Typography.Text type='secondary'>Scheduled Prompt</Typography.Text>
            <Button danger onClick={()=>deleteScheduled(prompt)}>Delete</Button>
          </Row>
        }
        actions={[
          <Col span={24}>
            <Button type='primary' size='large' block loading={isPosting} onClick={()=>handlePostNow(prompt)}>Post Now</Button>
          </Col>
        ]}
      >
        <Row gutter={[16,16]} onClick={()=>handlePromptClick(true)}>
          <Col span={24}>
            <Typography.Title level={3}>
              {prompt.text}
            </Typography.Title>
          </Col>
          {prompt.responses.map((response, index) => (
            <Col span={24} key={index}>
              <Button shape='round' style={{width:'100%'}}>
                <Typography.Text strong>
                  {prompt.responses[index]}
                </Typography.Text>
              </Button>
            </Col>
          ))}
        </Row>
        <Row style={{marginTop:10}}>
          <AttachAdToPrompt
            prompt={prompt}
            setPrompt={updateScheduled}
          />
        </Row>
        <Row style={{marginTop:10}}>
          <AttachScheduleToPrompt
            prompt={prompt}
            setPrompt={updateScheduled}
            handleDelete={handleScheduleDelete}
          />
        </Row>
      </Card>
      <Modal
        title={'Edit Scheduled Prompt'}
        visible={modalOpen}
        centered
        onOk={handleModalSubmit}
        onCancel={handleModalCancel}
        width={500}
        okText='Update Prompt'
        confirmLoading={isPosting}
      >
        <EditPromptModal prompt={editedDraft} setPrompt={setEditedDraft} setImg={setImg} setMediaTwoUpOne={setMediaTwoUpOne} setMediaTwoUpTwo={setMediaTwoUpTwo} handleDelete={handleScheduleDelete}/>
      </Modal>
    </Row>
  )
}