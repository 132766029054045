import React, { useState, useContext } from 'react'

import { Button, Modal, Row, Col, Radio } from 'antd';
import AttachedAdDetailsSmall from '../../AdsManager/AttachedAdDetailsSmall';

import { FirebaseContext } from '../../../utils/Firebase'
import CreateNewAdModal from '../../../modals/CreateNewAdModal/CreateNewAdModal';
import SavedAdModal from '../../../modals/SavedAdModal/SavedAdModal';
import { TagOutlined } from '@ant-design/icons';

const adObjInitialValues = {
  callOutText: '',
  sponsorLogo: '',
  sponsorName: '',
  sponsorUrl: '',
  sponsorUrlType: 'link',
  urlLink: '',
  filterSet:[]
}

export default function AttachAdToPrompt({ 
  prompt,
  setPrompt,
  form
}){
  const firebase = useContext(FirebaseContext)
  const [ adModalOpen, setAdModalOpen ] = useState(false)
  const [ selectedKey, setSelectedKey ] = useState('new')
  const [ formValid, setFormValid ] = useState(true)
  const [ adObj, setAdObj ] = useState(adObjInitialValues)
  const [ selectedAdsArr, setSelectedAdsArr ] = useState([])

  console.log('adObj',adObj)
  // console.log('form',form.getFieldsValue())

  const handleModalSubmit = async () => {
    // if(adObj){
      let formValues = form.getFieldsValue()
      let newAdObj = {...formValues}
      if(newAdObj.sponsorLogo){
        let firebaseUrl = await firebase.handleFirebaseImgUpload(newAdObj.sponsorLogo)
        newAdObj = {...newAdObj, sponsorLogo:firebaseUrl}
      }
      
      if(newAdObj.sponsorUrlType !== 'link' && newAdObj.sponsorUrl){
          let firebaseUrl = await firebase.handleFirebaseImgUpload(newAdObj.sponsorUrl)
          newAdObj = {...newAdObj, sponsorUrl:firebaseUrl}
      }
      let adsArr
      if(prompt.ads){
        adsArr = [...prompt.ads]
      }else{
        adsArr = []
      }
      adsArr.push(newAdObj)
      setPrompt({...prompt, ads:adsArr})
    // }

    if(selectedAdsArr && selectedAdsArr.length > 0){
      setPrompt({...prompt, ads:selectedAdsArr})
    }

    setAdObj(adObjInitialValues)
    setSelectedAdsArr([])
    setFormValid(false)
    setAdModalOpen(false)
  }

  const handleModalCancel = () => {
    setAdObj(adObjInitialValues)
    setAdModalOpen(false)
  }

  const handleAdTypeSelection = (e) => {
    setSelectedKey(e.target.value)
  }

  return(
    <Row style={{width:'100%'}}>
      {prompt && prompt.ads && prompt.ads.length > 0
        ? 
          <Col span={24} style={{display:'flex', flexDirection:'column'}}>
            {/* <Typography.Text>Ad Details:</Typography.Text> */}
            <AttachedAdDetailsSmall setPrompt={setPrompt} prompt={prompt} />
          </Col>
        :
        null
      }
      <Col span={24}>
        <Button type='link' block onClick={()=>setAdModalOpen(true)} icon={<TagOutlined/>}>Attach an Ad</Button>
      </Col>
      <Modal
        title='Attach an Ad'
        visible={adModalOpen}
        centered
        onOk={handleModalSubmit}
        onCancel={handleModalCancel}
        width={1000}
        okText='Attach Add'
        bodyStyle={{background:'#f0f2f5'}}
        okButtonProps={{disabled:!formValid}}
      >
        {/* <CreateNewAdModal 
          setFormValid={setFormValid}
          adObj={adObj}
          setAdObj={setAdObj}
        /> */}
        <Row justify='center'>
          <Radio.Group size='large' buttonStyle='solid' defaultValue="new" onChange={handleAdTypeSelection} style={{marginBottom: 20}}>
            <Radio.Button value='new'>Create New Ad</Radio.Button>
            <Radio.Button value='saved'>Use Saved Ad</Radio.Button>
          </Radio.Group>
        </Row>
        
          {selectedKey === 'new'
            ?
              <CreateNewAdModal 
                setFormValid={setFormValid}
                adObj={adObj}
                setAdObj={setAdObj}
                form={form}
              />
            :
              <SavedAdModal
                setFormValid={setFormValid}
                selectedAdsArr={selectedAdsArr}
                setSelectedAdsArr={setSelectedAdsArr}
              />
          }
      </Modal>
    </Row>
  )
}