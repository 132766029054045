import moment from 'moment'
import numeral from 'numeral'
import React, { useEffect, useState } from 'react'
import './EventAnalysisSinglePrompt.scss'

// COMPONENTS
// import ScaleStatementCard from '../../components/StatementCard/ScaleStatementCard'

// FIREBASE
import { Button, Card, Col, Row, Skeleton, Typography } from 'antd'
import { useHistory } from 'react-router'
import ResultCardWrapper from '../../Cards/ResultCards/ResultCardWrapper_V2'
import Firebase from '../../utils/Firebase'
const firebase = new Firebase()

export default function SinglePrompt(props){
  const history = useHistory()
  const [promptObj, setPromptObj] = useState()
  const [publisher, setPublisher] = useState(null)
  const [feed, setFeed] = useState(null)
  const [avgIntensity, setAvgIntensity] = useState()
  const [promptTime, setPromptTime] = useState()
  const [isLoading, setIsLoading] = useState(true)


  const promptId = props.match.params
  // console.log('promptObj',promptObj)

  useEffect(()=>{
    setIsLoading(true)
    firebase.getCollection('prompts').doc(promptId.promptId)
      .get()
      .then(doc => {
        if(doc.exists){
          setPromptObj(doc.data())
        } else {
          console.log("No such document!");
        }
      })
  },[promptId])

  // GET PUBLISHER INFO
  useEffect(() => {
    if(promptObj){
    firebase.getCollection('publisher').doc(promptObj.publisherId)
      .get()
      .then( doc => {
        if(doc.exists){
          setPublisher(doc.data())
        } else {
          console.log("No such document!");
        }
      })
    }
  },[promptObj])

  // GET FEED INFO
  useEffect(() => {
    if(promptObj){
    // setPromptTime(moment.utc(promptObj.timestamp._seconds, 'YYYY-MM-DDThh:mm:ss').local())
    // console.log('timestammp',moment.unix(promptObj.timestamp.seconds).format())
    setPromptTime(moment.unix(promptObj.timestamp.seconds).format())

    firebase.getCollection('feeds').doc(promptObj.feedId)
      .get()
      .then( doc => {
        if(doc.exists){
          setFeed(doc.data())
        } else {
          console.log("No such document!");
        }
      })
    }
  },[promptObj])  

  useEffect(()=>{
    if(promptObj !== null && publisher !== null && feed !== null){
      setIsLoading(false)
    }
  },[promptObj, publisher, feed])


  useEffect(()=>{
    let sumIntensity = 0
    if(promptObj){
      for(let [key, value] of Object.entries(promptObj.avgIntensityByChoice)){
        // console.log(key)
        sumIntensity += value
      }
      setAvgIntensity(sumIntensity/Object.keys(promptObj.avgIntensityByChoice).length)
    }
  },[promptObj])

  return(
    isLoading 
      ? 
        <Skeleton/> 
      : 
      <Row align='middle' justify='center' gutter={16} style={{ padding:20, marginTop:50}}>
          <Col xs={{span:24, offset:0}} sm={{span:24, offset:0}} md={{span:12, offset:0}} lg={{span:8, offset:0}} xl={{span:8, offset:0}} xxl={{span:6, offset:0}}>
            <ResultCardWrapper 
              feed={feed} 
              prompt={promptObj} 
              isLoading={isLoading} 
              // prevPostTimestamp={prevPostTimestamp}
            />
          </Col>
          <Col span={10}>
            <Card style={{background:'transparent'}}>
              <Typography.Title level={1}>About This Prompt</Typography.Title>
              <Row gutter={16}>
                <Col span={12} style={{display:'flex', flexDirection:'column'}}>
                  <Typography.Title level={3}>Publisher Information</Typography.Title>
                  <Typography.Text type='secondary' style={{marginBottom: 10}}>
                    This data was sourced directly from active<br/> Opine users by Opine Inc.
                  </Typography.Text>


                  <Typography.Text strong>
                    Publisher: <Typography.Text type='secondary'>{publisher.name}</Typography.Text>
                  </Typography.Text>

                  <Typography.Text strong>
                    Date Published: <Typography.Text type='secondary'>{moment(promptTime).format('MMM DD YYYY')}, {moment(promptTime).format('h:mm a')}</Typography.Text>
                  </Typography.Text>

                  <Typography.Text strong>
                    Event: <Typography.Text type='secondary'>{feed.eventName}</Typography.Text>
                    </Typography.Text>

                  <Typography.Text strong>
                    Feed: <Typography.Text type='secondary'>{feed.title}</Typography.Text>
                  </Typography.Text>

                  <Typography.Title level={3}>Participant Information</Typography.Title>

                  <Typography.Text type='secondary' style={{marginBottom: 10}}>
                    Participant information is based on active<br/> Opine users by Opine Inc.
                  </Typography.Text>

                  <Typography.Text strong>
                    Pool Size: <Typography.Text type='secondary'>{promptObj.totalUsersReached}</Typography.Text>
                  </Typography.Text>

                  <Typography.Text strong>
                    Participating Huddles: <Typography.Text type='secondary'>{promptObj.huddleCount}</Typography.Text>
                  </Typography.Text>

                  <Typography.Text strong>
                    Answer Rate Percentage: <Typography.Text type='secondary'>{numeral(promptObj.answerRatePercentage).format('00')}%</Typography.Text>
                  </Typography.Text>

                  <Typography.Text strong>
                    Average Response Intensity: <Typography.Text type='secondary'>{numeral(avgIntensity).format('00')}%</Typography.Text>
                  </Typography.Text>

                </Col>
              </Row>
            </Card>
          </Col>
        <Button size='large' shape='round' style={{position:'absolute', bottom:20}} type='primary' onClick={() => history.push('/eventanalysis')}>Go Back</Button>
      </Row>
  )
}
