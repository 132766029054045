import { Card, Col, Image, Row } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { switchPublishingAs } from '../../redux/publishing/actions'
import { FirebaseContext } from '../../utils/Firebase'

const { Meta } = Card
const width = 200

function SwitchUserModal({ setModalOpen, switchPublishingAsAction }) {
  const firebase = useContext(FirebaseContext)

  const currentUser = useSelector((state) => state.authUser.currentUser)
  const publishingAs = useSelector((state) => state.publishing.publishingAs)

  const [availablePublisherDocs, setAvailablePublisherDocs] = useState()

  const handleClick = (pubId) => {
    switchPublishingAsAction({
      publishingAs: pubId,
      id: publishingAs?.id ?? 0,
    })
    // setPastFeedsListAction()
    // dispatch({type:'SET_USER', payload:{...currentUser, lastPublisherUsed:pubId}})
    // firebase.getCollection('publisher').doc(currentUser.id).update({lastPublisherUsed:pubId})
    // dispatch({type:'SET_ACTIVE_EVENT', payload:null})
    // dispatch({type:'SET_ACTIVE_FUTURE_FEED', payload:null})
    // dispatch({type:'SET_ACTIVE_PAST_FEED', payload:null})
    // dispatch({type:'SET_UPCOMING_FEEDS', payload:[]})
    // dispatch({type:'SET_PAST_FEEDS', payload:[]})
    setModalOpen(false)
  }

  // useEffect(() => {
  //   if (error) {
  //     NotificationManager.warning(error, 'Login Error', 3000, null, null, '');
  //   }
  // }, [error]);

  useEffect(() => {
    if (!currentUser || !currentUser.authorizedPublisherIds) return
    const unsubscribe = getPublisherDocs(currentUser.authorizedPublisherIds)
    return () => unsubscribe
    // setAvailablePublisherIds(doc)
  }, [publishingAs])

  const getPublisherDocs = async (pidArr) => {
    let arr = await Promise.all(
      pidArr.map((pid) => {
        return firebase
          .getCollection('publisher')
          .doc(pid)
          .get()
          .then((res) => {
            let doc = res.data()
            doc = { ...doc, id: res.id }
            return doc
          })
      })
    )

    if ((publishingAs?.id ?? 0) !== currentUser.id) {
      arr.unshift(currentUser)
    }
    setAvailablePublisherDocs(arr)
  }

  return (
    <Row gutter={16}>
      {availablePublisherDocs && availablePublisherDocs.length > 0 ? (
        availablePublisherDocs.map((publisherDoc) => {
          // console.log('publisherDoc',publisherDoc)
          return (
            <Col span={8} key={publisherDoc.id}>
              <Card
                hoverable
                onClick={() => handleClick(publisherDoc.id)}
                style={{ width: width }}
                cover={
                  <Image
                    width={width}
                    height={width}
                    preview={false}
                    alt={publisherDoc.handle}
                    src={publisherDoc.profilePic}
                  />
                }
              >
                <Meta title={publisherDoc.name} />
              </Card>
            </Col>
          )
        })
      ) : (
        <Col>user</Col>
      )}
    </Row>
  )
}

export default connect(null, {
  switchPublishingAsAction: switchPublishingAs,
})(SwitchUserModal)
