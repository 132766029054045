import React from 'react'
import { Radio, Form } from 'antd'

export default function BaseballScheduler({ setSchedule, schedule }){

  const handleChange = (e) => {
    let newSchedule = {...schedule, [e.target.name]:e.target.value}
    setSchedule(newSchedule)
  }

  return(
    <Form layout='vertical' requiredMark='false'>
      <Form.Item label="Inning">
        <Radio.Group value={schedule && schedule.sendInPeriod} size='medium' onChange={handleChange} name='sendInPeriod'>
          <Radio.Button style={{margin:5}} value={1}>1st Inning</Radio.Button>
          <Radio.Button style={{margin:5}} value={2}>2nd Inning</Radio.Button>
          <Radio.Button style={{margin:5}} value={3}>3rd Inning</Radio.Button>
          <Radio.Button style={{margin:5}} value={4}>4th Inning</Radio.Button>
          <Radio.Button style={{margin:5}} value={5}>5th Inning</Radio.Button>
          <Radio.Button style={{margin:5}} value={6}>6th Inning</Radio.Button>
          <Radio.Button style={{margin:5}} value={7}>7th Inning</Radio.Button>
          <Radio.Button style={{margin:5}} value={8}>8th Inning</Radio.Button>
          <Radio.Button style={{margin:5}} value={9}>9th Inning</Radio.Button>
          <Radio.Button style={{margin:5}} value={10}>Extra Innings</Radio.Button>
        </Radio.Group>
      </Form.Item>
      <Form.Item label="Top, Middle, or Bottom">
        <Radio.Group value={schedule && schedule.sendAtMinutes} size='medium' onChange={handleChange} name='sendAtMinutes'>
          <Radio.Button style={{margin:5}} value={1}>Top</Radio.Button>
          <Radio.Button style={{margin:5}} value={2}>Middle</Radio.Button>
          <Radio.Button style={{margin:5}} value={3}>Bottom</Radio.Button>
        </Radio.Group>
      </Form.Item>
      <Form.Item label="Outs">
        <Radio.Group value={schedule && schedule.sendAtSeconds} disabled={schedule && schedule.sendAtMinutes === 2 ? true : false} size='medium' onChange={handleChange} name='sendAtSeconds'>
          <Radio.Button style={{margin:5}} value={1}>One</Radio.Button>
          <Radio.Button style={{margin:5}} value={2}>Two</Radio.Button>
          <Radio.Button style={{margin:5}} value={3}>Three</Radio.Button>
        </Radio.Group>
      </Form.Item>
    </Form>
  )
}