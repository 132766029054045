import React, { useEffect, useRef, useState } from 'react'
import { Row, Col, Spin, Alert, Button } from 'antd'
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { RotateLeftOutlined, RotateRightOutlined, ReloadOutlined, ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons';

export default function ImgCropper({ src, setCroppedData, isUploading }){
  const cropperRef = useRef(null);
  const [ isReady, setIsReady ] = useState(false)

  useEffect(()=>{
    setIsReady(false)
  },[])

  const onReady = (e) => {
    setIsReady(true)
  }

  const onCrop = (e) => {
    const imageElement = cropperRef.current;
    const cropper = imageElement.cropper;
    // console.log('onCrop',cropper)
    setCroppedData(cropper.getCroppedCanvas().toDataURL())
  }

  const handleZoom = (val) => {
    const imageElement = cropperRef.current;
    const cropper = imageElement.cropper;
    cropper.zoom(val)
    setCroppedData(cropper.getCroppedCanvas().toDataURL())
  }

  const handleRotate = (val) => {
    const imageElement = cropperRef.current;
    const cropper = imageElement.cropper;
    cropper.rotate(val)
    setCroppedData(cropper.getCroppedCanvas().toDataURL())
  }

  const handleClear = () => {
    const imageElement = cropperRef.current;
    const cropper = imageElement.cropper;
    cropper.clear()
    setCroppedData(cropper.getCroppedCanvas().toDataURL())
  }

  const handleReset = () => {
    const imageElement = cropperRef.current;
    const cropper = imageElement.cropper;
    cropper.reset()
    setCroppedData(cropper.getCroppedCanvas().toDataURL())
  }

  const cropperProps = {
    src: src,
    initialAspectRatio: 16 / 9,
    guides: false,
    crop: onCrop,
    ref: cropperRef,
    dragMode: 'crop',
    ready: onReady,
    style: { width:'100%' },
  }

  const buttonProps = {
    size:'large', 
    shape:'round', 
    type:'primary'
  }

  return(
    <Spin spinning={isUploading}>
      <Row style={{ width:'100%' }}>
        {isUploading 
          ?
            <Col span={24}/>
          :
            <Spin spinning={!isReady}>
              <Col span={24}>
                <Cropper {...cropperProps}/>
              </Col>
              <Row gutter={8} style={{width:'100%', marginTop:10}} justify='end'>
                <Col>
                  <Button icon={<ZoomInOutlined/>} {...buttonProps} onClick={() => handleZoom(.1)}/>
                </Col>
                <Col>
                  <Button icon={<ZoomOutOutlined/>} {...buttonProps} onClick={() => handleZoom(-.1)}/>
                </Col>
                <Col>
                  <Button icon={<RotateLeftOutlined/>} {...buttonProps} onClick={() => handleRotate(-90)}/>
                </Col>
                <Col>
                  <Button icon={<RotateRightOutlined/>} {...buttonProps} onClick={() => handleRotate(90)}/>
                </Col>
                <Col>
                  <Button {...buttonProps} onClick={() => handleClear()}>Clear</Button>
                </Col>
                <Col>
                  <Button {...buttonProps} onClick={() => handleReset()}>Reset</Button>
                </Col>
              </Row>
            </Spin>
        }
      </Row>
    </Spin>
  )
}