import { Avatar, Card, Empty, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { useSelector } from 'react-redux'
import { getPublisherUpcomingFeeds } from '../../helpers/Firebase'
import {
  setCurrentFeedsList,
  switchCurrentFeed,
} from '../../redux/publishing/actions'
import Firebase from '../../utils/Firebase'
const firebase = new Firebase()

const { Meta } = Card

function PerpetualFeed({
  event,
  switchCurrentFeedAction,
  setCurrentFeedsListAction,
  setSubmittingModal,
}) {
  const history = useHistory()
  const publisherId = useSelector(
    (state) => state.publishing.publishingAs?.id ?? 0
  ) //uid=Google, id=Email user
  const [perpetualFeeds, setPerpetualFeeds] = useState()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (publisherId)
      firebase
        .getCollection('perpetual_feeds')
        .where('publisherId', '==', publisherId)
        .onSnapshot((snap) => {
          let feedArr = []
          snap.forEach((docRef) => {
            let doc = docRef.data()
            let alreadyPartOfEvent
            doc.eventIds.forEach((eventId) => {
              if (eventId === event.id) {
                alreadyPartOfEvent = true
              }
            })
            if (!alreadyPartOfEvent) {
              feedArr.push(doc)
            }
          })
          setPerpetualFeeds(feedArr)
          setIsLoading(false)
        })
  }, [publisherId])

  const handleClick = (feed) => {
    const createFeedForEventFromPerpetual = firebase.functions.httpsCallable(
      'createFeedForEventFromPerpetual'
    )
    createFeedForEventFromPerpetual({
      perpetualFeedId: feed.id,
      eventId: event.id,
    })
      .then(async (res) => {
        await getPublisherUpcomingFeeds(publisherId)
          .then((res) => {
            switchCurrentFeedAction(res[0])
            setCurrentFeedsListAction(res, publisherId)
            console.log('res', res)
            setSubmittingModal(false)
            history.push('/publisher')
          })
          .catch((err) => console.log(err))
      })
      .catch((err) => {
        console.log('err', err)
      })
  }

  return isLoading ? (
    <Spin tip="Loading..." />
  ) : perpetualFeeds.length > 0 ? (
    perpetualFeeds.map((feed) => {
      return (
        <Card
          key={feed.id}
          onClick={() => handleClick(feed)}
          style={{ cursor: 'pointer' }}
        >
          <Meta
            avatar={<Avatar src={feed.picture} />}
            title={feed.title}
            description={feed.description}
          />
        </Card>
      )
    })
  ) : (
    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
  )
}

export default connect(null, {
  setCurrentFeedsListAction: setCurrentFeedsList,
  switchCurrentFeedAction: switchCurrentFeed,
})(PerpetualFeed)
