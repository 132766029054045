import { Form, Row, message } from 'antd'
import React, { useContext, useState } from 'react'
import AdSetDetails from './AdSetDetails'
// import AdSetAudience from '../../components/NewAdSetComponents/AdSetAudienceComponent'
import { useSelector } from 'react-redux'
import AdSetFilteredFeeds from '../../components/NewAdSetComponents/AdSetFilteredFeeds'
import AdSetFilters from '../../components/NewAdSetComponents/AdSetFiltersComponent'
import { FirebaseContext } from '../../utils/Firebase'
import { buildAudience, getGender, getMinMaxAge } from '../../utils/helpers/adsManagerHelpers'
import AdsAudienceManager from './AdsAudienceManager'

export default function CreateNewAdSetModal({ selectedAdSet, campaigns, audiences, setModalOpen }){
  const [form] = Form.useForm();
  const firebase = useContext(FirebaseContext)
  const publisherId = useSelector(
    (state) => state.publishing.publishingAs?.id ?? 0
  )
  const [ adSetData, setAdSetData ] = useState(selectedAdSet)
  const state = useSelector(state => state)

  console.log('state', state)

  const handleFormSubmit = async (formData) => {
    if(formData.availableEvents){
      delete formData.availableEvents
    }
    let adSet = {
      ...selectedAdSet,
      campaignId: formData.campaignId,
      name: formData.adSetName,
      audience: buildAudience(selectedAdSet, formData),
      feeds:formData.feeds ? formData.feeds : [],
      events:formData.events ? formData.events : [],
      leagues:formData.leagues ? formData.leagues : [],
      teams:formData.teams ? formData.teams : []
    }

    let db = firebase.getCollection('publisher').doc(publisherId).collection('ad-sets')
    if(adSet.id){
      db
      .doc(adSet.id)
      .update(adSet)
      .then(() => {
        message.success('Ad Set Updated Successfully!')
        setModalOpen(false)
      })
      .catch(err => {
        console.log('error:', err)
        message.warning('Error Updating Ad Set, please try again.')
      })
    }else{
      db
      .add(adSet)
      .then(() => {
        message.success('Ad Set Created Successfully!')
        setModalOpen(false)
      })
      .catch(err => {
        console.log('error:', err)
        message.warning('Error Creating New Campaign, please try again.')
      })
    }
  }

  const handleSubmitFailed = () => {

  }

  return(
    <Row gutter={[16,16]}>
      <Form
        form={form}
        id='newAdSetForm'
        name="newAdSetForm" 
        onFinish={handleFormSubmit}
        onFinishFailed={handleSubmitFailed}
        style={{width:'100%'}}
        layout='vertical'
        initialValues={{
          adSetName: selectedAdSet.name,
          campaignId: selectedAdSet.campaignId,
          minAge:getMinMaxAge('min',selectedAdSet.audience),
          maxAge:getMinMaxAge('max',selectedAdSet.audience),
          gender:getGender(selectedAdSet.audience),
          availableEvents:selectedAdSet.manualAd,
          teams:selectedAdSet.teams,
          events:selectedAdSet.events,
          leagues:selectedAdSet.leagues,
        }}
      >
        <AdSetDetails 
          campaigns={campaigns} 
        />

        <AdsAudienceManager 
          form={form}
          audiences={audiences}
        />

        <AdSetFilters 
          form={form}
          adSetData={adSetData}
          setAdSetData={setAdSetData}
        />

        {adSetData.manualAd === 'manual'
          ? 
            null
          :
            <AdSetFilteredFeeds
              form={form}
              adSetData={adSetData}
            />
        }
      </Form>
    </Row>
  )
}
