import React, { useState, useEffect, useContext } from 'react'
import { Typography, Row, Col, Breadcrumb, Tabs } from 'antd';
import Audiences from './Audiences'
import { HomeOutlined } from '@ant-design/icons';
import Ads from './Ads';
import { useSelector } from 'react-redux';
import AdCampaigns from './AdCampaigns';
import AdSets from './AdSets';
import { FirebaseContext } from '../../utils/Firebase';

const { Title } = Typography
const { TabPane } = Tabs

function AdsManager(){
  const firebase = useContext(FirebaseContext)
  const selectedPublisher = useSelector(state => state.publishing.publishingAs)
  const [ activeKey, setActiveKey ] = useState('campaigns')
  const [ campaigns, setCampaigns ] = useState([])
  const [ adSets, setAdSets ] = useState([])
  const [ ads, setAds ] = useState([])
  const [ selectedCampaigns, setSelectedCampaigns ] = useState([])
  const [ selectedAdSets, setSelectedAdSets ] = useState([])
  const [ selectedAds, setSelectedAds ] = useState([])
  const [ adsWithAdSets, setAdsWithAdSets ] = useState([])
  const [ audiences, setAudiences ] = useState([])

  console.log('selectedPublisher',selectedPublisher)

  // FETCH AD CAMPAIGNS
  useEffect(()=>{
    if(!selectedPublisher)return
    const unsubscribe = firebase.getCollection('publisher').doc(selectedPublisher.id).collection('ad-campaigns')
      .onSnapshot((snap)=>{
        let newCampaigns = snap.docs.map(docRef => ({
            ...docRef.data(),
            id: docRef.id
        }))
        setCampaigns(newCampaigns)
      })
    return () => unsubscribe()
  },[selectedPublisher])

  // FETCH AD SETS
  useEffect(()=>{
    if(!selectedPublisher)return
    const unsubscribe = firebase.getCollection('publisher').doc(selectedPublisher.id).collection('ad-sets')
      .onSnapshot((snap)=>{
        let newAdSets = snap.docs.map(docRef => ({
            ...docRef.data(),
            id: docRef.id
        }))
        setAdSets(newAdSets)
      })
    return () => unsubscribe()
  },[selectedPublisher])
  
  // FETCH ADS
  useEffect(()=>{
    if(!selectedPublisher)return
    const unsubscribe = firebase.getCollection('publisher').doc(selectedPublisher.id).collection('ads')
      .onSnapshot(async snap =>{
        let newAdsArr = await Promise.all(snap.docs.map(async docRef => {
          let ad = {
            ...docRef.data(),
            id: docRef.id
          }
          // console.log('ad', ad)

          let adSet = await firebase.getCollection('publisher').doc(selectedPublisher.id).collection('ad-sets').doc(ad.adSetId)
            .get()
            .then( adSetDocRef => adSetDocRef.data())
            ad = {...ad, adSet: adSet}

          if(adSet && adSet.feeds && adSet.feeds.length > 0){
            ad = {...ad, feeds: adSet.feeds}
          }

          if(adSet && adSet.audienceId){
            let audience = await firebase.getCollection('publisher').doc(selectedPublisher.id).collection('audiences').doc(adSet.audienceId)
              .get()
              .then( audienceDocRef => audienceDocRef.data())
              ad = {...ad, audience: audience}
            }


          return ad
        }))
        setAds(newAdsArr)
      })
    return () => unsubscribe()
  },[selectedPublisher])

  // FETCH AUDIENCES
  useEffect(()=>{
    if(!selectedPublisher)return
    const unsubscribe = firebase.getCollection('publisher').doc(selectedPublisher.id).collection('audiences')
      .onSnapshot(async snap => {
        let newAudiencesArr = await Promise.all(snap.docs.map(async docRef => {
          let audience = {
            ...docRef.data(),
            id: docRef.id,
            gender: 'all',
            minAge: '',
            maxAge: ''
          }
          await Promise.all(audience.filters.map(async filter => {
            await firebase.getCollection('promptFilters').doc(filter.id)
              .get()
              .then(filterDocRef => {
                let filterDoc = filterDocRef.data()
                if(filterDoc.entity === 'sex'){
                  audience = {...audience, gender: filter.value}
                }else if(filterDoc.name === 'Age Between'){
                  audience = {...audience, minAge: filter.value, maxAge: filter.endValue}
                } else if(filterDoc.name === 'Age Greater Than'){
                  audience = {...audience, minAge: filter.value, maxAge: '65+'}
                }
                return audience
              })
          }))
          return audience
        }))

        newAudiencesArr.sort((a,b)=> {
          if(a.name < b.name) return -1
          if(a.name > b.name) return 1
          return 0
        })
        setAudiences(newAudiencesArr)
    })
    return () => unsubscribe()
  },[selectedPublisher])

  useEffect(()=>{
    let newAdsArr = ads.map( ad => {
      let filteredAdSet = adSets.filter(adSet => adSet.id === ad.adSetId)
      ad = {...ad, adSet: filteredAdSet[0]}
      return ad
    })
    setAdsWithAdSets(newAdsArr)
  },[adSets, ads])

  return(
    <Row style={{padding:20}}>
      <Col span={24}>
        <Breadcrumb style={{marginBottom:31}}>
          <Breadcrumb.Item href="">
            <HomeOutlined />
          </Breadcrumb.Item>
          <Breadcrumb.Item href="">
            <span>Ads Manager</span>
          </Breadcrumb.Item>
        </Breadcrumb>
      </Col>

      <Col span={24}>
        <Row align='middle' style={adManagerHeaderStyleProps}>
          <Col className="gutter-row" span={20}>
            <Title style={{margin:0}}>Ads Manager</Title>
          </Col>
        </Row>

        <Row style={adTableStyleProps}>
          <Col style={{marginBottom:'25px'}}>
            <Tabs 
              defaultActiveKey="1"
              activeKey={activeKey}
              onTabClick={setActiveKey}
              size='large'
            >
              <TabPane 
                key="campaigns"
                tab={selectedCampaigns && selectedCampaigns.length > 0 ? `Campaigns (${selectedCampaigns.length})` : `Campaigns`} 
              >
                <AdCampaigns 
                  setSelectedCampaigns={setSelectedCampaigns} 
                  selectedCampaigns={selectedCampaigns}
                  campaigns={campaigns}
                />
              </TabPane>
              <TabPane 
                key="adSets"
                tab={selectedAdSets && selectedAdSets.length > 0 ? `Ad Sets (${selectedAdSets.length})` : `Ad Sets`} 
              >
                <AdSets
                  adSets={adSets}
                  selectedCampaigns={selectedCampaigns}
                  setSelectedAdSets={setSelectedAdSets}
                  campaigns={campaigns}
                  audiences={audiences}
                />
              </TabPane>
              <TabPane 
                key='ads' 
                tab={selectedAds && selectedAds.length > 0 ? `Ads (${selectedAds.length})` : `Ads`}
              >
                <Ads 
                  ads={adsWithAdSets}
                  selectedAdSets={selectedAdSets}
                  selectedAds={selectedAds}
                  setSelectedAds={setSelectedAds}
                  adSets={adSets}
                />
              </TabPane>
              <TabPane 
                key="audiences"
                tab="Audiences" 
              >
                <Audiences 
                  audiences={audiences}
                />
              </TabPane>
            </Tabs>
          </Col>
        </Row>
      </Col>

    </Row>
  )
}

export default AdsManager

const adManagerHeaderStyleProps = {
  background: 'white',
  borderRadius: 10,
  padding: 25,
  marginBottom: 10,
}

const adTableStyleProps = {
  background: 'white',
  padding: 25,
  borderRadius: 10,
  display: 'flex',
  flexDirection: 'column',
}