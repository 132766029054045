import React from 'react'
import { Radio, Form, Input } from 'antd'
import { FieldTimeOutlined } from '@ant-design/icons'

export default function BasketballScheduler({ setSchedule, schedule }){

  const handleChange = (e) => {
    let newSchedule = {...schedule, [e.target.name]:e.target.value}
    setSchedule(newSchedule)
  }

  return(
    <Form layout='vertical' requiredMark='false'>
      <Form.Item label="Quarter">
        <Radio.Group value={schedule && schedule.sendInPeriod} size='medium' onChange={handleChange} name='sendInPeriod'>
          <Radio.Button style={{margin:5}} value={1}>1st Quarter</Radio.Button>
          <Radio.Button style={{margin:5}} value={2}>2nd Quarter</Radio.Button>
          <Radio.Button style={{margin:5}} value={3}>3rd Quarter</Radio.Button>
          <Radio.Button style={{margin:5}} value={4}>4th Quarter</Radio.Button>
          <Radio.Button style={{margin:5}} value={5}>Overtime</Radio.Button>
        </Radio.Group>
      </Form.Item>
      <Form.Item 
        label='Minutes'
        name='sendAtMinutes'
        onChange={handleChange}
        rules={[
          {
            required: true,
            message: 'Please input a time',
          },
        ]}
      >
        <Input type='number' placeholder='Minutes' name='sendAtMinutes' prefix={<FieldTimeOutlined />} />
      </Form.Item>
      <Form.Item 
        label="Seconds"
        name='sendAtSeconds'
        onChange={handleChange}
        rules={[
          {
            required: true,
            message: 'Please input a time',
          },
        ]}
      >
        <Input type='number' placeholder='Seconds' name='sendAtSeconds' prefix={<FieldTimeOutlined />} />
      </Form.Item>
    </Form>
  )
}