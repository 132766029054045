import React, { useState, useEffect, useContext } from 'react'
import { Col, Row, Typography, Empty, Skeleton } from 'antd'

// COMPONENTS
import { useSelector } from 'react-redux'
import DraftCard from './DraftCard';

// FIREBASE
import { FirebaseContext } from '../../../utils/Firebase'

export default function Drafts(){
  const firebase = useContext(FirebaseContext)
  const [ draftedPrompts, setDraftedPrompts ] = useState()
  const [ draftedIsLoading, setDraftedIsLoading ] = useState(true)
  const feed = useSelector(state => state.publishing.activeCurrentFeed)

  console.log('feed', feed)

  // GET DRAFTS
  useEffect(() => {
    const unsubscribe = firebase.getCollection('prompt_drafts')
      .where('feedId', '==', feed.id)
      .orderBy('timestamp', 'desc')
      .onSnapshot(snapshot => {
        let draftArr = []
        snapshot.forEach(snap => {
          if(snap.exists){
            let doc = snap.data()
            draftArr.push(doc)
          }
        })
        if(draftArr.length > 0){
          setDraftedPrompts(draftArr)
        } else {
          setDraftedPrompts(null)
        }
        setDraftedIsLoading(false)
      })
  return () => unsubscribe()
  },[feed])
  
  return(
    <Row gutter={[16,16]} style={{width:'100%'}}>
      {draftedPrompts 
        ? 
          draftedPrompts.map((draft)=>(
            <Skeleton key={draft.id} loading={draftedIsLoading} active avatar>
              <Col sm={24} md={12}>
                <DraftCard prompt={draft}/>
              </Col>
            </Skeleton>
          ))
        :
          <Col span={24}>
            <Empty description={<Typography.Text type='secondary'>No Saved Prompts</Typography.Text>} style={{margin:40}}/>
          </Col>
      }
    </Row>
  )
}