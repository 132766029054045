import React from 'react'
import { Row } from 'antd'

const UnauthorizedLayout = ({children}) => {

  return (
    <Row className='center-container'>
      {children}
    </Row>
  )
}

export default UnauthorizedLayout