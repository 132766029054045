import React from 'react'

import { Col, Button, Table } from 'antd'
import { DeleteOutlined } from '@ant-design/icons';

export default function AttachedAdDetailsSmall({ prompt, setPrompt }){

  const columns = [
    {
      title: 'Thumbnail',
      dataIndex: 'sponsorLogo',
      key: 'sponsorLogo',
      // align: 'right',
      render: (text,record) => (
        <Col>
          <img style={{height: 50, borderRadius: 5}} src={record.sponsorLogo}/>
        </Col>
      )
    },
    {
      title: 'CTA',
      dataIndex: 'callOutText',
      key: 'callOutText',
      // align: 'right'
    },
    {
      key: 'deleteButton',
      width: 50,
      render:(text, record) => <Button type='text' danger icon={<DeleteOutlined/>} onClick={() => handleDelete(record)}/>
      // align: 'right'
    },
  ]

  const handleDelete = (adRecord) => {
    // console.log('prompt ads being deleted', prompt)
    let newAdsArr = [...prompt.ads]
    let i = newAdsArr.indexOf(adRecord)
    newAdsArr.splice(i,1)
    if(newAdsArr.length === 0){
      delete prompt.ads
      setPrompt({...prompt})
      // console.log('deleted ads field', prompt)
    }else{
      setPrompt({...prompt, ads:newAdsArr})
      // console.log('deleted ad', prompt)
    }
  }

  return(
    <Table 
      columns={columns} 
      dataSource={prompt.ads} 
      rowKey="callOutText"
      pagination={false}
    />
  )

}