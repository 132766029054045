import React, { useState, useEffect } from 'react'
import { Input, Form, Row, Col } from 'antd'

import RankResponsesGenerator from './RankResponseGenerator';
import IconUploader from '../../../../components/IconUploader/IconUploader';

function Rank ({ prompt, setPrompt }){
  const [ img, setImg ] = useState()

  useEffect(()=>{
    if(img){
      setPrompt({...prompt, photoUrl: img})
    }else if(!img && prompt.photoUrl){
      let newPrompt = {...prompt}
      delete newPrompt.photoUrl
      setPrompt(newPrompt)
    }
  },[img])

  const handleStatementChange = e => {
    setPrompt({...prompt, text:e.target.value})
  };

  const handleResponseChange = (e,index) => {
    let newArr = [...prompt.responses]
    newArr[index] = e.target.value
    setPrompt({...prompt, responses: newArr})
  };

  const removeOption = (index) => {
    let newArr = [...prompt.responses]
    newArr.splice(index, 1)
    setPrompt({...prompt, responses:newArr})
  }

  const addOption = () => {
    let newArr = [...prompt.responses]
    newArr.push('')
    setPrompt({...prompt, responses: newArr})
  }

  return (
    <Row>
      <Col span={24}>
        <Form.Item
          label="Feed Name"
          name="feedName"
          rules={[
            {
              required: true,
              message: 'Please add a Statement',
            },
          ]}
          onChange={handleStatementChange}
        >
          <Input 
            bordered={false}
            style={{borderBottom:'1px solid rgba(0,0,0,0.1'}} 
            placeholder='Enter your statement'
            suffix={<IconUploader setFile={setImg}/>}
          />
        </Form.Item>
      </Col>
      <Col span={24}>
        {prompt && prompt.responses
          ?
            prompt.responses.map((response, index) => {
              return <RankResponsesGenerator key={index} index={index} response={response} handleResponseChange={handleResponseChange} removeOption={removeOption} responses={prompt.responses}/>
            })
          :
            null
        }
        {prompt && prompt.responses && prompt.responses.length > 4 
          ? 
            null
          : 
            <p style={{cursor:'pointer'}} onClick={() => addOption()}>+ Add another Response</p>
        }
      </Col>
    </Row>
  )
}

export default Rank