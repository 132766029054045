import { Avatar, Col, Form, Input, Select, Typography } from 'antd'
import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { FirebaseContext } from '../../utils/Firebase'

const { Option } = Select 

export default function AdDetails({ adSets, form, setTriggers }){
  const firebase = useContext(FirebaseContext)
  const publisherId = useSelector(
    (state) => state.publishing.publishingAs?.id ?? 0
  )

  const handleAdSetChange = (adSetId) => {
    let i = adSets.findIndex(adSetObj => adSetObj.id === adSetId)
    if(i > -1){
      let adSetObj = adSets[i]
      getPublisherRulesets(publisherId, adSetObj)
    }
  }

  const getPublisherRulesets = async (publisherId, adSetObj) => {
    if(adSetObj.leagues){
      Promise.all(await adSetObj.leagues.map(async league => {
        const query = firebase.getCollection('publisher_rulesets')
        .where('publisherId', '==', publisherId)
        .where('league', '==', league)
        
        await query.onSnapshot(async snap => {
          let publisherAllowedRules = await Promise.all(snap.docs.map(async docRef => {
            let doc = docRef.data()
    
            let enginePrompts = await Promise.all(doc.assigned_rules_sectionIds.map(async sectionId => {
    
              return await firebase.getCollection('rules_documents').doc(doc.rules_documentId).collection('sections').doc(sectionId)
                .get()
                .then( sectionDocRef => {
                  let enginePrompt = sectionDocRef.data()
                  enginePrompt={...enginePrompt, enabled:false}
                  return enginePrompt
                })
              
    
            }))
    
            // console.log('enginePrompts', enginePrompts)
            const avatar = <Avatar shape='square' size={50} style={{borderRadius:4}}>{doc.name.split('')[0]}</Avatar>
            doc = {...doc, id:docRef.id, title:doc.name, description: doc.description, avatar:avatar, publisherEnginePrompts: enginePrompts, leagueObj: league}
            return doc
          }))
          if(publisherAllowedRules.length > 0){
            setTriggers(publisherAllowedRules)
            form.setFieldsValue({
              triggers:publisherAllowedRules
            })
          }else{
            setTriggers([])
            form.setFieldsValue({
              triggers:[]
            })
          }
        })
      }))
    }

  }

  return(
    <Col 
      span={24} 
      style={adDetailsStyleProps}
    >
    <Typography.Title level={4} style={{margin:0}}>Ad Details</Typography.Title>
    <Typography.Text type='secondary'>House Keeping</Typography.Text>
      <Form.Item
       label={<Typography.Text strong>Ad Name</Typography.Text>}
       name='adDetails'
       rules={[
        {
          required: true,
          message: 'Please give your ad a name',
        },
      ]}
      >
        <Input placeholder="Ad Name"/>
      </Form.Item>
      <Form.Item
        label={<Typography.Text strong>Ad Set</Typography.Text>}
        name='adSetId'
        rules={[
          {
            required: true,
            message: 'Please select a connected Ad Set',
          },
        ]}
      >
        <Select
          showSearch
          placeholder="Select AdSet"
          optionFilterProp="children"
          onChange={handleAdSetChange}
          style={{width:'100%'}}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {adSets.map(adSet => {
            return <Option value={adSet.id} key={adSet.id}>{adSet.name}</Option>
          })}
        </Select>
      </Form.Item>
    </Col>
  )
}

const adDetailsStyleProps = {
  width:'100%',
  background:'white', 
  borderRadius:8, 
  boxShadow:'0px 1px 2px rgba(0,0,0,0.25)', 
  paddingTop: 15,
  paddingBottom: 20,
  paddingLeft: 20,
  paddingRight: 20,
}
