import React, { useState } from 'react'

import { useHistory, withRouter } from 'react-router-dom'
import { compose } from 'recompose'

import {
  AppleFilled,
  EyeInvisibleOutlined,
  EyeTwoTone,
  FacebookFilled,
  GoogleCircleFilled,
  LockOutlined,
  UserOutlined
} from '@ant-design/icons'
import {
  Button,
  Col,
  Form,
  Image,
  Input,
  Row,
  Typography,
  message
} from 'antd'
import { connect, useSelector } from 'react-redux'
import OpineLogo from '../../assets/images/OpineLogo.svg'
import {
  emailAuthentication,
  federatedAuthentication,
} from '../../redux/auth/actions'

function SignInPage({ loginUserAction, federatedLoginAction }) {
  const error = useSelector((state) => state.authUser.error)
  const history = useHistory()

  const handleSocialClick = (service) => {
    federatedLoginAction(service, history)
  }

  return (
    <Row
      align="middle"
      justify="center"
      style={{
        height: '100%',
      }}
    >
      <Col
        style={{
          background: 'white',
          padding: '4em',
          width: 500,
        }}
      >
        {error ? message.error(error) : null}
        <Image
          width={100}
          src={OpineLogo}
          preview={false}
          style={{ marginBottom: 40 }}
        />
        <Typography.Title style={{ lineHeight: 0 }}>Welcome!</Typography.Title>
        <Typography.Title level={5} style={{ marginBottom: 25 }}>
          Please sign into you publisher account.
        </Typography.Title>
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <Button
              block
              type="text"
              onClick={() => handleSocialClick('GOOGLE_AUTHENTICATION')}
              icon={<GoogleCircleFilled />}
            >
              Sign in with Google
            </Button>
          </Col>
          <Col span={24}>
            <Button
              block
              type="text"
              onClick={() => handleSocialClick('FACEBOOK_AUTHENTICATION')}
              icon={<FacebookFilled />}
            >
              Sign in with Facebook
            </Button>
          </Col>
          {/* <Col span={24}>
              <Button block type='text' onClick={()=>handleSocialClick('TWITTER_AUTHENTICATION')} icon={<TwitterCircleFilled/>}>Sign in with Facebook</Button>
            </Col> */}
          <Col span={24}>
            <Button
              block
              type="text"
              onClick={() => handleSocialClick('APPLE_AUTHENTICATION')}
              icon={<AppleFilled />}
            >
              Sign in with Apple
            </Button>
          </Col>
          <Col span={24}>
            <SignInForm loginUserAction={loginUserAction} />
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

function SignInFormBase({ loginUserAction }) {
  const history = useHistory()
  const [form] = Form.useForm()
  const error = useSelector((state) => state.authUser.error)
  const [passwordValidation, setPasswordValidation] = useState({
    status: null,
    message: null,
  })
  const [emailValidation, setEmailValidation] = useState({
    status: null,
    message: null,
  })

  const onFinish = async (values) => {
    try {
      await form.validateFields(['email'])
      // console.log('Success:', values);
      // setState({...state, email:values.email})
      setEmailValidation({
        status: 'success',
      })
    } catch (errorInfo) {
      // console.log('Failed:', errorInfo);
      setEmailValidation({
        status: 'error',
        message: errorInfo.errorFields[0].errors[0],
      })
    }

    try {
      await form.validateFields(['password'])
      setPasswordValidation({
        status: 'success',
      })
    } catch (errorInfo) {
      // console.log('Failed:', errorInfo);
      setPasswordValidation({
        status: 'error',
        message: errorInfo.errorFields[0].errors[0],
      })
    }

    loginUserAction(values, history)
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
    if (errorInfo.errorFields[0].name[0] === 'email') {
      setEmailValidation({
        status: 'error',
        message: errorInfo.errorFields[0].errors[0],
      })
    }
    if (errorInfo.errorFields[0].name[0] === 'password') {
      setPasswordValidation({
        status: 'error',
        message: errorInfo.errorFields[0].errors[0],
      })
    }
    if (
      errorInfo.errorFields[1] &&
      errorInfo.errorFields[1].name[0] === 'password'
    ) {
      setPasswordValidation({
        status: 'error',
        message: errorInfo.errorFields[1].errors[0],
      })
    }
  }

  const handleForgotPasswordClick = () => {
    history.push('/auth/forgot-password')
  }

  return (
    <Row style={{ marginTop: 20 }}>
      <Col span={24}>
        <Typography.Title level={5}>Sign in with Email</Typography.Title>
      </Col>
      <Col span={24}>
        <Form
          form={form}
          name="email_login"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            name="email"
            rules={[
              {
                type: 'email',
                message: 'Please enter a valid E-mail',
              },
              {
                required: true,
                message: 'E-mail is required',
              },
            ]}
            validateStatus={emailValidation.status}
            help={emailValidation.message}
            hasFeedback
            style={{ marginBottom: 8 }}
          >
            <Input
              autoComplete="off"
              placeholder="Email"
              prefix={<UserOutlined />}
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Please input your Password!' }]}
            validateStatus={passwordValidation.status}
            help={passwordValidation.message}
            hasFeedback
            style={{ marginBottom: 0 }}
          >
            <Input.Password
              // type='hidden'
              // value={state.password}
              autoComplete="new-password"
              prefix={<LockOutlined />}
              // type="password"
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
              placeholder="Password"
              // onChange={handlePasswordChange}
              // initialvalues='password'
            />
          </Form.Item>
          <Form.Item>
            {/* <Form.Item name="remember" valuePropName="checked" noStyle>
              <Checkbox>Remember me</Checkbox>
            </Form.Item> */}

            <Button block type="link" onClick={handleForgotPasswordClick}>
              Forgot password?
            </Button>
          </Form.Item>

          <Form.Item>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Button type="primary" htmlType="submit" block>
                  Sign in
                </Button>
              </Col>
              {/* <Col span={24}>
                <Row justify='center'>
                  Don't Have an Account?
                </Row>
                <Link to='/auth/register'>
                  <Button type='link' block>
                    Register Here!
                  </Button>
                </Link>
              </Col> */}
            </Row>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  )
}

const SignInForm = compose(withRouter)(SignInFormBase)

export { SignInForm }

export default connect(null, {
  loginUserAction: emailAuthentication,
  federatedLoginAction: federatedAuthentication,
})(SignInPage)
