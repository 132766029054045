import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { authorizedRoot, currentUser, newUserRoot } from '../../constants/defaultValues';
import { appleProvider, auth, facebookProvider, getOpineUser, googleProvider } from '../../helpers/Firebase';
import {
  setCurrentFeedToLocalStorage,
  setCurrentFeedsListToLocalStorage,
  setCurrentUserToLocalStorage,
  setPastFeedToLocalStorage,
  setPastFeedsListToLocalStorage,
  setToLocalStorage
} from '../../helpers/Utils';

import {
  APPLE_AUTHENTICATION,
  AUTHENTICATION_SUCCESS,
  EMAIL_AUTHENTICATION,
  FACEBOOK_AUTHENTICATION,
  FEDERATED_AUTHENTICATION,
  FORGOT_PASSWORD,
  GOOGLE_AUTHENTICATION,
  LOGOUT_USER,
  REGISTER_USER,
  RESET_PASSWORD,
} from '../constants';

import {
  authenticationSuccess,
  forgotPasswordError,
  forgotPasswordSuccess,
  loginUserError,
  loginUserSuccess,
  registerUserError,
  registerUserSuccess,
  resetPasswordError,
  resetPasswordSuccess,
} from './actions';

import {
  setCurrentFeedsListSuccess,
  setPastFeedsListSuccess,
  switchActiveEventSuccess,
  switchCurrentFeedSuccess,
  switchPastFeedSuccess,
  switchPublishingAsSuccess,
} from '../publishing/actions';

export function* watchFederatedLoginUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(FEDERATED_AUTHENTICATION, loginWithPopup);
}

function* loginWithPopup({ payload }) {
  // eslint-disable-next-line no-use-before-define
  const { service, history } = payload
  try {
    const authUser = yield call(loginWithPopupAsync, service)
    if(authUser){
      yield put(authenticationSuccess(authUser.user,history))
    }
  } catch (error) {
    console.log('loginUser error', error)
    yield put(loginUserError(`${error.message}: ${error.email}`));
  }
}

const loginWithPopupAsync = async (service) => {
  switch(service){
    case GOOGLE_AUTHENTICATION:
      return await auth
        .signInWithPopup(googleProvider)
        .then(result => result).catch(err=>err)
    case FACEBOOK_AUTHENTICATION:
      return await auth
        .signInWithPopup(facebookProvider)
        .then(result => result).catch(err=>err)
    case APPLE_AUTHENTICATION:
      return await auth
        .signInWithPopup(appleProvider)
        .then(result => result).catch(err=>err)
  }
}

export function* watchEmailLoginUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(EMAIL_AUTHENTICATION, loginWithEmailPassword);
}

function* loginWithEmailPassword({ payload }) {
  const { history } = payload;
  const { email, password } = payload.input;
  try {
    const authUser = yield call(loginWithEmailPasswordAsync, email, password);
    if (!authUser.message) {
      yield put(authenticationSuccess(authUser.user,history))
    } else {
      console.log('loginUser failed',authUser)
      yield put(loginUserError(authUser.message));
    }
  } catch (error) {
    yield put(loginUserError(error));
  }
}

const loginWithEmailPasswordAsync = async (email, password) =>
// eslint-disable-next-line no-return-await
  await auth
    .signInWithEmailAndPassword(email, password)
    .then(async (user) => {
      return user
    })
    .catch((error) => error);

export function* watchAuthenticationSuccess() {
  yield takeEvery(AUTHENTICATION_SUCCESS, retrieveOpineUser)
}

function* retrieveOpineUser({ payload }) {
  console.log('payload', payload)
  const { authUser, history } = payload
  try{
    const opineUser = yield call(retrieveOpineUserAsync, authUser);
    /**
     * Google authUser structure
     * displayName
     * email
     * phoneNumber
     * photoURL
     * providerId
     * uid
     */
    if(opineUser){
      setCurrentUserToLocalStorage(opineUser);
      yield put(loginUserSuccess(opineUser));
      history.push(authorizedRoot);
    }else{
      history.push(newUserRoot);
    }
  } catch (err){

  }
}

const retrieveOpineUserAsync = async (providerData) => {
  try {
    let opineUser = await getOpineUser(providerData)
    if (opineUser) {
      return opineUser
    }
  } catch (err) {
    console.log('signInWithEmailAndPassword error:', err)
    return err
  }
}

export function* watchRegisterUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(REGISTER_USER, registerWithEmailPassword);
}

const registerWithEmailPasswordAsync = async (email, password) =>
  // eslint-disable-next-line no-return-await
  await auth
    .createUserWithEmailAndPassword(email, password)
    .then((user) => user)
    .catch((error) => error);

function* registerWithEmailPassword({ payload }) {
  const { email, password } = payload.user;
  const { history } = payload;
  try {
    const registerUser = yield call(
      registerWithEmailPasswordAsync,
      email,
      password
    );
    if (!registerUser.message) {
      const item = { uid: registerUser.user.uid, ...currentUser };
      setCurrentUserToLocalStorage(item);
      yield put(registerUserSuccess(item));
      history.push(authorizedRoot);
    } else {
      yield put(registerUserError(registerUser.message));
    }
  } catch (error) {
    yield put(registerUserError(error));
  }
}

export function* watchForgotPassword() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(FORGOT_PASSWORD, forgotPassword);
}

const forgotPasswordAsync = async (email) => {
  // eslint-disable-next-line no-return-await
  return await auth
    .sendPasswordResetEmail(email)
    .then((user) => user)
    .catch((error) => error);
};

function* forgotPassword({ payload }) {
  const { email } = payload.forgotUserMail;
  try {
    const forgotPasswordStatus = yield call(forgotPasswordAsync, email);
    if (!forgotPasswordStatus) {
      yield put(forgotPasswordSuccess('success'));
    } else {
      yield put(forgotPasswordError(forgotPasswordStatus.message));
    }
  } catch (error) {
    yield put(forgotPasswordError(error));
  }
}

export function* watchResetPassword() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(RESET_PASSWORD, resetPassword);
}

const resetPasswordAsync = async (resetPasswordCode, newPassword) => {
  // eslint-disable-next-line no-return-await
  return await auth
    .confirmPasswordReset(resetPasswordCode, newPassword)
    .then((user) => user)
    .catch((error) => error);
};

function* resetPassword({ payload }) {
  const { newPassword, resetPasswordCode } = payload;
  try {
    const resetPasswordStatus = yield call(
      resetPasswordAsync,
      resetPasswordCode,
      newPassword
    );
    if (!resetPasswordStatus) {
      yield put(resetPasswordSuccess('success'));
    } else {
      yield put(resetPasswordError(resetPasswordStatus.message));
    }
  } catch (error) {
    yield put(resetPasswordError(error));
  }
}

export function* watchLogoutUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(LOGOUT_USER, logout);
}

function* logout({ payload }) {
  // eslint-disable-next-line no-use-before-define
  const { history } = payload;
  try{
    let res = yield call(logoutAsync);
    if(res === 'success'){
      history.push('/auth/login');
      setCurrentUserToLocalStorage();
      setToLocalStorage('opine_publishing_as',)
      setCurrentFeedToLocalStorage()
      setPastFeedToLocalStorage()
      setCurrentFeedsListToLocalStorage()
      setPastFeedsListToLocalStorage()
      yield put(switchPublishingAsSuccess(null));
      yield put(switchCurrentFeedSuccess(null));
      yield put(switchPastFeedSuccess({}));
      yield put(setCurrentFeedsListSuccess([]));
      yield put(setPastFeedsListSuccess([]));
      yield put(switchActiveEventSuccess({}));
    }
  }catch(err){
    console.log('Logout Error->', err)
  }
}

const logoutAsync = async () => {
  return await auth
    .signOut()
    .then(() => 'success')
    .catch((error) => error);
};

export default function* rootSaga() {
  yield all([
    fork(watchEmailLoginUser),
    fork(watchFederatedLoginUser),
    fork(watchLogoutUser),
    fork(watchRegisterUser),
    fork(watchForgotPassword),
    fork(watchResetPassword),
    fork(watchAuthenticationSuccess),
  ]);
}
