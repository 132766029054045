import { Col, Layout, Row, Tabs } from 'antd'
import React, { useState } from 'react'

// COMPONENTS
import EventStatusBar from '../../components/EventStatusBar/EventStatusBar_V2'
import NewPrompts from './PromptCreator/NewPrompts'
// import TargetTime from './PostTimer/TargetTime';
import NoEventsPublisher from '../../components/NoEventsPublisher/NoEventsPublisher'
import Drafts from './Drafts/Drafts'

// REDUX
import { useSelector } from 'react-redux'

// FIREBASE
import CurrentFeedsSidebar from '../../components/FeedSidebar/CurrentFeedsSidebar'
import PostHistory_V2 from './PostHistory/PostHistory_V2'
import ScheduledPrompts from './ScheduledPrompts/ScheduledPrompts'

const { Content, Sider } = Layout
const { TabPane } = Tabs

function Publisher() {
  const activeCurrentFeed = useSelector(
    (state) => state.publishing.activeCurrentFeed
  )
  const [collapsed, setCollapsed] = useState(false)
  const [isFetchingFutureFeeds, setIsFetchingFutureFeeds] = useState(true)

  return (
    <Layout style={{ height: '100%' }}>
      {/* {isFetchingFutureFeeds */}
      {/* ?
          <Content style={clipLoaderContainerStyle}>
            <ClipLoader/>
          </Content>
        : */}
      {activeCurrentFeed === null ? (
        <Content style={{ margin: '0 20px' }}>
          <NoEventsPublisher />
        </Content>
      ) : (
        <Content style={{ overflowY: 'scroll' }}>
          <EventStatusBar pastOrFutureEvent="future" />
          <Row justify="center" style={{ width: '100%', padding: '0 20' }}>
            <Tabs {...TabsProps}>
              <TabPane tab="Publish" key="publish">
                <Col span={24}>
                  <NewPrompts />
                </Col>
              </TabPane>
              <TabPane tab="Saved Prompts" key="draft">
                <Col span={24} style={{ height: '100%', padding: 20 }}>
                  <Drafts />
                </Col>
              </TabPane>
              <TabPane tab="Scheduled" key="schedule">
                <Col span={24} style={{ height: '100%', padding: 20 }}>
                  <ScheduledPrompts />
                </Col>
              </TabPane>
              <TabPane tab="Posted Prompts" key="history">
                <Col span={24} style={{ height: '100%', padding: 20 }}>
                  <PostHistory_V2 />
                </Col>
              </TabPane>
            </Tabs>
          </Row>
        </Content>
      )}
      <Sider
        width={350}
        collapsible
        collapsed={collapsed}
        onCollapse={() => setCollapsed(!collapsed)}
        theme="light"
        collapsedWidth={0}
        reverseArrow
        zeroWidthTriggerStyle={{ top: '50%' }}
      >
        <CurrentFeedsSidebar
          setIsFetchingFutureFeeds={setIsFetchingFutureFeeds}
        />
      </Sider>
    </Layout>
  )
}

export default Publisher

const clipLoaderContainerStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}

const TabsProps = {
  size: 'large',
  centered: true,
  defaultActiveKey: 'publish',
  tabPosition: 'top',
  tabBarStyle: {
    background: 'rgba(255,255,255,0.5)',
    boxShadow: '6px 0 12px rgba(0,0,0,0.05)',
  },
  style: {
    width: '100%',
    zIndex: 0,
  },
}
