import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import UnauthorizedLayout from '../../layouts/UnauthorizedLayout';
import SignIn from './SignIn'
import SignUp from './SignUp'
import ForgotPassword from './ForgotPassword'
import NewUser from './NewUser'
// import ResetPassword from './reset-password'

const AuthView = ({ match }) => {
  return (
    <UnauthorizedLayout>
      <Switch>
        <Redirect exact from={`${match.url}/`} to={`${match.url}/login`} />
        <Route
          path={`${match.url}/login`}
          render={(props) => <SignIn {...props} />}
        />
        {/* <Route
          path={`${match.url}/register`}
          render={(props) => <SignUp {...props} />}
        /> */}
        <Route
          path={`${match.url}/forgot-password`}
          render={(props) => <ForgotPassword {...props} />}
        />
        {/* <Route
          path={`${match.url}/new-user`}
          render={(props) => <NewUser {...props} />}
        /> */}
        {/* <Route
          path={`${match.url}/reset-password`}
          render={(props) => <ResetPassword {...props} />}
        /> */}
        <Redirect to="/error" />
      </Switch>
    </UnauthorizedLayout>
  );
};

export default AuthView;
