import { DeleteTwoTone, ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Modal, Row, Space, Table, Typography } from 'antd';
import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { constructFilters } from '../../filtersUtil';
import CreateNewAudienceModal from '../../modals/AdManagerModals/CreateNewAudienceModal';
import { FirebaseContext } from '../../utils/Firebase';
import EditAudienceModal from './EditAudienceModal';

const { Title } = Typography
const { confirm } = Modal;

export default function Audiences({ audiences }){
  const firebase = useContext(FirebaseContext)
  const publisherId = useSelector(
    (state) => state.publishing.publishingAs?.id ?? 0
  )
  const [ filteredInfo, setFilteredInfo ] = useState(null)
  const [ sortedInfo, setSortedInfo ] = useState(null)
  const [ editAudienceModalVisible, setEditAudienceModalVisible ] = useState()
  const [ createAudienceModalVisible, setCreateAudienceModalVisible ] = useState()
  const [ updatingAudiences, setUpdatingAudiences ] = useState(false)

  // CREATE NEW AUDIENCE STATE
  const [ audienceName, setAudienceName ] = useState()
  const [ genderValue, setGenderValue ] = useState('all')
  const [ ageValue, setAgeValue ] = useState(13)
  const [ ageEndValue, setAgeEndValue ] = useState('And Older')
  const [ audienceId, setAudienceId ] = useState()
  const [ editedAudiences, setEditedAudiences ] = useState()

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      // sorter: (a, b) => {
      //   if(a.name < b.name) return -1
      //   if(a.name > b.name) return 1
      //   return 0
      // },
      // sortOrder: sortedInfo ? sortedInfo.columnKey === 'name' && sortedInfo.order : 'ascend',
      // sortDirections: ['ascend' , 'descend', 'ascend'],
    },
    {
      title: 'Min Age',
      dataIndex: 'minAge',
      key: 'minAge',
      align: 'right',
    },
    {
      title: 'Max Age',
      dataIndex: 'maxAge',
      key: 'maxAge',
      align: 'right',
    },
    {
      title: 'Gender',
      dataIndex: 'gender',
      key: 'gender',
      align: 'right',
      // width:100,
      // filters: [
      //   { text: 'Female', value: 'female' },
      //   { text: 'Male', value: 'male' },
      //   { text: 'All', value: 'all' },
      // ],
      // filteredValue: filteredInfo ? filteredInfo.gender : null,
      // onFilter: (value, record) => record.gender.includes(value),
      // sorter: (a, b) => {
      //   if(a.gender < b.gender) return -1
      //   if(a.gender > b.gender) return 1
      //   return 0
      // },
      sortOrder: sortedInfo ? sortedInfo.columnKey === 'gender' && sortedInfo.order : null,
    },
    {
      title: '',
      key: 'action',
      align: 'right',
      width:155,
      render: (text, record) => (
        <Space size="middle">
          {/* <Button type='text' onClick={()=>handleEditAudience(record)} icon={<EditTwoTone/>}/> */}
          <Button type='text' danger onClick={()=>showConfirm(record)} icon={<DeleteTwoTone twoToneColor='red'/>}/>
        </Space>
      ),
    },
  ];

  const handleChange = (pagination, filters, sorter) => {
    // console.log('Various parameters', pagination, filters, sorter);
    setFilteredInfo(filters)
    setSortedInfo(sorter)
  };


  const handleCheck = (selectedRowKeys, selectedRows) => {
    // setSelectedAudiences(selectedRows)
  }

  const clearFilters = () => {
    setFilteredInfo(null)
  };

  const clearSorters = () => {
    setSortedInfo(null)
  };

  const clearAll = () => {
    setFilteredInfo(null)
    setSortedInfo(null)
  };

  const handleEditAudience = (record) => {
    // console.log('Clicked', record)
    setAudienceId(record.id)
    setAgeValue(record.age)
    setAgeEndValue(record.ageEnd)
    setGenderValue(record.gender)
    setAudienceName(record.name)
    setEditAudienceModalVisible(true)
  }

  const showConfirm = (record) => {
    confirm({
      title: `Are you sure you want to delete ${record.name}?`,
      icon: <ExclamationCircleOutlined />,
      content: 'This cannot be undone',
      okText:'Yes',
      onOk() {
        firebase.deleteAudience(publisherId, record.id)
      },
      onCancel() {
        // console.log('Cancel');
      },
    });
  }

  const submitEditAudienceModal = async () => {
    setUpdatingAudiences(true)
    let filters = constructFilters(genderValue, ageValue, ageEndValue)
    let audience = {
      name: audienceName,
      filters
    }
    await firebase.updatePublisherAudience(publisherId, audienceId, audience)
    setAudienceName(null)
    setGenderValue('all')
    setAgeValue('Min Age')
    setAgeEndValue('Max Age')
    setEditAudienceModalVisible(false)
    setUpdatingAudiences(false)
  }


  const submitCreateAudienceModal = async () => {
    let filters = constructFilters(genderValue, ageValue, ageEndValue)
    let audience = {
      name: audienceName,
      filters
    }
    firebase.addPublisherAudience(publisherId, audience)
    setAudienceName(null)
    setGenderValue('all')
    setAgeValue('Min Age')
    setAgeEndValue('Max Age')
    setCreateAudienceModalVisible(false)
  }

  
  const handleModalCancel = () => {
    setAudienceName(null)
    setGenderValue('all')
    setAgeValue('Min Age')
    setAgeEndValue('Max Age')
    setCreateAudienceModalVisible(false)
  }

  return(
    <Row>
      <Col span={24}>
        <Row justify="space-between" align="middle">
          <Button type='primary' icon={<PlusOutlined/>} onClick={()=>setCreateAudienceModalVisible(true)}>Create New Audience</Button>
        </Row>
      </Col>
      <Col span={24} style={{marginTop: 20}}>
        {/* <Space style={{ marginBottom: 16 }}>
          <Button onClick={clearSorters}>Clear Sorters</Button>
          <Button onClick={clearFilters}>Clear filters</Button>
          <Button onClick={clearAll}>Clear filters and sorters</Button>
        </Space> */}
        <Table 
          rowSelection={{
            type:'checkbox',
            onChange: (selectedRowKeys, selectedRows) => handleCheck(selectedRowKeys, selectedRows),
          }}
          columns={columns} 
          dataSource={audiences} 
          onChange={handleChange} 
          rowKey='id'
        />
      </Col>
      <Modal
        title="Create New Audience"
        visible={createAudienceModalVisible}
        onOk={submitCreateAudienceModal}
        onCancel={handleModalCancel}
        width={600}
        destroyOnClose
      >
        <CreateNewAudienceModal 
          audienceName={audienceName}
          genderValue={genderValue}
          ageValue={ageValue}
          ageEndValue={ageEndValue}
          setAudienceName={setAudienceName}
          setGenderValue={setGenderValue} 
          setAgeValue={setAgeValue} 
          setAgeEndValue={setAgeEndValue}
        />
      </Modal>
      <Modal
          title="Edit Audience"
          visible={editAudienceModalVisible}
          onOk={submitEditAudienceModal}
          onCancel={()=>setEditAudienceModalVisible(false)}
          width={600}
          destroyOnClose
        >
          <EditAudienceModal 
            audienceName={audienceName}
            genderValue={genderValue}
            ageValue={ageValue}
            ageEndValue={ageEndValue}
            setAudienceName={setAudienceName}
            setGenderValue={setGenderValue} 
            setAgeValue={setAgeValue} 
            setAgeEndValue={setAgeEndValue}
          />
        </Modal>
      </Row>
  )
}
