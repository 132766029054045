import React from 'react'

// COMPONENTS
import {
  DashboardOutlined,
  AppstoreOutlined,
  AppstoreAddOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  PieChartOutlined,
  DesktopOutlined,
  ContainerOutlined,
  MailOutlined,
  FundOutlined,
  CalendarOutlined,
  MessageOutlined,
  SendOutlined,
  ScheduleOutlined,
  CloudUploadOutlined,
  TagsOutlined,
  ShoppingOutlined,
  CommentOutlined,
  HeatMapOutlined,
  CarryOutOutlined,
  NotificationOutlined,
	GroupOutlined,
	ExperimentOutlined
} from '@ant-design/icons';

export const pages = [
	// {
	// 	url: '/dashboard',
  //   title: 'Dashboard',
  //   icon: <DashboardOutlined style={{fontSize:'20px'}}/>,
	// 	disabled: false,
	// 	paid: false
	// },	
	{
		url: '/browse-events',
    title: 'Browse Events',
    icon: <CarryOutOutlined style={{fontSize:'20px'}}/>,
		disabled: false,
		paid: false
	},
	{
		url: '/publisher',
    title: 'Publisher',
    icon: <NotificationOutlined style={{fontSize:'20px'}}/>,
		disabled: false,
		paid: false
	},
	{
		url: '/past-events',
    title: 'Past Events',
    icon: <FundOutlined style={{fontSize:'20px'}}/>,
		disabled: false,
		paid: false
	},
	// {
	// 	url: '/heatmap',
  //   title: 'Engagement Map',
  //   icon: <HeatMapOutlined style={{fontSize:'20px'}}/>,
	// 	disabled: false,
	// 	paid: false
	// },
	// {
	// 	url: '/feeds',
  //   title: 'Feeds',
  //   icon: <GroupOutlined style={{fontSize:'20px'}}/>,
	// 	disabled: false,
	// 	paid: false
	// },
	// {
	// 	url: '/sponsors',
  //   title: 'Sponsors',
  //   icon: <ShoppingOutlined style={{fontSize:'20px'}}/>,
	// 	disabled: false,
	// 	paid: true
	// },
	// {
	// 	url: '/rules-engine',
  //   title: 'Rules Engine Admin',
  //   icon: <ExperimentOutlined style={{fontSize:'20px'}}/>,
	// 	disabled: false,
	// 	paid: true
	// },
	{
		url: '/ads-manager',
    title: 'Ads Manager',
    icon: <TagsOutlined style={{fontSize:'20px'}}/>,
		disabled: false,
		paid: true
	}
]


	// {
	// 	url: 'analytics',
	// 	title: 'Coming Soon!',
	// 	disabled: true,
	// },
	// {
	// 	url: 'data-marketplace',
	// 	title: 'Coming Soon!',
	// 	disabled: true,
	// },
	// {
	// 	url: 'community',
	// 	title: 'Community',
	// 	disabled: true,
	// },
	// {
	// 	url: 'gameification',
	// 	title: 'Gameification',
	// 	disabled: true,
	// },
	// {
	// 	url: 'calendar',
	// 	title: 'Calendar',
	// 	disabled: true,
	// },