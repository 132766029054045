import React, { useState, useEffect, useContext } from 'react'
import { Col, Row, Typography, Empty, Skeleton } from 'antd'

// COMPONENTS
import { useSelector } from 'react-redux'
import ScheduledPromptCard from './ScheduledPromptCard';

// FIREBASE
import { FirebaseContext } from '../../../utils/Firebase'

export default function ScheduledPrompts(){
  const firebase = useContext(FirebaseContext)
  const feed = useSelector(state => state.publishing.activeCurrentFeed)

  const [ scheduledPrompts, setScheduledPrompts ] = useState()
  const [ scheduledIsLoading, setScheduledIsLoading ] = useState(true)

  useEffect(() => {
    const unsubscribe = firebase.getCollection('scheduled_prompts')
      .where('feedId', '==', feed.id)
      .orderBy('timestamp', 'asc')
      .onSnapshot(snapshot => {
        let scheduledPromptArr = []
        snapshot.forEach(snap => {
          if(snap.exists){
            let doc = snap.data()
            scheduledPromptArr.push(doc)
          }
        })
        if(scheduledPromptArr.length > 0){
          setScheduledPrompts(scheduledPromptArr)
        } else {
          setScheduledPrompts(null)
        }
        setScheduledIsLoading(false)
      })
  return () => unsubscribe()
  },[feed])
  
  return(
    <Row gutter={[16,16]} style={{width:'100%'}}>
      {scheduledPrompts
        ? 
          scheduledPrompts.map((scheduledPrompt)=>(
            <Skeleton key={scheduledPrompt.id} loading={scheduledIsLoading} active avatar>
              <Col sm={24} md={12}>
                <ScheduledPromptCard prompt={scheduledPrompt} key={scheduledPrompt.id}/>
              </Col>
            </Skeleton>
          ))
        :
        <Col span={24}>
          <Empty description={<Typography.Text type='secondary'>No Scheduled Prompts</Typography.Text>} style={{margin:40}}/>
        </Col>
      }
    </Row>
  )
}