import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import PastEvents from '../../Pages/EventAnalysis/EventAnalysis';
import EventAnalysisSinglePrompt from '../../Pages/EventAnalysisSinglePrompt/EventAnalysisSinglePrompt';
import AuthorizedLayout from '../../layouts/AuthorizedLayout';


const PastEventsView = ({ match }) => {
  return (
    <AuthorizedLayout>
      <Switch>
        <Route
          exact
          path={`${match.url}`}
          render={(props) => <PastEvents {...props} />}
        />
        <Route
          path={`${match.url}/:promptId'`}
          render={(props) => <EventAnalysisSinglePrompt {...props} />}
        />
        <Redirect to="/error" />
      </Switch>
    </AuthorizedLayout>
  );
};

export default PastEventsView;
