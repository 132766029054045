import React from 'react'
import { Form, TimePicker } from 'antd'

export default function PoliticsScheduler({ schedule, setSchedule }){
  
  const handleChange = (e) => {
    let newSchedule = {...schedule, [e.target.name]:e.target.value}
    setSchedule(newSchedule)
  }

  return(
    <Form layout='vertical' requiredMark='false'>
      <Form.Item 
        label='Time'
        name='Time'
        onChange={handleChange}
        rules={[
          {
            required: true,
            message: 'Please input a time',
          },
        ]}
      >
        <TimePicker use12Hours bordered={false} value={schedule && schedule.sendAtSeconds} onChange={handleChange} />
      </Form.Item>
    </Form>
  )
}