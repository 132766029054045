import React from 'react'
import { Link } from 'react-router-dom'

import { Empty, Typography, Button, Row, Col } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

export default function NoEventsPublisher() {
  return(
    <Row align='middle' justify='center' gutter={[16,16]} style={{flexDirection:'column',  padding:24, marginTop:50}}>
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={false}/>
      <Typography.Title level={3} style={{ lineHeight:0 }}>No Upcoming or Live Events Selected</Typography.Title>
      <Typography.Text>To publish, please select a feed to the right, or add a new feed using the button below.</Typography.Text>
      <Link to={'/browse-events'}>
        <Button type='primary' icon={<PlusOutlined />}>
          Add a new Feed
        </Button>
      </Link>
    </Row>
  )
}