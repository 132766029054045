import React from 'react';
import Publisher from '../../Pages/Publisher/Publisher';
import AuthorizedLayout from '../../layouts/AuthorizedLayout';


const PublisherView = () => {
  return (
    <AuthorizedLayout>
      <Publisher />
    </AuthorizedLayout>
  );
};

export default PublisherView;
