import React, { useEffect, useState, useContext } from 'react'
import { Col, Form, Row, Radio, Select, Button, Typography, Table, Image } from 'antd';

const { Option } = Select;
let minAgeArr = ['Min Age']
let maxAgeArr = ['Max Age']

for(let i=13; i < 66; i++){
  minAgeArr.push(i)
  maxAgeArr.push(i)
}

minAgeArr.push('65+')
maxAgeArr.push('65+')

// const ageBetweenInitialState = {
// id: 'GyUK3OHi7regN2sWK7ve',
// value: 'Min Age',
// endValue: 'Max Age'
// }

export default function NewAdModal({ setFormValid, adObj, setAdObj, modalData }){
  const [form] = Form.useForm();
  const [ filters, setFilters ] = useState([]) 
  const [ ageBetweenValues, setAgeBetweenValues ] = useState({
    min:'Min Age',
    max:'Max Age'
  })
  const [ ageValidation, setAgeValidation ] = useState({
    status: null,
    message: null
  })

  useEffect(()=>{
    let newFilters = [...filters]
    if(adObj.filterSet.length > 0){
      if(adObj.filterSet[0].filters > 0){
        // console.log('filters',adObj.filterSet[0].filters)
        newFilters.push(adObj.filterSet[0].filters)
      }
    }
    setFilters(newFilters)
  },[adObj])

  useEffect(()=>{
    validateAges()
  },[filters])

  const handleGenderChange = (e) => {
    const val = e.target.value
    let filtersArr = [...filters]
    let genderFilter = {
      id: 'W6NUekFCgWiRHBr9pgj3',
      value: val
    }

    let index = filtersArr.findIndex(filter => {
      return filter.id === genderFilter.id
    })
    if(index > -1 ){
      if(val && val === 'all'){
        filtersArr.splice(index, 1)
      }else{
        filtersArr.splice(index, 1, genderFilter)
      }
    }else{
      filtersArr.push({...genderFilter, value: val})
    }
    
    setFilters(filtersArr)
    if(filtersArr.length > 0){
      setAdObj({...adObj, filterSet:[{filters: filtersArr}]})
    }else{
      setAdObj({...adObj, filterSet:[]})
    }
  }

  const onMinAgeChange = (val) => {
    setAgeBetweenValues({...ageBetweenValues, min: val})
    const ageBetweenFilter = {
      id: 'GyUK3OHi7regN2sWK7ve',
      value: val
    }
    let filtersArr = [...filters]

    let i = filtersArr.findIndex(filter => {
      return filter.id === ageBetweenFilter.id
    })
    if(i > -1 ){
      let newAgeFilter = {
        ...filtersArr[i],
        value: val
      }
      if(val === 'Min Age' && newAgeFilter.endValue && newAgeFilter.endValue === 'Max Age' || val === 'Min Age' && !newAgeFilter.endValue){
        filtersArr.splice(i, 1)
      }else{
        filtersArr.splice(i, 1, newAgeFilter)
      }
    }else{
      filtersArr.push(ageBetweenFilter)
    }

    setFilters(filtersArr)
    if(filtersArr.length > 0){
      setAdObj({...adObj, filterSet:[{filters: filtersArr}]})
    }else{
      setAdObj({...adObj, filterSet:[]})
    }
  }

  const onMaxAgeChange = (val) => {
    setAgeBetweenValues({...ageBetweenValues, max: val})
    const ageBetweenFilter = {
      id: 'GyUK3OHi7regN2sWK7ve',
      endValue: val
    }
    let filtersArr = [...filters]

    let i = filtersArr.findIndex(filter => {
      return filter.id === ageBetweenFilter.id
    })
    if(i > -1 ){
      let newAgeFilter = {
        ...filtersArr[i],
        endValue: val
      }
      if(val === 'Max Age' && newAgeFilter.value && newAgeFilter.value === 'Min Age' || val === 'Max Age' && !newAgeFilter.value){
        filtersArr.splice(i, 1)
      }else{
        filtersArr.splice(i, 1, newAgeFilter)
      }
    }else{
      filtersArr.push(ageBetweenFilter)
    }

    setFilters(filtersArr)
    if(filtersArr.length > 0){
      setAdObj({...adObj, filterSet:[{filters: filtersArr}]})
    }else{
      setAdObj({...adObj, filterSet:[]})
    }
  }

  const handleResetClick = () => {
    let filtersArr = [...filters]
    let i = filtersArr.findIndex(filter => {
      return filter.id === 'GyUK3OHi7regN2sWK7ve'
    })
    if(i > -1 ){
      filtersArr.splice(i, 1)
    }

    setAgeValidation({status:null, message:null})
    setAgeBetweenValues({min: 'Min Age', max: 'Max Age'})
    setFilters(filtersArr)
    if(filtersArr.length > 0){
      setAdObj({...adObj, filterSet:[{filters: filtersArr}]})
    }else{
      setAdObj({...adObj, filterSet:[]})
    }
  }

  const validateAges = () => {

    let i = filters.findIndex(filter => {
      return filter.id === 'GyUK3OHi7regN2sWK7ve'
    })

    if(i > -1){
      if(
        filters[i].value &&  !filters[i].endValue 
        || !filters[i].value && filters[i].endValue
        || filters[i].value === 'Min Age' && filters[i].endValue
        || filters[i].value && filters[i].endValue === 'Max Age'
      ){
        setFormValid(false)
      }else{
        if(filters[i].value > filters[i].endValue){
          setFormValid(false)
          setAgeValidation({status: 'error', message:'Please set Min age lower than Max age'})
        }else{
          setFormValid(true)
          setAgeValidation({status: null, message: null})
        }
      }
    }else{
      setFormValid(true)
      setAgeValidation({status: null, message: null})
    }
  }

  return(
    <Col 
      span={24} 
      style={{
        background:'white', 
        borderRadius:8, 
        boxShadow:'0px 1px 2px rgba(0,0,0,0.25)', 
        paddingTop: 10,
        paddingBottom: 20,
        paddingLeft: 20,
        paddingRight: 20,
      }}
    >
      <Typography.Title
        level={4} 
        style={{
          margin:0
        }}
      >
        Ad Audience
      </Typography.Title>
      <Typography.Text
        type='secondary'
      >
        Who do you want to see your ad?
      </Typography.Text>
      <Form
        form={form}
        layout="vertical"
        style={{marginTop: 10}}
      >
        <Form.Item label="Gender">
          <Radio.Group
            onChange={handleGenderChange}
            // options={}
            defaultValue='all'
            // value={genderFilter.value}
            optionType='button'
          >
            <Radio.Button value="all">All</Radio.Button>
            <Radio.Button value='male'>Male</Radio.Button>
            <Radio.Button value='female'>Female</Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Form.Item 
          label='Age'
          validateStatus={ageValidation.status}
          help={ageValidation.message}
          >
          <Select 
            defaultValue={13}
            value={ageBetweenValues.min} 
            style={{width:120}} 
            onChange={onMinAgeChange}
            name='minAge'
          >
            {minAgeArr.map(age => {
              return <Option key={age} value={age}>{age}</Option>
            })}
          </Select>
          <Select 
            defaultValue='And Older'
            value={ageBetweenValues.max}
            style={{width:120}} 
            onChange={onMaxAgeChange}
            name='minAge'
          >
            {maxAgeArr.map(age => {
                return <Option key={age} value={age}>{age}</Option>
              })}
          </Select>
          <Button type='link' onClick={handleResetClick}>Reset</Button>
        </Form.Item>
      </Form>
    </Col>
  )
}