import React, { useState } from 'react'
import { Input, Form } from 'antd'
import CloseIcon from '@material-ui/icons/Close';

function RankResponsesGenerator(props){
  const { handleResponseChange, removeOption, index, responses, response} = props
  const [ showRemoveHover, setShowRemoveHover ] = useState(false)

  const showHideRemoveBtn = () => {
    if(showRemoveHover){
      if(responses.length > 2){
        return <CloseIcon className='rank-response-remove-btn' onClick={() => removeOption(index)}/>
      }
    }
  }

  return(
    <div className='rank-response-section' onMouseEnter={() => setShowRemoveHover(true)} onMouseLeave={() => setShowRemoveHover(false)}>
      <Form.Item
        key={index}
        label='Response'
        name={`response${index}`}
        rules={[
          {
            required: true,
            message: 'Please add a Response',
          },
        ]}
        onChange={(e)=>handleResponseChange(e,index)}
      >
        <Input bordered={false} placeholder={'Response'} style={{borderBottom:'1px solid rgba(0,0,0,0.1'}}/>
      </Form.Item>
      {showHideRemoveBtn()}
    </div>
  )
}

export default RankResponsesGenerator