import React, { useContext, useState } from 'react'
import moment from 'moment'
import { Row, Col, Typography, Skeleton, Empty, Button, List, Card, Input, Avatar } from 'antd'

import { FirebaseContext } from '../../utils/Firebase'
import { useSelector, useDispatch, connect } from 'react-redux';
import { useHistory } from 'react-router-dom'
import { PlusOutlined, UserOutlined, StarFilled, StarOutlined } from '@ant-design/icons'
import { switchPastFeed } from '../../redux/publishing/actions';

function PastFeedsSidebar({ switchPastFeedAction }){
  const firebase = useContext(FirebaseContext)
  const history = useHistory()

  const pastFeeds = useSelector(state => state.publishing.pastFeedsList)
  const activePastFeed = useSelector(state => state.publishing.activePastFeed)

  const [ searchText, setSearchText ] = useState()
  const [ filteredList, setFilteredList ] = useState([])
  const [ isLoading, setIsLoading ] = useState(false)

  console.log('pastFeeds',pastFeeds)

  // SET ACTIVE PAST FEED


  // SEARCH FEEDS FUNCTIONALITY
  // useEffect(()=>{
  //   if(!feedList)return
  //   setFilteredList(feedList)
  // },[feedList])

  Array.prototype.unique = function() {
    var a = this.concat();
    for(var i=0; i<a.length; ++i) {
        for(var j=i+1; j<a.length; ++j) {
            if(a[i] === a[j])
                a.splice(j--, 1);
        }
    }
    return a;
  };

  const searchFeedList = (e) => {
    setSearchText(e.target.value)
    let titleList = [...pastFeeds]
    let eventNameList = [...pastFeeds]
    titleList = titleList.filter( feedObj => {
      return feedObj.title.toLowerCase().search(e.target.value.toLowerCase()) !== -1;
    })
    eventNameList = eventNameList.filter( feedObj => {
      return feedObj.eventName.toLowerCase().search(e.target.value.toLowerCase()) !== -1;
    })
    let updatedList = titleList.concat(eventNameList).unique()
    setFilteredList(updatedList)
  }

  const handleFeedClick = (feed) => {
    switchPastFeedAction(feed)
  }

  const handleFavoriteClick = (val) => {
    if(val.favorited){
      firebase.getCollection('feeds').doc(val.id).update({favorited:false})
        .then(()=>console.log("Document successfully updated with Favorite!"))
        .catch(err => console.error("Error updating document: ", err))
    } else {
      firebase.getCollection('feeds').doc(val.id).update({favorited:true})
        .then(()=>console.log("Document successfully updated with Favorite!"))
        .catch(err => console.error("Error updating document: ", err))
    }
  }

  return (
    <Row style={{ paddingTop:20, minWidth:350, height:'100%', overflow:'hidden', paddingBottom:110 }}>
      <Col span={24} style={{ paddingLeft:20 }}>
        <Typography.Title level={3}>Publisher Feeds</Typography.Title>
      </Col>
      {isLoading
        ? 
          <Col span={24}>
            <Skeleton loading={isLoading} active avatar/>
          </Col>
        :
        pastFeeds && pastFeeds.length > 0 || activePastFeed
            ? 
              <Row style={{height:'100%'}}>
                <Col span={24}>
                  <Input 
                    value={searchText} 
                    bordered={false} 
                    size="large" 
                    placeholder={`Search Feeds`} 
                    prefix={<UserOutlined />} 
                    onChange={searchFeedList}
                    style={{padding:20}}
                  />
                </Col>
                <Col span={24} style={{height:'100%', overflowY:'scroll'}}>
                  <List
                    itemLayout='horizontal'
                    dataSource={pastFeeds}
                    rowKey='id'
                    renderItem={item => {
                      // console.log('item',item.id)

                      return (<List.Item 
                        style={{ 
                          position:'relative',
                          borderLeft:`${activePastFeed && activePastFeed.id  === item.id ? '5px black solid' : '5px white solid'}`
                        }}
                      >
                        <Card
                          bordered={false}
                          style={{width:'100%', cursor:'pointer'}}
                          onClick={()=>handleFeedClick(item)}
                        >
                          <Card.Meta 
                            avatar={<Avatar src={item.picture} size={50}/>}
                            title={item.title}
                            description={
                            <Row>
                              <Col span={24}>{item.eventName},</Col>
                              <Col>{item.formattedTimestamp.day} {item.formattedTimestamp.month} {item.formattedTimestamp.date} {item.formattedTimestamp.year} at {item.formattedTimestamp.time}</Col>
                            </Row>
                            }
                          />
                        </Card>
                        <Row style={{position:'absolute', right: 10, top: 10, cursor:'pointer', zIndex:10}} onClick={() => handleFavoriteClick(item)}>
                          {item.favorited ? <StarFilled/> : <StarOutlined/>}
                        </Row>
                      </List.Item>
                      )}
                    }
                  />
                  {/* {sidebarTime === 'past' 
                    ? 
                      <Button type='primary' size='large' block onClick={fetchMorePastFeeds}>Get More Past Feeds</Button>
                    :
                      null
                  } */}
                </Col>
              </Row>
            :
              <Row>
                <Col span={24} style={{paddingTop:50}}>
                  <Empty description={'No Feeds'}/>
                </Col>
                <Col span={24} style={{display:'flex', justifyContent:'center', alignItems:'center', paddingTop:25}}>
                  <Button type="primary" icon={<PlusOutlined/>} onClick={()=>history.push('/browse-events')}>Add a New Feed</Button>
                </Col>
              </Row>
      }
    </Row>
  )
}


export default connect(null, {
  switchPastFeedAction: switchPastFeed,
})(PastFeedsSidebar)