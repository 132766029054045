import React, { useState, useEffect, useContext } from 'react'
import { message, Row, Col, Button, Form, Image } from 'antd'

// COMPONENTS
import { handleWriteNewPrompt } from './PromptWriter'
import AttachAdToPrompt from './AttachAdToPrompt';
import AttachScheduleToPrompt from './AttachScheduleToPrompt';
import { FirebaseContext } from '../../../utils/Firebase';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';

// REDUX
import { useSelector } from 'react-redux';
import PromptContent from './PromptContent';

// FIREBASE

const initialPromptObj = {
  text:'',
}


function PromptBuilder({ selectedType }){
  const firebase = useContext(FirebaseContext)
  const selectedPublisher = useSelector(state => state.publishing.publishingAs )
  const feed = useSelector(state =>  state.publishing.activeCurrentFeed)
  const [ form ] = Form.useForm()
  const [ prompt, setPrompt ] = useState(initialPromptObj)
  const [ isPosting, setIsPosting ] = useState()

  // console.log('selectedPublisher', selectedPublisher)
  console.log('prompt', prompt)
  
  const initializePrompt = (incomingPrompt) =>{
    let newPrompt = {...incomingPrompt}
    if(newPrompt && newPrompt.responseCaptions){
      delete newPrompt.responseCaptions
    }
    if(selectedType === 'Two Up'){
      newPrompt = {
        ...newPrompt, 
        responseContentType:'text', 
        responseType: 'single choice', 
        responses:['',''],
      }
    } else if(selectedType === 'Four Up'){
        newPrompt = {
          ...newPrompt, 
          responseContentType:'text',
          responseType: 'single choice', 
          responses:['','','','']
        }
    } else if(selectedType === 'Checklist'){
        newPrompt = {
          ...newPrompt, 
          responseContentType:'text', 
          responseType: 'multiple choice', 
          responses:['','','','']
        }
    } else if(selectedType === 'Scale'){
        newPrompt = {
          ...newPrompt, 
          responseContentType:'text', 
          responseType: 'sliding scale', 
          responses:['','']
        }
    } else if(selectedType === 'Media Two Up'){
        newPrompt = {
          ...newPrompt, 
          responseContentType:'image', 
          responseType: 'single choice', 
          responses:['',''], 
          responseCaptions:['','']
        }
    } else if(selectedType === 'Rank'){
        newPrompt = {
          ...newPrompt, 
          responseContentType:'text', 
          responseType: 'ranking'
        }
    }
    setPrompt(newPrompt)
  }
  
  useEffect(()=>{
    if(prompt){
      initializePrompt(prompt)
    }else{
      initializePrompt(initialPromptObj)
    }
  },[selectedType])

  const buildMediaTwoUpResponses = async (img1, img2) => {
    let responses = []
    if(img1 && img2){
      let firebaseURL1 = await firebase.handleFirebaseImgUpload(img1, message)
      let firebaseURL2 = await firebase.handleFirebaseImgUpload(img2, message)
      responses = [firebaseURL1, firebaseURL2]
    }else {
      message.error('Please Select Two Images')
    }
    return responses
  }  

  const buildPrompt = async (collection) => {
    setIsPosting(true)

    let DbPrompt = {...prompt, eventId: feed.eventId, feedId: feed.id, publisherId: selectedPublisher.id }
    if(prompt.photoURL && prompt.photoUrl !== null) DbPrompt = {...DbPrompt, photoURL: await firebase.handleFirebaseImgUpload(prompt.photoURL) }
    if(prompt.responseCaptions) DbPrompt = {...DbPrompt, responses: await buildMediaTwoUpResponses(prompt.responses[0],prompt.responses[1])}
    
    handleWriteNewPrompt(DbPrompt, collection)
      .then(() => {
        setIsPosting(false)
        form.resetFields()
        setPrompt(initialPromptObj)
        initializePrompt(initialPromptObj)
      }).catch(err => {
        message.error('Error sending prompt, please try again')
        console.log('error:', err)
        setIsPosting(false)
      })
  }

  const handleScheduleDelete = () => {
    let newPromptObj = {...prompt}
    delete newPromptObj.scheduling
    setPrompt(newPromptObj)
  }

  const removeImg = () => {
    let newPromptObj = {...prompt}
    delete newPromptObj.photoUrl
    setPrompt(newPromptObj)
  }

  return(
    <Row  style={{width:'100%'}}>
      <Form
        form={form}
        name="control-ref" 
        onFinish={()=>buildPrompt(prompt.scheduling ? 'scheduled_prompts' : 'prompts')}
        style={{width:'100%'}}
        layout='vertical'
      >
        <Col span={24} style={{padding: '20px 50px'}}>
          {prompt.photoUrl 
            ? 
              <Row justify='center' style={{marginBottom:20}}>
                <Button onClick={removeImg} danger style={{position:'absolute', right:0, top:0}}>Remove</Button>
                <Image preview={false} src={prompt.photoUrl} style={{maxWidth:500, border:'1px solid rgba(0,0,0,0.4)'}}/>
              </Row>
            : 
              null
          }
          <PromptContent
            prompt={prompt}
            setPrompt={setPrompt}
          />
          <AttachAdToPrompt 
            prompt={prompt}
            setPrompt={setPrompt}
            form={form}
          />
          <AttachScheduleToPrompt
            prompt={prompt}
            setPrompt={setPrompt}
            handleDelete={handleScheduleDelete}
          />
          <Row justify='center' align='middle'>
            <Button type='link' onClick={()=>buildPrompt('prompt_drafts')} icon={<EditOutlined/>}>Save as a Draft</Button>
          </Row>
          <Row justify='center' align='middle' style={{marginTop: 5}}>
            {prompt && prompt.scheduling
              ?
                <Button type='primary' loading={isPosting} htmlType="submit">Schedule Now</Button>
              :
                <Button type='primary' loading={isPosting} htmlType="submit">Post Now</Button>
            }
          </Row>
        </Col>
      </Form>
    </Row>
  )
}

export default PromptBuilder