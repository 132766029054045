import { Col, Form, Input, Radio, Row, Space, message } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { FirebaseContext } from '../../utils/Firebase'

const objectives = {
  awareness:[
    'Brand Awareness',
    'Reach',
  ],
  consideration:[
    'Traffic',
    'App Installs',
    'Video Views',
    'Lead Generation'
  ],
  conversions:[
    'Conversions',
    'Store Traffic',
  ]
}

export default function CreateNewAdCampaignModal({ selectedCampaign, setModalOpen }){
  const firebase = useContext(FirebaseContext)
  const publisherId = useSelector(
    (state) => state.publishing.publishingAs?.id ?? 0
  )
  const [form] = Form.useForm();
  const [ showCampaignName, setShowCampaignName ] = useState(false)
  
    useEffect(()=>{
      if(selectedCampaign && selectedCampaign.name){
        setShowCampaignName(true)
      }
    },[selectedCampaign])
  
  const handleToggleAdCampaignName = (e) => {
    e.preventDefault()
    setShowCampaignName(true)
  }

  const handleFormSubmit = (formData) => {
    console.log(formData)
    const campaign = {
      ...selectedCampaign,
      name:formData.campaignName,
      objective:formData.campaignObjective,
    }
    let db = firebase.getCollection('publisher').doc(publisherId).collection('ad-campaigns')
    if(selectedCampaign.id){
      db.doc(selectedCampaign.id)
        .update({ name:formData.campaignName, objective:formData.campaignObjective,})
        .then(() => {
          message.success('Campaign Updated Successfully!')
          setModalOpen(false)
        })
        .catch(err => {
          console.log('error:', err)
          message.warning('Error editing your campaign, please try again.')
        })
    }else{
      db
        .add(campaign)
        .then((ref) => {
          message.success('Campaign Created Successfully!')
          setModalOpen(false)
          db.doc(ref.id).update({id:ref.id})
        })
        .catch(err => {
          console.log('error:', err)
          message.warning('Error Creating New Campaign, please try again.')
        })
    }
  }

  return(
    <Row gutter={16} style={{ width:'100%' }}>
      <Col span={24}>
        <Form
          form={form}
          layout="vertical"
          id='newAdCampaignForm'
          name="newAdCampaignForm" 
          autoComplete='off'
          initialValues={{
            campaignObjective: selectedCampaign.objective,
            campaignName: selectedCampaign.name
          }}
          onFinish={handleFormSubmit}
        >
          <Row justify='space-between'>
            <Col span={1/3}>
              <Form.Item label="Awareness" name='campaignObjective'>
                <Radio.Group onChange={handleToggleAdCampaignName}>
                  <Space direction="vertical">
                    {objectives.awareness.map(obj => (
                      <Radio value={obj}>
                        {obj}
                      </Radio>
                    ))}
                  </Space>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={1/3}>
              <Form.Item label="Consideration" name='campaignObjective'>
                <Radio.Group>
                  <Space direction="vertical">
                    {objectives.consideration.map(obj => (
                      <Radio value={obj}>
                        {obj}
                      </Radio>
                    ))}
                  </Space>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={1/3}>
              <Form.Item label="Conversions" name='campaignObjective'>
                <Radio.Group>
                  <Space direction="vertical">
                    {objectives.conversions.map(obj => (
                      <Radio value={obj}>
                        {obj}
                      </Radio>
                    ))}
                  </Space>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          {showCampaignName 
            ?
              <Form.Item label="Campaign Name" name='campaignName'>
                <Input placeholder="Name"/>
              </Form.Item>
            :
              null
          }
          {/* {objective 
            ?
              <Form.Item label="Campaign Schedule">
                <DatePicker.RangePicker onChange={handleDateChange}/>
              </Form.Item>
            :
              null
          } */}
        </Form>
      </Col>
    </Row>
  )
}
