import React from 'react';
import { Button, Row, Col, Typography } from 'antd';
import { NavLink } from 'react-router-dom';
import UnauthorizedLayout from '../layouts/UnauthorizedLayout';
import '../main.scss'


const UnauthorizedView = () => {
  return (
    <UnauthorizedLayout>
      <Row className='auth-container'>
      <Col className='column-center'>
        <Typography.Title>
          Uh uh uh... you didn't say the magic word.
        </Typography.Title>
      </Col>
      <Col className='column-center'>
        <NavLink to='/auth/login'>
          <Button type='primary' shape='round'>
            Go Home
          </Button>
        </NavLink>
      </Col>
      </Row>
    </UnauthorizedLayout>
  );
};

export default UnauthorizedView;
