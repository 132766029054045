import React, { useState, useContext } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import OpineLogo from '../../assets/images/OpineLogo.svg'

import { FirebaseContext } from '../../utils/Firebase';
import { Button, Row, Col, Form, Input, Modal, Typography, Image } from 'antd';
import { UserOutlined, LockOutlined, GoogleCircleFilled, FacebookFilled, TwitterCircleFilled, EyeTwoTone, EyeInvisibleOutlined, AppleFilled } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { compose } from 'recompose';

const INITIAL_STATE = {
  userName: '',
  email: '',
  password: '',
  error: null,
};

export default function SignUp(){
  const history = useHistory()
  return (
    <Row 
      align='middle'
      justify='center'
      style={{
        height:'100%',
      }}
      >
        <Col
          style={{
            background: 'white',
            padding: '4em',
            width: 500,
          }}
        >
          <Image width={100} src={OpineLogo} preview={false} />
          <Typography.Title>Hello Friend</Typography.Title>
          <Typography.Title level={5} style={{marginBottom: 25}}>Please create an account using one of the methods below.</Typography.Title>
          <Row gutter={[8,8]}>
              <Col span={24}><SignUpGoogle/></Col>
              <Col span={24}><SignUpFacebook/></Col>
              {/* <Col span={24}><SignUpApple/></Col> */}
              {/* <Col span={24}><SignUpTwitter/></Col> */}
              <Col span={24}><EmailSignUp/></Col>
              <Col span={24}>
                <Row justify='center'>
                  <Typography.Text>Already Have an Account?</Typography.Text>
                  <Button type='link' block onClick={()=>history.push('/auth/login')}>
                    Sign In Here
                  </Button>
                </Row>
              </Col>
          </Row>
        </Col>
    </Row>
  )
}

function EmailSignUp(){
  const history = useHistory()
  const firebase = useContext(FirebaseContext)
  const [form] = Form.useForm();
  const [ state, setState ] = useState( { ...INITIAL_STATE } )
  // const [ modalOpen, setModalOpen ] = useState(false)
  // const [ modalData, setModalData ] = useState()
  const [ passwordValidation, setPasswordValidation ] = useState({
    status: null,
    message: null
  })
  const [ emailValidation, setEmailValidation ] = useState({
    status: null,
    message: null
  })
  
  const onFinish = (values) => {
    setEmailValidation({
      status:null,
      message: null
    })
    setPasswordValidation({
      status:null,
      message: null
    })
    const { email, password } = values
    firebase.auth.createUserWithEmailAndPassword(email, password)
    .then(() => {
      // setModalOpen(true)
      history.push('/dashboard')
    })
    .catch(error => {
      console.log('error:', error)
      if(error.code === 'auth/email-already-in-use'){
        setEmailValidation({
          status: 'error',
          message: error.message
        })
      }else if(error.code === 'auth/weak-password'){
        setPasswordValidation({
          status: 'error', 
          message:error.message
        })
      }
    });
    
  };

  const onFinishFailed = (errorInfo) => {
    // console.log('Failed:', errorInfo);
    if(errorInfo.errorFields[0].name[0] === 'email'){
      setEmailValidation({
        status:'error',
        message: errorInfo.errorFields[0].errors[0]
      })
    }
    if(errorInfo.errorFields[0].name[0] === 'password'){
      setPasswordValidation({
        status:'error',
        message: errorInfo.errorFields[0].errors[0]
      })
    }
    if(errorInfo.errorFields[1] && errorInfo.errorFields[1].name[0] === 'password'){
      setPasswordValidation({
        status:'error',
        message: errorInfo.errorFields[1].errors[0]
      })
    }
  };

  const handleEmailChange = async (val) => {
    try {
      const values = await form.validateFields(['email']);
      // console.log('Success:', values);
      setState({...state, email:values.email})
      setEmailValidation({
        status:'success'
      })
    } catch (errorInfo) {
      // console.log('Failed:', errorInfo);
      setEmailValidation({
        status:'error',
        message: errorInfo.errorFields[0].errors[0]
      })
    }
  }

  const handlePasswordChange = async (val) => {
    try {
      const values = await form.validateFields(['password']);
      // console.log('Success:', values);
      setState({...state, password:values.password})
      setPasswordValidation({
        status:'success'
      })
    } catch (errorInfo) {
      // console.log('Failed:', errorInfo);
      setPasswordValidation({
        status:'error',
        message: errorInfo.errorFields[0].errors[0]
      })
    }
  }

  // const handleModalCancel = () => {

  // }

  // const handleModalSubmit = () => {

  // }
  return (
    <Row style={{marginTop:20}}>
      <Col span={24}><Typography.Title level={5}>Sign Up with Email</Typography.Title></Col>
      <Col span={24}>
        <Form
          form={form}
          name="signUpForm"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout='vertical'
        >
          <Form.Item
            name="email"
            rules={[
              {
                type: 'email',
                message: 'Please enter a valid E-mail',
              },
              {
                required: true,
                message: 'E-mail is required',
              },
            ]}
            validateStatus={emailValidation.status}
            help={emailValidation.message}
            hasFeedback
            style={{marginBottom:8}}
          >
            <Input 
              autoComplete='off' 
              placeholder="Email" 
              prefix={<UserOutlined/>} 
              value={state.userName}
              onChange={handleEmailChange}
            />
          </Form.Item>
          <Form.Item
            // label="Password"
            name="password"
            rules={[
              { required: true, 
                message: 'Please input your Password!' 
              }
            ]}
            validateStatus={passwordValidation.status}
            help={passwordValidation.message}
            hasFeedback
          >
            <Input.Password
              // type='hidden'
              value={state.password}
              autoComplete='new-password'
              prefix={<LockOutlined/>}
              // type="password"
              iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
              placeholder="Password"
              onChange={handlePasswordChange}
            />
          </Form.Item>

          {state.password 
            ? 
            <Form.Item
              name="confirm"
              // label="Confirm Password"
              dependencies={['password']}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: 'Please confirm your password!',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('The two passwords that you entered do not match!'));
                  },
                }),
              ]}
            >
              <Input.Password 
              placeholder='Confirm Password'
              prefix={<LockOutlined />}
              />
            </Form.Item>
          :
              null
          }

          <Form.Item>
            <Row gutter={[16,16]}>
              <Col span={24}>
                <Button type="primary" htmlType="submit" block>
                  Sign Up
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </Col>
{/* 
      <Modal
        title='Welcome New User'
        visible={modalOpen}
        centered={true}
        onOk={handleModalSubmit}
        onCancel={handleModalCancel}
        width={1000}
        destroyOnClose={true}
        okText='Create Ad Set'
        bodyStyle={{background:'#f0f2f5'}}
      >
        <NewUserModal
          modalData={modalData}
          setModalData={setModalData}
        />
      </Modal> */}
    </Row>
  )
}

function SignUpGoogleBase(){
  const firebase = useContext(FirebaseContext)
  const history = useHistory()
  const dispatch = useDispatch()
  const [ error, setError ] = useState(null)

  const onSubmit = event => {
    // console.log('clicked')
    firebase
      .doSignInWithGoogle()
      .then(socialAuthUser => {
        if (socialAuthUser !== null ) {
          // console.log('socialAuthUser', socialAuthUser)
          firebase.buildOpineUser(socialAuthUser.user)
            .then(res => {
              dispatch({type:'SET_IS_ROUTES_LOADING', payload:false})
              if(res){
                dispatch({type:'SET_USER', payload:res})
              }else{
                dispatch({type:'SET_USER', payload:{...socialAuthUser, registered: false}})
              }
              history.push('/publisher')
            })
            .catch(err => console.log('error', err))
        }
      })
      .catch(error => {
        setError(error );
      });

    event.preventDefault();
  };

  return (
    <form onSubmit={onSubmit}>
      <Row>
        <Button block variant='text' onClick={onSubmit} icon={<GoogleCircleFilled/>}>Sign up with Google</Button>
      </Row>
      {error && <p>{error.message}</p>}
    </form>
  );
  
}

function SignUpFacebookBase(){
  const firebase = useContext(FirebaseContext)
  const history = useHistory()
  const dispatch = useDispatch()
  const [ error, setError ] = useState(null)

  const onSubmit = event => {
    // console.log('clicked')
    firebase
      .doSignInWithFacebook()
      .then(socialAuthUser => {
        if (socialAuthUser !== null ) {
          // console.log('socialAuthUser', socialAuthUser)
          firebase.buildOpineUser(socialAuthUser.user)
            .then(res => {
              dispatch({type:'SET_IS_ROUTES_LOADING', payload:false})
              if(res){
                dispatch({type:'SET_USER', payload:res})
              }else{
                dispatch({type:'SET_USER', payload:{...socialAuthUser, registered: false}})
              }
              history.push('/publisher')
            })
            .catch(err => console.log('error', err))
        }
      })
      .catch(error => {
        setError(error );
      });

    event.preventDefault();
  };


  return (
    <form onSubmit={onSubmit}>
      <Row>
        <Button block variant='text' onClick={onSubmit} icon={<FacebookFilled/>}>Sign up with Facebook</Button>
      </Row>
      {error && <p>{error.message}</p>}
    </form>
  )
}

function SignUpTwitterBase(){
  const firebase = useContext(FirebaseContext)
  const history = useHistory()
  const dispatch = useDispatch()
  const [ error, setError ] = useState(null)

  const onSubmit = event => {
    // console.log('clicked')
    firebase
      .doSignInWithTwitter()
      .then(socialAuthUser => {
        if (socialAuthUser !== null ) {
          // console.log('socialAuthUser', socialAuthUser)
          firebase.buildOpineUser(socialAuthUser.user)
            .then(res => {
              dispatch({type:'SET_IS_ROUTES_LOADING', payload:false})
              if(res){
                dispatch({type:'SET_USER', payload:res})
              }else{
                dispatch({type:'SET_USER', payload:{...socialAuthUser, registered: false}})
              }
              history.push('/publisher')
            })
            .catch(err => console.log('error', err))
        }
      })
      .catch(error => {
        setError(error );
      });

    event.preventDefault();
  };


  return (
    <form onSubmit={onSubmit}>
      <Row>
        <Button block variant='text' onClick={onSubmit} icon={<TwitterCircleFilled/>}>Sign up with Twitter</Button>
      </Row>
      {error && <p>{error.message}</p>}
    </form>
  )
}

function SignUpAppleBase(){
  const firebase = useContext(FirebaseContext)
  const history = useHistory()
  const dispatch = useDispatch()
  const [ error, setError ] = useState(null)

  const onSubmit = event => {
    // console.log('clicked')
    firebase
      .doSignInWithApple()
      .then(socialAuthUser => {
        if (socialAuthUser !== null ) {
          // console.log('socialAuthUser', socialAuthUser)
          firebase.buildOpineUser(socialAuthUser.user)
            .then(res => {
              dispatch({type:'SET_IS_ROUTES_LOADING', payload:false})
              if(res){
                dispatch({type:'SET_USER', payload:res})
              }else{
                dispatch({type:'SET_USER', payload:{...socialAuthUser, registered: false}})
              }
              history.push('/publisher')
            })
            .catch(err => console.log('error', err))
        }
      })
      .catch(error => {
        setError(error );
      });

    event.preventDefault();
  };


  return (
    <form onSubmit={onSubmit}>
      <Row>
        <Button block variant='text' onClick={onSubmit} icon={<AppleFilled/>}>Sign up with Apple</Button>
      </Row>
      {error && <p>{error.message}</p>}
    </form>
  )
}

const SignUpGoogle = compose(
  withRouter,
)(SignUpGoogleBase);

const SignUpFacebook = compose(
  withRouter,
)(SignUpFacebookBase);

const SignUpTwitter = compose(
  withRouter,
)(SignUpTwitterBase);

const SignUpApple = compose(
  withRouter,
)(SignUpAppleBase);