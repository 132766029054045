import React, { useEffect, useState } from 'react'
import { Input, Form, Col, Row } from 'antd'
import IconUploader from '../../../../components/IconUploader/IconUploader';

export default function SingleChoice({ prompt, setPrompt }){
  const [ img, setImg ] = useState()

  useEffect(()=>{
    if(prompt && !prompt.photoUrl){
      setImg()
    }
  },[prompt])
  
  useEffect(()=>{
    if(img){
      setPrompt({...prompt, photoUrl: img})
    }else if(!img && prompt.photoUrl){
      let newPrompt = {...prompt}
      delete newPrompt.photoUrl
      setPrompt(newPrompt)
    }
  },[img])

  const handleStatementChange = e => {
    setPrompt({...prompt, text:e.target.value})
  };

  const handleResponseChange = (e,index) => {
    let newArr = [...prompt.responses]
    newArr[index] = e.target.value
    setPrompt({...prompt, responses: newArr})
  };

  return (
    <Row>
      {prompt.photoUrl 
        ? null
        :
        <Col span={24}>
          <IconUploader setFile={setImg} prompt={prompt}/>
        </Col>
      }
      
      <Col span={24}>
        <Form.Item
          label="Statement"
          name="text"
          rules={[
            {
              required: true,
              message: 'Please add a Statement',
            },
          ]}
          onChange={handleStatementChange}
        >
          <Input 
            bordered={false}
            style={{borderBottom:'1px solid rgba(0,0,0,0.1'}} 
            placeholder='Enter your statement'
            // suffix={<IconUploader setFile={setImg} prompt={prompt}/>}
          />
        </Form.Item>
      </Col>
      <Col span={24}>
        { prompt && prompt.responses 
          ?
            prompt.responses.map((response, index)=>(
              <Form.Item
                key={index}
                label="Response"
                name={`response${index}`}
                rules={[
                  {
                    required: true,
                    message: 'Please add a Response',
                  },
                ]}
                onChange={(e)=>handleResponseChange(e,index)}
              >
                <Input bordered={false} placeholder='Response' style={{borderBottom:'1px solid rgba(0,0,0,0.1'}}/>
              </Form.Item>
            ))
          :
            null
          }
      </Col>
    </Row>
  )
}