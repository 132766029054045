export const deconstructFilters = (id, name, filters) => {
  if(!filters)return
  let audienceObj
  filters.map(filter => {
    let filterValue = filterSwitcher(filter)
    for (const property in filterValue){
      // console.log(`${property}: ${filterValue[property]}`);
      audienceObj = {...audienceObj, [property]: filterValue[property]}
    }
  })
  audienceObj = {...audienceObj, name, id}
  if(!audienceObj.gender) audienceObj.gender = 'all'
  return audienceObj
}

const filterSwitcher = (filter) => {
  const ageLessThan = 'AzSuVuhDexprQ7Q2usYu',
        ageBetween = 'GyUK3OHi7regN2sWK7ve',
        gender = 'W6NUekFCgWiRHBr9pgj3',
        ageGreaterThan = 'mb6UDypGR8FfZKK5DkPL'

  switch(filter.id){
    case ageLessThan:
      return {ageRange: `${filter.value} and younger`,  age: filter.value}

    case ageGreaterThan:
      return {ageRange: `${filter.value}+`, age: filter.value}

    case ageBetween:
      return {ageRange: `${filter.value} - ${filter.endValue}`, age:filter.value, ageEnd: filter.endValue}

    case gender:
      return {gender: filter.value}
  }
}

export const constructFilters = (genderValue, ageValue, ageEndValue) => {
  let filters = []
  if(genderValue !== 'all'){
    // SEX EQUAL TO
    let filter = {
      id: 'W6NUekFCgWiRHBr9pgj3',
      value: genderValue
    }
    filters.push(filter)
  }

  if(ageEndValue === 'And Older'){
    // AGE GREATER THAN
    let filter = {
      id: 'mb6UDypGR8FfZKK5DkPL',
      value: ageValue,
    }
    filters.push(filter)
  } else {
    // AGE BETWEEN
    let filter = {
      id: 'GyUK3OHi7regN2sWK7ve',
      value: ageValue,
      endValue: ageEndValue
    }
    filters.push(filter)
  }
  return filters
}