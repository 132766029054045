import React, { useState } from 'react';
import { Upload, Image, message, Tooltip, Button, Row, Col, Modal, Spin } from 'antd'
import ImgCropper from '../ImgCropper/ImgCropper'
import Resizer from "react-image-file-resizer";

import { VideoCameraAddOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';

const { Dragger } = Upload;

const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      800,
      800,
      "JPEG",
      70,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64",
      500,
      500
    );
  });

export default function DropZoneUploader({file,setFile}){
  const [ editButtonType, setEditButtonType ] = useState('default')
  const [ removeButtonType, setRemoveButtonType ] = useState('default')
  const [ uploadedFile, setUploadedFile ] = useState(null)
  const [ croppedData, setCroppedData ] = useState()
  const [ modalOpen, setModalOpen ] = useState(false)
  const [ isUploading, setIsUploading ] = useState(false)

  const handleUpload = async (upload) => {
    try {
      const image = await resizeFile(upload.file);
      upload.onSuccess(image)
    } catch (err) {
      upload.onError(err)
    }
  }

  const handleChange = async (info) => {
    const isImage = info.file.type === 'image/jpeg' || info.file.type === 'image/png'
    if (!isImage) {
      return message.error('Please try agian, the file type you tried to upload is not currently supported')
    } else {
      const { status } = info.file;
      if (status !== 'uploading') {
        setUploadedFile(null)
        setIsUploading(true)
      }
      if (status === 'done') {
        setModalOpen(true)
        setUploadedFile(info.file.response)
        setIsUploading(false)
      } else if (status === 'error') {
        console.log('error',info.file)
        message.error(`${info.file.name} file upload failed.`);
        message.error(`info ${info.file}`);
        setIsUploading(false)
        setModalOpen(false)
        setUploadedFile(null)
      }
    }
  }

  const removePhoto = () => {
    setFile(null)
  }

  const handleModalSubmit = () => {
    setFile(croppedData)
    setModalOpen(false)
    setUploadedFile(null)
    setCroppedData(null)
  }
  
  const handleModalCancel = () => {
    setUploadedFile(null)
    setCroppedData(null)
    setModalOpen(false)
  }

  const draggerProps = {
    name: 'file',
    multiple: false,
    showUploadList: false,
    style: { padding: '0 2em' },
    // beforeUpload: beforeUpload,
    customRequest: handleUpload,
    onChange: handleChange,
  }

  const modalProps = {
    title: 'Image Crop',
    visible: modalOpen,
    width: 700,
    centered: true,
    okText: 'Done',
    onOk: handleModalSubmit,
    onCancel: handleModalCancel,
    destroyOnClose: true,
  }

  return (
    file 
    ? 
      <Row>
        <Col>
          <Image 
            preview={false}
            src={file} 
            alt={'prompt image'}
            width={'100%'}
          />
        </Col>
        <Col style={{position:'absolute', top:20, right: 20}}>
          <Row gutter={16}>
            {/* <Col onMouseOver={()=>setEditButtonType('primary')} onMouseLeave={()=>setEditButtonType('default')}>
              <Button type={editButtonType} shape="round" icon={<EditOutlined />}>Change Photo</Button>
            </Col> */}
            <Col onMouseOver={()=>setRemoveButtonType('primary')} onMouseLeave={()=>setRemoveButtonType('default')}>
              <Button onClick={removePhoto} type={removeButtonType} danger={removeButtonType === 'primary'} shape="round" icon={<DeleteOutlined />}>Remove Photo</Button>
            </Col>
          </Row>
        </Col>
      </Row>
    :
      // <ImgCrop rotate modalWidth={800}>
      <Spin spinning={isUploading}>
      <Row>
        <Dragger {...draggerProps}>
          <p className="ant-upload-drag-icon">
            <VideoCameraAddOutlined />
          </p>
          <p className="ant-upload-text">Click or drag file to this area to upload</p>
          <p className="ant-upload-hint">
            Support for a single or bulk upload. Strictly prohibit from uploading company data or
            other brand files
          </p>
        </Dragger>
        <Modal {...modalProps}>
          <ImgCropper
            src={uploadedFile}
            setCroppedData={setCroppedData}
            isUploading={isUploading}
          />
        </Modal>
      </Row>
      </Spin>
      // </ImgCrop>
  )
};