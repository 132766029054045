import React from 'react'
import { useSelector } from 'react-redux';

import BaseballScheduler from './PromptSchedulers/BaseballScheduler';
import BasketballScheduler from './PromptSchedulers/BasketballScheduler';
import FootballScheduler from './PromptSchedulers/FootballScheduler';
import HockeyScheduler from './PromptSchedulers/HockeyScheduler';
import NcaaFbScheduler from './PromptSchedulers/NcaaFbScheduler';
import NcaaMbScheduler from './PromptSchedulers/NcaaMbScheduler';
import PoliticsScheduler from './PromptSchedulers/PoliticsScheduler';
import SoccerScheduler from './PromptSchedulers/SoccerScheduler';

export default function CreateNewScheduleModal({ schedule, setSchedule }){
  const event = useSelector(state => state.publishing.activeEvent)
  const sport = useSelector(state => state.publishing.activeEvent.sport)

  const baseball = 'baseball',
        basketball = 'basketball',
        football = 'football',
        hockey= 'hockey',
        politics = 'politics',
        soccer = 'soccer'

  switch(sport){
    case baseball: 
      return(
        <BaseballScheduler
          setSchedule={setSchedule}        
          schedule={schedule}
        />
      )

    case basketball: 
      if(event.league === 'nba'){
        return(
          <BasketballScheduler 
            setSchedule={setSchedule}        
            schedule={schedule}
          />
        )
      }else if(event.league === 'ncaa_mb'){
        return(
          <NcaaMbScheduler
            setSchedule={setSchedule}
            schedule={schedule}
          />
        )
      }else if(event.league === 'grind'){
        return(
          <BasketballScheduler 
            setSchedule={setSchedule}        
            schedule={schedule}
          />
        )
      }

    case football:
      if(event.league === 'nfl' || event.league === 'nflp'){
        return(
          <FootballScheduler
            setSchedule={setSchedule}
            schedule={schedule}
          />
          )
        }else if(event.league === 'ncaa_fb'){
          <NcaaFbScheduler
            setSchedule={setSchedule}
            schedule={schedule}
          />
      }

    case hockey:
      return(
        <HockeyScheduler
          setSchedule={setSchedule}
          schedule={schedule}
        />
      )

    case politics:
      return(
        <PoliticsScheduler
        setSchedule={setSchedule}
          schedule={schedule}
        />
      )

    case soccer:
      return(
        <SoccerScheduler
          setSchedule={setSchedule}
          schedule={schedule}
        />
      )

    default: return(null)
  }
}