import { Col, Empty, Row, Skeleton, Typography } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import './PostHistory.scss';

// COMPONENTS
import { useDispatch, useSelector } from 'react-redux';
import ResultCardWrapper from '../../../Cards/ResultCards/ResultCardWrapper_V2';


// FIREBASE
import { FirebaseContext } from '../../../utils/Firebase';

export default function PostHistory(){
  const firebase = useContext(FirebaseContext)
  const dispatch = useDispatch()
  const feed = useSelector(state => state.publishing.activeCurrentFeed)
  const [ publishedPrompts, setPublishedPrompts ] = useState()
  const [ publishedIsLoading, setPublishedIsLoading ] = useState(true)

  // GET PUBLISHED PROMPTS
  useEffect(() => {
    const unsubscribe = firebase.getCollection('prompts')
    .where('feedId', '==', feed.id)
    .orderBy('ordinalNum', 'desc')
    .onSnapshot(snapshot => {
        const handleSnapshot = firebase.flattenSnapshot(snapshot)
        if(handleSnapshot[0]) {
          setPublishedPrompts(handleSnapshot)
          if(handleSnapshot[0].timestamp){
            dispatch({type:'SET_CURRENT_TIMESTAMP', payload:handleSnapshot[0].timestamp.seconds})
          }
          setPublishedIsLoading(false) 
        } else {
          dispatch({type:'SET_CURRENT_TIMESTAMP', payload:null})
          setPublishedPrompts(null)
          setPublishedIsLoading(false) 
        }
      })
    return () => unsubscribe()
  },[feed])
  
  return(
    <Row gutter={[16,16]}>
      {publishedPrompts 
        ? 
          publishedPrompts.map((prompt)=>(
            <Skeleton key={prompt.id} loading={publishedIsLoading} active avatar>
              <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={6} key={prompt.id} >
                <ResultCardWrapper 
                  feed={feed} 
                  prompt={prompt} 
                  isLoading={publishedIsLoading} 
                  // prevPostTimestamp={prevPostTimestamp}
                />
              </Col>
            </Skeleton>
          ))
        :
          <Col span={24}>
            <Empty description={<Typography.Text type='secondary'>No Published Prompts Yet</Typography.Text>} style={{margin:40}}/>
          </Col>
      }
    </Row>
  )
}
