import React, { useEffect, useState } from 'react'
import { Slider, Typography, Row, Col } from 'antd'
import {ReactComponent as ScaleBubble} from '../../../assets/images/Scale_Bubble.svg'
const winnerColor = '#002A5C'

export default function SlidingScale({ prompt }){
  const { responseCountByChoice } = prompt
  const [ scaleResponseAvg, setScaleResponseAvg ] = useState()
  const [ leftMargin, setLeftMargin ] = useState()

  useEffect(()=>{
    let sum = 0
    for( let val in responseCountByChoice){
        sum += parseInt(val)
    }
    let avg = Math.round(sum / prompt.responseCount)
    setScaleResponseAvg(avg)
  },[responseCountByChoice])

  useEffect(()=>{
    if(scaleResponseAvg <= 25){
      setLeftMargin(0)
    }else if(scaleResponseAvg > 25 && scaleResponseAvg <= 75){
      setLeftMargin(scaleResponseAvg - 25)
    }else{
      setLeftMargin(54)
    }
  },[scaleResponseAvg])

  return(
    <Row gutter={16} align='bottom' style={{ height: 200}}>
      <Col style={{ position:'absolute', height:3, width:`${scaleResponseAvg}%`, background:winnerColor}}/>
      <Col
        style={{
          marginBottom: 30,
          marginLeft: `${leftMargin}%`,
          width: 150,
          height: 150,
          // display: 'flex',
          // justifyContent: 'center',
          // alignItems: 'center'
          }}
        >
          <ScaleBubble style={{position:'absolute'}}/>
          <Row justify='center' align='middle'
            style={{
              height:'100%'
              }}
          >
            <Col style={{marginTop:-20}}>
              <Typography.Text 
                level={1} 
                style={{
                  fontSize:72,
                  letterSpacing:-3,
                  fontWeight:800,
                  color:winnerColor,
                }}
                >
                {scaleResponseAvg}
              </Typography.Text>
            </Col>
            {/* <Col style={{marginTop:-10}}>
              <Typography.Text 
                level={1}
                style={{
                  fontSize:32,
                  fontWeight:800,
                  color:winnerColor
                }} 
              >
                %
              </Typography.Text>
            </Col> */}
          </Row>
        </Col>
      <Col style={{ position:'absolute', height:2, width:`100%`, background:'rgba(0,0,0,0.1)'}}/>
  </Row>
  )
}

    // <Slider 
    //   value={scaleResponseAvg}
    //   tooltipVisible
    //   min={parseInt(prompt.responses[0])}
    //   max={parseInt(prompt.responses[1])}
    //   // marks={[parseInt(prompt.responses[0]),parseInt(prompt.responses[1])]}
    //   tipFormatter={val => (
    //       <Typography.Text style={{color:'white'}}>Avg. Response: {val}</Typography.Text>
    //   )}
    //   style={{marginTop: 70}}
    // />