import React, { useEffect, useState } from 'react'
import { Menu } from 'antd';
import { useHistory } from "react-router-dom";
import { pages } from '../../constants/Menu'
import { useSelector } from 'react-redux';

export default function SiteDrawer(){
  let history = useHistory();
  const user = useSelector(state => state.authUser.currentUser)
  const [ windowUrl, setWindowUrl ] = useState()

  useEffect(()=>{
    return history.listen(location => {
      if(history.action === 'POP'){
        setWindowUrl(location.pathname)
        if(location.pathname !== '/'){
          setWindowUrl(location.pathname)
        } else {
          setWindowUrl('/publisher')
        }
      }else if(history.action === 'PUSH'){
        setWindowUrl(location.pathname)
      }
    })
  },[])

  useEffect(()=>{
    if(history.location.pathname !== '/'){
      setWindowUrl(history.location.pathname)
    } else {
      setWindowUrl('/publisher')
    }
  },[history])

  const handleClick = (url) => {
    history.push(url)
    setWindowUrl(url)
  }

  return(
    <Menu
      mode="inline"
      selectedKeys={[windowUrl]}
      theme='light'
      style={{ height: '100%', borderRight: 0, paddingTop:'12px'}}
    >
      {pages.map((menuItem) => {
        if(menuItem.paid){
          return paidUser(user,handleClick,menuItem)
        } else {
          return <Menu.Item 
            key={menuItem.url}
            onClick={() => handleClick(menuItem.url)} 
            icon={menuItem.icon}
          >
            {menuItem.title}
          </Menu.Item>
        }
      })}
    </Menu>
  )
}

function paidUser(user, handleClick, menuItem){
  if(user && user.role){
		// return user.roles.map(role=>{
			if(user.role === 'superAdmin'){
        return <Menu.Item 
            key={menuItem.url}
            onClick={() => handleClick(menuItem.url)} 
            icon={menuItem.icon}
          >
            {menuItem.title}
          </Menu.Item>
			}
		// })
	}
}