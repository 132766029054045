import React from 'react'
import './StatementCardSkeleton.scss'
import { Col, Empty, Row, Typography } from 'antd'

export default function NoStatementCards(){
  return(
    <Row align='middle' justify='center' style={{background:'white', width:'100%', height:500}}>
      <Col span={24} style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
        <Empty description={false} />
        <Typography.Title level={4}>No posts from this feed.</Typography.Title>
      </Col>
    </Row>
  )
}