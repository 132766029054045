const initialState = {
  authUser:null,
  selectedPublisher:null,
  upcomingFeeds: [],
  pastFeeds:[],
  activeFutureFeed:'none',
  activePastFeed:'none',
  activeEvent:null,
  sponsorListSelection: null,
  feedListSelection: null,
  FBAcessToken:null,
  FBUserId:null,
  currentStatementTimestamp:'',
  lastVisiblePastFeed:'',
  featuredEventsList:[],
  todaysEventsList:[]
}

//Action Descriptors
const SET_USER = 'SET_USER',
      SET_ACTIVE_FUTURE_FEED = 'SET_ACTIVE_FUTURE_FEED',
      SET_ACTIVE_PAST_FEED = 'SET_ACTIVE_PAST_FEED',
      SET_ACTIVE_EVENT = 'SET_ACTIVE_EVENT',
      SET_SPONSOR_LIST_SELECTION = 'SET_SPONSOR_LIST_SELECTION',
      SET_FEED_LIST_SELECTION = 'SET_FEED_LIST_SELECTION',
      SET_UPCOMING_FEEDS = 'SET_UPCOMING_FEEDS',
      SET_PAST_FEEDS = 'SET_PAST_FEEDS',
      SET_FB_ACCESS_TOKEN = 'SET_FB_ACCESS_TOKEN',
      SET_FB_USER_ID = 'SET_FB_USER_ID',
      SET_SELECTED_PUBLISHER = 'SET_SELECTED_PUBLISHER',
      SET_CURRENT_TIMESTAMP = 'SET_CURRENT_TIMESTAMP',
      SET_LAST_VISIBLE_PAST_FEED = 'SET_LAST_VISIBLE_PAST_FEED',
      SET_FEATURED_EVENTS_LIST = 'SET_FEATURED_EVENTS_LIST',
      SET_TODAYS_EVENTS_LIST = 'SET_TODAYS_EVENTS_LIST'

export default function reducer(state = initialState, action){
  switch( action.type ){
      case SET_USER:
        return {...state, authUser: action.payload}
              
      case SET_SELECTED_PUBLISHER:
        return {...state, selectedPublisher: action.payload}

      case SET_ACTIVE_FUTURE_FEED:
        return {...state, activeFutureFeed: action.payload}

      case SET_ACTIVE_PAST_FEED:
        return {...state, activePastFeed: action.payload}

      case SET_ACTIVE_EVENT:
        return {...state, activeEvent: action.payload}

      case SET_UPCOMING_FEEDS:
        return {...state, upcomingFeeds: action.payload}

      case SET_PAST_FEEDS:
        return {...state, pastFeeds: action.payload}
 
      case SET_SPONSOR_LIST_SELECTION:
        return {...state, sponsorListSelection: action.payload}
        
      case SET_FEED_LIST_SELECTION:
        return {...state, feedListSelection: action.payload}
              
      case SET_FB_ACCESS_TOKEN:
        return {...state, FBAcessToken: action.payload}
      
      case SET_FB_USER_ID:
        return {...state, FBUserId: action.payload}
      
      case SET_CURRENT_TIMESTAMP:
        return{...state, currentStatementTimestamp: action.payload }
      
      case SET_LAST_VISIBLE_PAST_FEED:
        return{...state, lastVisiblePastFeed: action.payload }
      
      case SET_FEATURED_EVENTS_LIST:
        return{...state, featuredEventsList: action.payload }
      
      case SET_TODAYS_EVENTS_LIST:
        return{...state, todaysEventsList: action.payload }

      default: return state
  }
}