import React from 'react'
import { Row, Col, Select, Input, Typography, Form } from 'antd'

const { Option } = Select

export default function AdSetDetails({ campaigns }){
  return(
    <Row style={adSetDetailsStyleProps}>
      <Col span={24}>
        <Typography.Title 
          level={3} 
          style={{ margin:0 }}
        >
          Ad Set Details
        </Typography.Title>
        <Typography.Text 
          type='secondary'
        >
          Define the parameters of the ad set
        </Typography.Text>
      </Col>
      <Col span={24} style={{marginTop:10}}>
        <Form.Item
          label={<Typography.Text strong>Ad Set Name</Typography.Text>}
          name="adSetName"
          rules={[
            {
              required: true,
              message: 'Please add an Ad Set Name',
            },
          ]}
        >
          <Input 
            placeholder='Ad Set Name'
          />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          label={<Typography.Text strong>Campaign</Typography.Text>}
          name="campaignId"
          rules={[
            {
              required: true,
              message: 'Please add an Ad Set Name',
            },
          ]}
        >
          <Select>
            {campaigns.map(campaign => {
              return <Option value={campaign.id} key={campaign.id}>{campaign.name}</Option>
            })}
          </Select>
        </Form.Item>
      </Col>

    </Row>
  )
}

const adSetDetailsStyleProps = {
  width:'100%',
  background:'white', 
  borderRadius:8, 
  boxShadow:'0px 1px 2px rgba(0,0,0,0.25)', 
  paddingTop: 15,
  paddingBottom: 20,
  paddingLeft: 20,
  paddingRight: 20,
}