import React, { useState } from 'react'
import { Col, Form, Row, Radio, Select, Button, Typography, Input } from 'antd';
import { getGender, getMinMaxAge } from '../../utils/helpers/adsManagerHelpers'

const { Option } = Select;
let minAgeArr = ['No Minimum']
let maxAgeArr = ['No Maximum']

for(let i=13; i < 66; i++){
  minAgeArr.push(i)
  maxAgeArr.push(i)
}

minAgeArr.push('65+')
maxAgeArr.push('65+')

export default function NewAdModal({ form, audiences }){
  const validateName = async (rule,value) =>{
    let minAge = form.getFieldValue('minAge')
    let maxAge = form.getFieldValue('maxAge')
    let gender = form.getFieldValue('gender')

    if(!value){
      if(gender !== 'all' || minAge || maxAge){
        throw new Error('Please input an audience name');
      }
    }
  }

  const validateAges = async (rule,value) => {
    let minAge = form.getFieldValue('minAge')
    let maxAge = form.getFieldValue('maxAge')
    
    if(rule.field === 'minAge'){
      if(value !== 'No Minimum' && maxAge && minAge > maxAge){
        throw new Error('Min age must be smaller than max age');
      }
      if(minAge < maxAge){
        form.setFields([
          {
            name:'maxAge',
            errors:null
          }
        ])
      }
    }else if(rule.field === 'maxAge'){
      if(value !== 'No Maximum' && minAge && minAge > maxAge){
        throw new Error('Max age must be larger than min age');
      }
      if(minAge < maxAge){
        form.setFields([
          {
            name:'minAge',
            errors:null
          }
        ])
      }
    }
  }

  const handleAudienceClick = (val) => {
    if(val !== 'new'){
      audiences.map(audience => {
        if(audience.id === val){
          
          console.log(audience)
          form.setFieldsValue(
            {
              audienceName:audience.name,
              gender:getGender(audience),
              minAge:getMinMaxAge('min',audience),
              maxAge:getMinMaxAge('max',audience),
            }
          )

        }
      })
    }
  }

  return(
    <Row style={adAudienceStyleProps}>
      <Row style={{width:'100%'}} justify='space-between' align='middle'>
        <Col>
          <Typography.Title
            level={4} 
            style={{
              margin:0
            }}
          >
            Ad Audience
          </Typography.Title>
          <Typography.Text
            type='secondary'
          >
            Who do you want to see your ad?
          </Typography.Text>
        </Col>
        <Col>
          <Select defaultValue="new" onChange={handleAudienceClick}>
              <Select.Option value={'new'}>New Audience</Select.Option>
              {audiences.map(audience => {
                return(
                  <Select.Option value={audience.id}>{audience.name}</Select.Option>
                )
              })}
            </Select>
        </Col>
      </Row>

      <Col span={24} style={{marginTop:20}}>
        <Col span={24}>
          <Form.Item 
            label={<Typography.Text strong>Audience Name</Typography.Text>}
            name="audienceName"
            rules={[{ validator:validateName }]}
          >
            <Input 
              placeholder='Audience Name'
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item 
            label={<Typography.Text strong>Gender</Typography.Text>}
            name="gender"
          >
            <Radio.Group
              optionType='button'
            >
              <Radio.Button value="all">All</Radio.Button>
              <Radio.Button value='male'>Male</Radio.Button>
              <Radio.Button value='female'>Female</Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col span={24} style={{display:'flex', alignItems:'center'}}>
          <Form.Item 
            label={<Typography.Text strong>Min Age</Typography.Text>}
            name="minAge"
            rules={[{validator:validateAges}]}
            >
            <Select style={{width:120}} >
              {minAgeArr.map(age => {
                return <Option key={age} value={age === '65+' ? 150 : age}>{age}</Option>
              })}
            </Select>
          </Form.Item>
          <Form.Item
            label={<Typography.Text strong>Max Age</Typography.Text>}
            name="maxAge"
            rules={[{validator:validateAges}]}
            >
              <Select style={{width:120}}>
                {maxAgeArr.map(age => {
                    return <Option key={age} value={age === '65+' ? 150 : age}>{age}</Option>
                  })}
              </Select>
          </Form.Item>
          <Button type='link' onClick={() => form.setFieldsValue({ minAge:'No Minimum', maxAge:'No Maximum' })}>Reset</Button>
      </Col>
      </Col>
    </Row>
  )
}

const adAudienceStyleProps = {
  marginTop:20,
  width:'100%',
  background:'white', 
  borderRadius:8, 
  boxShadow:'0px 1px 2px rgba(0,0,0,0.25)', 
  paddingTop: 15,
  paddingBottom: 20,
  paddingLeft: 20,
  paddingRight: 20,
}