import { DeleteTwoTone, ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Image, Modal, Row, Table } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CreateNewAdModal from '../../modals/AdManagerModals/CreateNewAdModal';
import { FirebaseContext } from '../../utils/Firebase';

const initialAdObjState = {
  active:false,
  name:'',
  rules:[],
  adSetId:'',
  feedId:'',
  callOutText: '',
  sponsorLogo: '',
  sponsorUrl: '',
  sponsorUrlType: 'link',
}

const { confirm } = Modal;

export default function Ads({ ads, selectedAdSets, setSelectedAds, selectedAds, adSets }) {
  const firebase = useContext(FirebaseContext)
  const publisherId = useSelector(
    (state) => state.publishing.publishingAs?.id ?? 0
  )
  const [ filteredAds, setFilteredAds ] = useState(ads)
  const [ modalOpen, setModalOpen ] = useState(false)
  const [ adObj, setAdObj ] = useState(initialAdObjState)

  console.log('ads', ads)

  const columns = [
    // {
    //   title: 'On/Off',
    //   dataIndex: 'active',
    //   key: 'active',
    //   // align: 'right',
    //   width:40,
    //   render: (text, record) => (
    //     <Switch size='small' checked={record.active} onChange={() => handleChange(record)}/>
    //   ),
    // },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      // render: (text, record) => {
      //   return <Typography.Link onClick={() => handleTableRowClick(record)}>{text}</Typography.Link>
      // }
    },
    {
      title: 'CTA',
      dataIndex: 'callOutText',
      key: 'callOutText',
      // align: 'right'
    },
    {
      title: 'Ad Thumbnail',
      dataIndex: 'sponsorLogo',
      key: 'sponsorLogo',
      // align: 'middle',
      width: 115,
      render: (text) => (
        <Col style={{width:50, height: 50}} >
          <Image src={text}/>
        </Col>
      )
    },
    {
      title: 'Ad Type',
      dataIndex: 'sponsorUrlType',
      key: 'sponsorUrlType',
      width: 115,
      // align: 'right'
    },
    {
      title: 'Triggers',
      dataIndex: 'rules',
      key: 'rules',
      // align: 'right'
    },
    {
      title: 'Audience',
      dataIndex: ['audience', 'name'],
      key: ['audience', 'name'],
      // align: 'right'
    },
    {
      title: 'Event Type',
      dataIndex: ['adSet','eventTypes'],
      key: 'eventTypes',
      // align: 'right'
    },
    {
      title: 'Feeds',
      dataIndex: 'feeds',
      key: 'eventTypes',
      render: val => {
        if(val){
          return val.map(feedObj => feedObj.title)
        }
      }
      // align: 'right'
    },
    // {
    //   title: 'Ad Route',
    //   dataIndex: 'sponsorUrl',
    //   key: 'sponsorUrl',
    //   // align: 'right'
    // },

    // {
    //   title: 'Ad Set Name',
    //   dataIndex: 'adSetId',
    //   key: 'adSetId',
    //   // align: 'right' 
    // },
    // {
    //   title: 'Budget',
    //   dataIndex: 'budget',
    //   key: 'budget',
    //   align: 'right'
    // },
    // {
    //   title: 'Results',
    //   dataIndex: 'campaignResults',
    //   key: 'campaignResults',
    //   align: 'right',
    // },
    // {
    //   title: 'Reach',
    //   dataIndex: 'campaignReach',
    //   key: 'campaignReach',
    //   align: 'right',
    // },
    // {
    //   title: 'Impressions',
    //   dataIndex: 'campaignImpressions',
    //   key: 'campaignImpressions',
    //   align: 'right',
    // },
    // {
    //   title: 'Cost Per Result',
    //   dataIndex: 'campaignCostPerResult',
    //   key: 'campaignCostPerResult',
    //   align: 'right',
    // },
    // {
    //   title: 'Engagement Ranking',
    //   dataIndex: 'campaignCostPerResult',
    //   key: 'campaignCostPerResult',
    //   align: 'right',
    // },
    // {
    //   title: 'Amount Spent',
    //   dataIndex: 'spent',
    //   key: 'spent',
    //   align: 'right',
    // },
    // {
    //   title: 'End Date',
    //   dataIndex: 'campaignEndDate',
    //   key: 'campaignEndDate',
    //   align: 'right',
    // },
    {
      title: '',
      key: 'action',
      align: 'right',
      width:96,
      render: (text, record) => (
        <Row>
          {/* <Button type='text' onClick={()=>handleEditAd(record)} icon={<EditTwoTone/>}/> */}
          <Button type='text' danger onClick={()=>handleDeleteAd(record)} icon={<DeleteTwoTone twoToneColor='red'/>}/>
        </Row>
      ),
    },
  ]

  useEffect(()=>{
    if(selectedAdSets.length < 1){
      setFilteredAds(ads)
    }else{
      let filteredArr = []
      selectedAdSets.map(adSetDoc => {
        let results = ads.filter(adDoc => {
          return adSetDoc.id === adDoc.adSetId
        })
        // console.log('results',results)
        results.map(filteredAd => {
          filteredArr.push(filteredAd)
        })
      })
      setFilteredAds(filteredArr)
    }
  },[selectedAdSets, ads])

  const handleTableRowClick = record => {
    const index = selectedAds.findIndex(adDoc => adDoc.id === record.id)
    if(index === -1){
      setSelectedAds([...selectedAds, record])
    }
  }

  const handleChange = (record) => {
    const query = firebase.getCollection('publisher').doc(publisherId).collection('ads').doc(record.id)
    query.update({active: !record.active})
  }

  const handleCheck = (selectedRowKeys, selectedRows) => {
    setSelectedAds(selectedRows)
  }

  const handleModalCancel = () => {
    setAdObj(initialAdObjState)
    setModalOpen(false)
  }

  const handleEditAd = (record) => {
    // console.log('record', record)
    setAdObj(record)
    setModalOpen(true)
  }

  const handleDeleteAd = async (record) => {
    confirm({
      title: `Are you sure you want to delete this ad?`,
      icon: <ExclamationCircleOutlined />,
      content: 'This cannot be undone.',
      okText:'Yes',
      onOk() {
        firebase.getCollection('publisher').doc(publisherId).collection('ads').doc(record.id).delete()
      },
      onCancel() {
        // console.log('Cancel');
      },
    });
  }

  return(
    <Row>
      <Col span={24}>
        <Row justify="space-between" align="middle">
          <Button type='primary' icon={<PlusOutlined/>} onClick={()=>setModalOpen(true)}>Create New Ad</Button>
        </Row>
      </Col>
      <Col span={24} style={{marginTop: 20}}>
        <Table 
          rowSelection={{
            type:'checkbox',
            onChange: (selectedRowKeys, selectedRows) => handleCheck(selectedRowKeys, selectedRows),
          }}
          columns={columns} 
          dataSource={filteredAds} 
          rowKey="id"
          size='small'
        />
      </Col>
      <Modal
        title='New Ad'
        visible={modalOpen}
        centered
        onCancel={handleModalCancel}
        width={800}
        bodyStyle={{background:'#f0f2f5'}}
        destroyOnClose
        footer={[
          <Row justify='end'>
            <Button onClick={handleModalCancel}>Cancel</Button>
            <Button type='primary' form='newAdForm' htmlType='submit'>Submit</Button>
          </Row>
        ]}
      >
        <CreateNewAdModal
          adObj={adObj}
          setAdObj={setAdObj}
          adSets={adSets}
          setModalOpen={setModalOpen}
        />
      </Modal>
    </Row>
  )
}
