import { Col, Form, Input, Row } from 'antd';
import React, { useState } from 'react';

import IconUploader from '../../components/IconUploader/IconUploader';
import AttachAdToPrompt from '../../Pages/Publisher/PromptCreator/AttachAdToPrompt';
import AttachScheduleToPrompt from '../../Pages/Publisher/PromptCreator/AttachScheduleToPrompt';

function EditPromptModal({ prompt, setPrompt, handleDelete }){
  const [ form ] = Form.useForm()
  const [ img, setImg ] = useState()
  
  const handleStatementChange = (e) => {
    e.preventDefault()
    setPrompt({...prompt, text:e.target.value})
  }

  const handleResponseChange = (e) => {
    e.preventDefault()
    let responses = [...prompt.responses]
    responses[e.target.name] = e.target.value
    setPrompt({...prompt, responses: responses})
  }

  return(
    <Row  style={{width:'100%'}}>
        <Col span={24} style={{padding: '20px 50px'}}>
          <Form
            form={form}
            name="control-ref" 
            style={{width:'100%'}}
            layout='vertical'
          >
            <Form.Item
              label="Statement"
            >
              <Input 
                bordered={false}
                style={{borderBottom:'1px solid rgba(0,0,0,0.1'}} 
                placeholder='Enter your statement'
                suffix={<IconUploader setFile={setImg}/>}
                value={prompt.text}
                onChange={handleStatementChange}
              />
            </Form.Item>
            {prompt.responses.map((response,i) => {
              return(
                <Form.Item
                  label="Response"
                >
                  <Input 
                    bordered={false}
                    style={{borderBottom:'1px solid rgba(0,0,0,0.1'}} 
                    placeholder='No Response'
                    value={response}
                    name={i}
                    onChange={handleResponseChange}
                    autoComplete='off'
                  />
                </Form.Item>
              )
            })}
          </Form>
          <AttachAdToPrompt       
            prompt={prompt}
            setPrompt={setPrompt}
          />
          <AttachScheduleToPrompt
            prompt={prompt}
            setPrompt={setPrompt}
            handleDelete={handleDelete}
          />
        </Col>
    </Row>
  )
}

export default EditPromptModal
