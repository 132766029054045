import {
  SET_PAST_FEEDS_LIST,
  SET_PAST_FEEDS_LIST_SUCCESS,
  SWITCH_ACTIVE_EVENT,
  SWITCH_ACTIVE_EVENT_SUCCESS,
  SWITCH_CURRENT_FEED,
  SWITCH_CURRENT_FEEDS_LIST,
  SWITCH_CURRENT_FEEDS_LIST_SUCCESS,
  SWITCH_CURRENT_FEED_SUCCESS,
  SWITCH_PAST_FEED,
  SWITCH_PAST_FEED_SUCCESS,
  SWITCH_PUBLISHING_AS,
  SWITCH_PUBLISHING_AS_SUCCESS
} from '../constants';

export const switchPublishingAs = (user) => {
  return {
  type: SWITCH_PUBLISHING_AS,
  payload: {user},
}};

export const switchPublishingAsSuccess = (publisher) => ({
  type: SWITCH_PUBLISHING_AS_SUCCESS,
  payload: publisher,
});

export const switchCurrentFeed = (feed) => {
  return {
  type: SWITCH_CURRENT_FEED,
  payload: { feed },
}};

export const switchCurrentFeedSuccess = (feed) => ({
  type: SWITCH_CURRENT_FEED_SUCCESS,
  payload: feed,
});

export const switchPastFeed = (feed) => ({
  type: SWITCH_PAST_FEED,
  payload: { feed },
});

export const switchPastFeedSuccess = (feed) => ({
  type: SWITCH_PAST_FEED_SUCCESS,
  payload: feed,
});

export const setPastFeedsList = (feedsArr) => ({
  type: SET_PAST_FEEDS_LIST,
  payload: { feedsArr },
});

export const setPastFeedsListSuccess = (feedsArr) => ({
  type: SET_PAST_FEEDS_LIST_SUCCESS,
  payload: feedsArr,
});

export const setCurrentFeedsList = (feedsArr, publisherId) => ({
  type: SWITCH_CURRENT_FEEDS_LIST,
  payload: { feedsArr, publisherId },
})

export const setCurrentFeedsListSuccess = (feedsArr) => ({
  type: SWITCH_CURRENT_FEEDS_LIST_SUCCESS,
  payload: feedsArr,
});

export const switchActiveEvent = (event) => ({
  type:SWITCH_ACTIVE_EVENT,
  payload: {event},
});

export const switchActiveEventSuccess = (event) => ({
  type:SWITCH_ACTIVE_EVENT_SUCCESS,
  payload: event,
});

// export const setActivePastFeed = (feed) => ({
//   type: SET_ACTIVE_PAST_FEED,
//   payload: { feed },
// });

// export const setActivePastFeedSuccess = (feed) => ({
//   type: SET_ACTIVE_PAST_FEED_SUCCESS,
//   payload: { feed },
// });
