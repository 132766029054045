import { 
  getCurrentFeedFromLocalStorage, 
  getPastFeedFromLocalStorage, 
  getFromLocalStorage, 
  getPastFeedsListFromLocalStorage, 
  getCurrentFeedsListFromLocalStorage
} from '../../helpers/Utils';
import {
  SWITCH_PUBLISHING_AS_SUCCESS,
  SWITCH_CURRENT_FEED_SUCCESS,
  SWITCH_PAST_FEED_SUCCESS,
  SET_PAST_FEEDS_LIST_SUCCESS,
  SWITCH_CURRENT_FEEDS_LIST_SUCCESS,
  SWITCH_ACTIVE_EVENT_SUCCESS,
} from '../constants';

const INIT_STATE = {
  publishingAs: getFromLocalStorage('opine_publishing_as'),
  activeCurrentFeed: getCurrentFeedFromLocalStorage(),
  activeEvent:{},
  activePastFeed: getPastFeedFromLocalStorage(),
  pastFeedsList: getPastFeedsListFromLocalStorage(),
  currentFeedsList: getCurrentFeedsListFromLocalStorage()
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SWITCH_PUBLISHING_AS_SUCCESS:
      return {
        ...state,
        loading: false,
        publishingAs: action.payload,
        error: '',
      };
    case SWITCH_CURRENT_FEED_SUCCESS:
      return {
        ...state,
        loading: false,
        activeCurrentFeed: action.payload,
        error: '',
      };
    case SWITCH_PAST_FEED_SUCCESS:
      return {
        ...state,
        loading: false,
        activePastFeed: action.payload,
        error: '',
      };
    case SWITCH_ACTIVE_EVENT_SUCCESS:
      return {
        ...state,
        loading: false,
        activeEvent: action.payload,
        error: '',
      };
    case SET_PAST_FEEDS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        pastFeedsList: action.payload,
        error: '',
      };
    case SWITCH_CURRENT_FEEDS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        currentFeedsList: action.payload,
        error: '',
      };
    
    default:
      return { ...state };
  }
};
