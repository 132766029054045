import React, { useState } from 'react';
import '../AppBar.scss';
import { useHistory, Link } from 'react-router-dom';

// REDUX
import { useSelector, useDispatch, connect } from 'react-redux';

// MATERIAL UI
import { Button, Menu, Avatar, Dropdown, Modal } from 'antd';

// COMPONENTS
import SwitchUserModal from '../../../modals/SwitchUserModal/SwitchUserModal'
import { logoutUser } from '../../../redux/auth/actions';

function UserAppBarRight({ logoutUserAction }){
  const history = useHistory()
  const dispatch = useDispatch()
  const state = useSelector(state => state)
  const authUser = useSelector(state => state.publishing.publishingAs)
  const [ modalOpen, setModalOpen ] = useState(false)

  const emailFirstLetter = () => {
    if(authUser.email){
      let split = authUser.email.split('')
      return split[0]
    } else {
      return 'X'
    }
  }

  const handleSignOut = () => {
    logoutUserAction(history)
    dispatch({type:'SET_USER', payload:null})
    dispatch({type:'SET_ACTIVE_EVENT', payload:null})
    dispatch({type:'SET_ACTIVE_FUTURE_FEED', payload:null})
    dispatch({type:'SET_ACTIVE_PAST_FEED', payload:null})
    dispatch({type:'SET_UPCOMING_FEEDS', payload:null})
    dispatch({type:'SET_PAST_FEEDS', payload:null})
    // firebase.auth.signOut()
    // history.push('/signin')
  }

  const nameDisplay = (user) => {
    if(user === 'Unregistered User'){
      return 'Unregistered User'
    } else {
      return authUser.name ? authUser.name : authUser.email
    }
  }

  const handleMenuClick = e => {
    if(e.key === 'signOut'){
      handleSignOut()
    }else{
      setModalOpen(true)
    }
  }

  const handleModalCancel = () => {
    setModalOpen(false)
  }

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key='switchUser'>Switch Users</Menu.Item>
      <Menu.Item key='signOut'>Sign Out</Menu.Item>
    </Menu>
  )

  return(
    <div className='app-bar-right'>
      {/* <div className='search'>
        <SearchIcon />
        <input placeholder='Search feeds, posts or help' aria-describedby="Search"></input>
      </div>
      <div className='app-bar-icon-container'>
        <div id="app-bar-icons">
          <Notification />
        </div>
      </div> */}
      {authUser != null ? 
        <Dropdown overlay={menu} placement='bottomRight' arrow>
          <div>
            <Button type='text' size='large'>{nameDisplay(authUser)}</Button>
            {authUser.profilePic ? 
              <Avatar src={authUser.profilePic} alt={authUser.email}/> 
            : 
              <Avatar>{emailFirstLetter()}</Avatar>}
          </div>
        </Dropdown>
      :
        <Link to={'/signin'}><Button>Sign In</Button></Link>
      }
      <Modal
        title={'Switch User'}
        visible={modalOpen}
        centered
        // onOk={handleModalSubmit}
        onCancel={handleModalCancel}
        width={650}
        footer={null}
        destroyOnClose
      >
        <SwitchUserModal setModalOpen={setModalOpen}/>
      </Modal>
    </div>
  )
}

export default connect(null, {
  logoutUserAction: logoutUser,
})(UserAppBarRight)