import React, { useState } from 'react'
import { Layout } from 'antd'
import AppBar from '../components/AppBar/AppBar';
import NavMenu from '../components/NavMenu/NavMenu'

const { Header, Content, Sider } = Layout;


const AuthorizedLayout = ({children}) => {
  const [ collapsed, setCollapsed ] = useState(true)

  return (
    <Layout style={{height:'100vh'}}>
      <Header style={{ zIndex: 1, width: '100%', padding:0}}>
        <AppBar/>
      </Header>
      <Layout>
        <Sider
          width={200} 
          collapsible 
          collapsed={collapsed}
          onCollapse={()=>setCollapsed(!collapsed)}
          theme='light'
        >
          <NavMenu />
        </Sider>
        <Layout>
          <Content
            style={{
              // margin: 0,
              height:'100%',
              minWidth: '1200px',
              overflowY: 'scroll'
            }}
          >
            {children}
          </Content>
        </Layout>
      </Layout>
    </Layout>
  )
}

export default AuthorizedLayout