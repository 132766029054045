import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import OpineLogo from '../../assets/images/OpineLogo.svg'
import { Button, Row, Col, Form, Input, Typography, Image, Result } from 'antd';
import { FirebaseContext } from '../../utils/Firebase';
import { UserOutlined } from '@ant-design/icons';

export default function ForgotPassword(){
  const history = useHistory()
  const [ email, setEmail ] = useState(false)
  const [ emailSent, setEmailSent ] = useState(false)
  
  return(
    emailSent 
      ? 
        <Result
          status="success"
          title={`Success!`}
          subTitle={`Password reset email sent to ${email}.`}
          extra={[
            <Button type="primary" key="signIn" onClick={() => history.push('/signin')}>
              Return to Sign In
            </Button>,
            <Button key="close" onClick = {() => setEmailSent(false)}>Close</Button>,
          ]}
        />
      :
        <Row 
          align='middle'
          justify='center'
          style={{
            height:'100%',
          }}
        >
          <Col
            style={{
              background: 'white',
              padding: '4em',
              width: 500,
            }}
          >
            <Image width={100} src={OpineLogo} preview={false} style={{marginBottom:40}}/>
            <Typography.Title style={{lineHeight:0}}>Uh oh!</Typography.Title>
            <Typography.Title level={5} style={{marginBottom: 25}}>Please Enter the Email associated with your account</Typography.Title>
            <Row gutter={[8,8]}>
              <Col span={24}><ForgotPasswordForm setEmailSent={setEmailSent} email={email} setEmail={setEmail}/></Col>
            </Row>
          </Col>
        </Row>
  )
}

function ForgotPasswordForm({setEmailSent, email, setEmail}){
  const firebase = useContext(FirebaseContext)
  const [form] = Form.useForm();
  const [ emailValidation, setEmailValidation ] = useState({
    status: null,
    message: null
  })

  const onFinish = () => {
    firebase.auth.sendPasswordResetEmail(email)
      .then(() => {
        console.log('success!');
        // setEmail(null);
        form.resetFields()
        setEmailValidation({
          status:null,
        })
        setEmailSent(true)
      })
      .catch(error => {
        console.log('firebase error', error)
        setEmailValidation({
          status:'error',
          message:error.message
        })
      });
  }

  const onFinishFailed = async (errorInfo) => {
    setEmailValidation({
      status:'error',
      message: errorInfo.errorFields[0].errors[0]
    })
  }

  const handleEmailChange = async () => {
    try {
      const values = await form.validateFields(['email']);
      setEmail(values.email)
      setEmailValidation({
        status:'success'
      })
    } catch (errorInfo) {
      setEmailValidation({
        status:'error',
        message: errorInfo.errorFields[0].errors[0]
      })
    }
  }
  return(
    <Form
      form={form}
      name="resetPasswordForm"
      className="login-form"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      layout='vertical'
    >
      <Form.Item
        // label="Email"
        name="email"
        rules={[
          {
            type: 'email',
            message: 'Please enter a valid E-mail',
          },
          {
            required: true,
            message: 'E-mail is required',
          },
        ]}
        validateStatus={emailValidation.status}
        help={emailValidation.message}
        hasFeedback
      >
        <Input 
          autoComplete='off' 
          placeholder="Email" 
          prefix={<UserOutlined/>} 
          value={email}
          defaultValue={null}
          onChange={handleEmailChange}
        />
      </Form.Item>

      <Form.Item>
        <Row gutter={[16,16]}>
          <Col span={24}>
            <Button type="primary" htmlType="submit" block>
              Submit
            </Button>
          </Col>
        </Row>
      </Form.Item>
    </Form>
  )
}