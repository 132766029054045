import { Form, Row, message } from 'antd';
import React from 'react';

import { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import AdCreative from '../../components/NewAdComponents/AdCreativeComponentV2';
import AdDetails from '../../components/NewAdComponents/AdDetailsComponent';
import AdSelectRules from '../../components/NewAdComponents/AdSelectRulesComponent';
import { FirebaseContext } from '../../utils/Firebase';

export default function CreateNewAdModal({ adObj, setAdObj, adSets, setModalOpen }){
  const [form] = Form.useForm()
  const firebase = useContext(FirebaseContext)
  const publisherId = useSelector(
    (state) => state.publishing.publishingAs?.id ?? 0
  )
  const [ triggers, setTriggers ] = useState([])

  const handleFormSubmit = async (formData) => {
    console.log(formData)
    let newAdObj = {...formData}
    let adSet = adSets.filter(adSet => adSet.id === newAdObj.adSetId)
    if(adSet[0].feeds.length > 0){
      newAdObj = {...newAdObj, feedId: adSet[0].feeds[0].id}
    }else{
      newAdObj = {...newAdObj, feedId: 'n/a'}
    }

    let url = await firebase.handleFirebaseImgUpload(newAdObj.sponsorLogo)
    newAdObj = {...newAdObj, sponsorLogo:url}
    firebase.getCollection('publisher').doc(publisherId).collection('ads')
      .add(newAdObj)
      .then( async docRef => {

        // Promise.all(await newAdObj.rules.map(ruleId => {

        //   firebase.getCollection('assigned_sponsors')
        //   .add({
        //     enginePromptId:newAdObj.rules,
        //     feedId:newAdObj.feedId,
        //     ads:[
        //       {
        //         // sponsorName: newAdObj.name,
        //         callOutText: newAdObj.callOutText,
        //         sponsorUrlType: newAdObj.sponsorUrlType,
        //         sponsorUrl:newAdObj.sponsorUrl,
        //         sponsorLogo:newAdObj.sponsorLogo,
        //         // filterSet: [
        //         //   {
        //         //     filters: selectedAdSet.filters
        //         //   },
        //         // ],
        //       }
        //     ]
        //   })
        //   .then(docRef => {
            docRef.update({id:docRef.id})
            setModalOpen(false)
            message.success('Ad Set Created Successfully!')
        //   })
        //   .catch(err => {
        //     console.log('error:', err)
        //     message.warning('Error Creating New Ad, please try again.')
        //   })
        // }))


      })
      .catch(err => {
        console.log('error:', err)
        message.warning('Error Creating New Ad, please try again.')
      })
  }

  const handleSubmitFailed = () => {

  }

  return(
    <Row gutter={[16,16]}>
      <Form
        form={form}
        id='newAdForm'
        name="newAdForm" 
        onFinish={handleFormSubmit}
        onFinishFailed={handleSubmitFailed}
        style={{width:'100%'}}
        layout='vertical'
        initialValues={{
          
        }}
      >
        <AdDetails
          adSets={adSets}
          form={form}
          setTriggers={setTriggers}
        />
        <AdCreative
          adObj={adObj}
          setAdObj={setAdObj}
          form={form}
        />
        <AdSelectRules
          form={form}
          triggers={triggers}
        />
      </Form>
    </Row>
  )
}

