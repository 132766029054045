/* globals window */
import { message } from 'antd';
import moment from 'moment';
import zipcodes from 'zipcodes';
import constants from '../../constants';
import { deconstructFilters } from '../../filtersUtil';
import { getStateName, states } from '../../geoUtils';
import { formatTimestamp } from '../../helpers/Utils';
// import { useDispatch } from 'react-redux'

export default class Firebase {
  get auth() {
    return window.firebase.auth();
  }

  get firestore() {
    return window.firebase.firestore();
  }

  get functions() {
    return window.firebase.functions();
  }

  get storage() {
    return window.firebase.storage();
  }

  get firestoreRoot() {
    return this.firestore.collection('versions').doc(constants.FIREBASE.VERSION);
  }

  get googleProvider() {
    return new window.firebase.auth.GoogleAuthProvider();
  }

  get facebookProvider() {
    return new window.firebase.auth.FacebookAuthProvider();
  }

  get twitterProvider() {
    return new window.firebase.auth.TwitterAuthProvider();
  }

  get appleProvider() {
    return new window.firebase.auth.OAuthProvider('apple.com');
  }

  getSentimentRefByEventId(eventId) {
    return this.firestoreRoot.collection('sentiment').doc(eventId);
  }

  getAvgSentimentHistoryRefByEventId(eventId) {
    const sentimentRef = this.firestoreRoot.collection('sentiment');

    return sentimentRef.doc(eventId).collection('avgSentimentHistory');
  }

  getVideoCalloutsRefByEventId(eventId) {
    const sentimentRef = this.firestoreRoot.collection('sentiment');

    return sentimentRef.doc(eventId).collection('videoCallouts');
  }

  getPromptsRefByEventId(eventId) {
    const promptsRef = this.firestoreRoot.collection('prompts');

    return promptsRef.where('eventId', '==', eventId);
  }

  getResponsesRefByPromptId(promptId) {
    const responsesRef = this.firestoreRoot.collection('responses');

    return responsesRef.where('promptId', '==', promptId);
  }

  flattenSnapshot(snapshot) {
    return snapshot.docs.map(doc => ({ __id: doc.id, ...doc.data() }));
  }

  createTimestamp({ seconds, nanoseconds } = {}) {
    return new window.firebase.firestore.Timestamp(seconds, nanoseconds);
  }

  getPromptsRefByFeedId(feedId) {
    const promptsRef = this.firestoreRoot.collection('prompts');

    return promptsRef.where('feedId', '==', feedId);
  }

  getUserInfoByUserId(userId) {
    return this.firestoreRoot.collection('users').doc(userId)
  }

  getCollection = (collection) => {
    return this.firestoreRoot.collection(collection)
  }

  getDoc = (collection, doc) => {
    return this.firestoreRoot.collection(collection).doc(doc)
  }

  getPublisherDoc = (publisherId) => {
    return this.firestoreRoot.collection('publisher').doc(publisherId).get().then(docRef => {
      if(docRef.exists){
        return docRef.data()
      }
    })
  }

  buildOpineUser = async (uid) => {
    // console.log('find user', uid)

    return this.firestoreRoot.collection('publisher').doc(uid)
      .get()
      .then(docRef => {
        if(docRef.exists){
          let userDoc = docRef.data()
          // const { 
          //   authorizedPublisherIds, 
          //   description,
          //   name,
          //   profilePic,
          //   roles,
          //   lastPublisherUsed,
          //   lastUsedCurrentFeed,
          //   lastUsedPastFeed
          // } = userDoc
          // let user = {
          //   id: userDoc.id,
          //   authorizedPublisherIds, 
          //   description,
          //   name,
          //   profilePic,
          //   roles,
          //   lastPublisherUsed,
          //   lastUsedCurrentFeed,
          //   lastUsedPastFeed
          // }
          // console.log('user', user)
          return {...userDoc, id:userDoc.id}
        } else {
          return false
        }
      })
      .catch(err => {
        console.log('error!', err)
      })
  }

  getTopPrompts = (publisherId) => {
    return this.firestoreRoot.collection('prompts')
      .where('publisherId', '==', publisherId)
      .orderBy('responseCount', 'desc')
      .limit(3)
      .get()
      .then(snap => {
        let docArr = []
        snap.forEach(docRef => {
          let doc = docRef.data()
          const firstLetter = doc.text.split('')[0]
          doc = {...doc, id:docRef.id, firstLetter}
          docArr.push(doc)
        })
        return docArr
      })
  }

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email, password) => this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithGoogle = () => this.auth.signInWithPopup(this.googleProvider);

  doSignInWithFacebook = () => this.auth.signInWithPopup(this.facebookProvider);

  doSignInWithTwitter = () => this.auth.signInWithPopup(this.twitterProvider);

  doSignInWithApple = () => this.auth.signInWithPopup(this.appleProvider);

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

  doPasswordUpdate = password => this.auth.currentUser.updatePassword(password);

  handleFirebaseImgUpload = async (image) => {
    let randomName = function makeid(length) {
      var result           = '';
      var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for ( var i = 0; i < length; i++ ) {
         result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    }
    let time = Date.now()
    let name = randomName(50)+time

    let uploadTask = this.storage.ref(`/v1/feeds/prompt_pics/${name}.jpg`)
    message.loading('Uploading Image...', 0)
    const uploadTaskSnapshot = await uploadTask.putString(image, 'data_url')
    const downloadURL = await uploadTaskSnapshot.ref.getDownloadURL()
    message.destroy()
    // message.success('Image Uploaded Successfully')
    return downloadURL
  };

  deleteFromFirebaseStorage = async (metaData, setUrl) => {
    const mediaRef = this.storage.ref(metaData.fullPath)
    mediaRef
      .delete()
      .then(() => {
        setUrl()
        return message.success(`Image Removed`)
      })
      .catch(err => {
        console.log('Error: ', err)
        return message.error(`There was an error removing this Image`)
      })
  }

  createNewRegularFeed = async (event, newFeedName, newFeedDescription, newFeedImg, publisherId) => {
    if(!event || !newFeedName || !newFeedDescription || !publisherId || !newFeedImg){
      message.error('Please Fill Out All Fields')
      return false
    } 
    let parsedEventName

      if(event.parties.length > 1) {
        parsedEventName = `${event.parties[0].alias} vs ${event.parties[1].alias}`
      } else {
        parsedEventName = event.parties[0].name
      }

      let feedImg = await this.handleFirebaseImgUpload(newFeedImg)

      let data = {
        description: newFeedDescription,
        eventId: event.id,
        eventLeauge: event.league,
        eventName: parsedEventName,
        eventScheduledTime: event.scheduledTime,
        eventStatus: event.status,
        eventType: event.type,
        picture: feedImg,
        publisherId: publisherId,
        title: newFeedName,
      }

      return this.getCollection('feeds')
        .add(data)
        .then(ref => {
          return this.getCollection('feeds').doc(ref.id).update({id: ref.id})
            .then(() => {
              console.log('Doc Succesfully Updated')
              return ref.get()
                .then(docRef => {
                  return docRef.data()
                })
            })
          })
        .catch(err => {
          console.log('error:', err)
        })
    }

  createSponsor = async (sponsorName, sponsorImg, sponsorUrl, publisherId) => {
    if(!sponsorName || !sponsorImg || !sponsorUrl){
      return message.error('Please fill out all fields')
    }

    let sponsorImgUrl = await this.handleFirebaseImgUpload(sponsorImg, message)

    let sponsorObj = {
      publisherId: publisherId,
      sponsorLogo: sponsorImgUrl,
      sponsorName: sponsorName,
      sponsorUrl: sponsorUrl,
      sponsorUrlType: 'link'
    }
    return this.getCollection('sponsors').add(sponsorObj)
      .then(ref=>{
        return this.getCollection('sponsors').doc(ref.id).update({sponsorId: ref.id})
          .then(()=>{
            message.success('New Sponsor Created')
            return ref.get()
              .then(docRef=>{
                return docRef.data()
              })
          })
      })
  }

  getPublisherResponseCountByState = (publisherId) => {
    return this.firestoreRoot.collection('responses')
      .where('publisherId', '==', publisherId)
      .get()
      .then(snap => {
        const handleSnapshot = this.flattenSnapshot(snap)
        const zipArr = []

        handleSnapshot.forEach(resObj => {
          if(!resObj.zipCode){
            zipArr.push('No State Data')
          } else {
            zipArr.push(resObj.zipCode)
          }
        })

        for(let zip of zipArr){
          if(zip !== 'No State Data'){
            let state
            if(zip.split('').length === 5){

              state = zipcodes.lookup(zip)
            }
            zip = state.state
          }
          states[zip] = (states[zip] || 0) + 1
        }

      let outputArr = []
      for(let [key, value] of Object.entries(states)){
        let fullState = getStateName(key)
        outputArr.push({state: key, count: value, fullState})
      }
      // console.log('outputArr',outputArr)
      return outputArr
    })
  }

  getAllEventBroadcasterDocs = async (allBroadcasters) => {
    if(allBroadcasters !== 'Unknown'){
      let broadcasterArr = []
      await Promise.all(allBroadcasters.map(async broadcaster => {
        await this.firestoreRoot.collection('broadcasters')
          .where('broadcaster', '==', broadcaster)
          .get()
          .then(snapshot => {
            snapshot.forEach(docRef => {
              if(docRef.exists){
                broadcasterArr.push(docRef.data())
              }
            })
          })
          .catch(err => console.log('error:', err))
        }))
        return broadcasterArr[0]
      }
  }

  getUpcomingEvents = async (values,queryType,queryValue,limit,endArg,setEvents,setIsFetchingEvents) => {
    const start = moment().add(values,'day').startOf('day').utc().format()
    const end = moment(start).add(endArg,'day').startOf('day').utc().format()

    return await this.firestoreRoot.collection('events')
      .where(queryType, '==', queryValue)
      .where('scheduledTime', '>=', start)
      .where('scheduledTime', '<', end)
      .orderBy('scheduledTime', 'asc')
      .limit(limit)
      .onSnapshot(async snapshot => {
        let eventArr = []
        snapshot.forEach(async snap => {
          let doc = snap.data()
          if(doc.status === 'unnecessary' || doc.status === 'postponed' || doc.status === 'closed' || doc.status === 'ended' || doc.status === 'abondoned' || doc.status === 'final' || doc.status === 'cancelled') return
          let formattedTimestamp = formatTimestamp(doc.scheduledTimestamp)
          let formattedDoc = {...doc, id:snap.id, formattedTimestamp }
          eventArr.push(formattedDoc)
        })
        // GET BROADCASTER DOCS FROM COLLECTION
        let broadcasterFormattedEventArr = await Promise.all(eventArr.map(async doc => {
         return await this.getAllEventBroadcasterDocs(doc.allBroadcasters)
          .then(res=>{
            if(res){
              doc = {...doc, allBroadcasters:res}
            }else {
              doc = {...doc, allBroadcasters: []}
            }
            return doc
          }).catch(err => console.log(err))
        }))
        // GET AGGREGATE HUDDLE COUNT FOR EACH EVENT
        let eventArrWithHuddleCount = await Promise.all(broadcasterFormattedEventArr.map(async doc=>{
          return await this.getEventHuddleCount(doc)
          .then(res => {
            if(res){
              doc = {...doc, huddleCount: res.huddleCount}
            }else{
              doc = {...doc, huddleCount: 0}
            }
            return doc
          }).catch(err => console.log(err))
        }))
        setEvents(eventArrWithHuddleCount)
        setIsFetchingEvents(false)
      },err => console.log(err))
  }

  getEventHuddleCount = async (event) => {
    let feedArr =[]
    await this.firestoreRoot.collection('feeds')
      .where('eventId', '==', event.id)
      .get()
      .then(snap => {
        snap.forEach(snap => {
          let feed = snap.data()
          feedArr.push(feed)
        })
      })

      let mappedFeeds = feedArr.map(async feed => {
      const feedHuddles = []

      await this.firestoreRoot.collection('feeds').doc(feed.id).collection('huddles')
      .get()
      .then(snap => {
        snap.forEach(docRef => {
          feedHuddles.push(docRef.data())
        })
      })

      let huddleCount = 0
      if(feedHuddles.length > 0){
        huddleCount = feedHuddles.length
      } 

      return {huddleCount}
    })
    let res = await Promise.all(mappedFeeds)
    return res[0]
  }

  makeFeatured = async (docId, setFeaturedLoading) => {
    setFeaturedLoading(true)
    return this.firestoreRoot.collection('events').doc(docId)
      .update({
        featured: true
      })
      .then(() => {
        setFeaturedLoading(false)
        console.log(`Document ${docId} Successfully updated.`)
        return 'success'
      })
  }

  unfeature = (docId, setFeaturedLoading) => {
    setFeaturedLoading(true)

    this.firestoreRoot.collection('events').doc(docId)
      .update({
        featured: false
      })
      .then(() => {
        setFeaturedLoading(false)
        console.log(`Document ${docId} Successfully updated.`)
      })
  }

  subscribeToAudiences = async ( publisherId, setAudienceSets ) => {
    return await this.firestoreRoot.collection('publisher').doc(publisherId).collection('audiences')
    .onSnapshot(snap => {
        let audienceArr = []
        snap.forEach(docRef => {
          let doc = docRef.data()
          audienceArr.push(deconstructFilters(docRef.id, doc.name, doc.filters,))
        })
        audienceArr.sort((a,b)=> {
          if(a.name < b.name) return -1
          if(a.name > b.name) return 1
          return 0
        })
        setAudienceSets(audienceArr)
    })
  }

  addPublisherAudience = async (publisherId, audience) => {
    let query = this.firestoreRoot.collection('publisher').doc(publisherId).collection('audiences')
    
    let res = await query.add(audience)

    // console.log('res', res.id)
    // query.doc(res.id).update({id:res.id})
  }

  updatePublisherAudience = async (publisherId, audienceId, audience) => {
    
    const query = this.firestoreRoot.collection('publisher').doc(publisherId).collection('audiences').doc(audienceId)
    
    const FieldValue = window.firebase.firestore.FieldValue
    await query.update({
      filters: FieldValue.delete()
    })
    
    await query.update({
      name: audience.name,
      filters: audience.filters
    })

    return
  }

  deleteAudience = async (publisherId, docId) => {
    return this.firestoreRoot.collection('publisher').doc(publisherId).collection('audiences').doc(docId).delete()
  }

  getAudiences = async (publisherId) => {
    return await this.firestoreRoot.collection('publisher').doc(publisherId).collection('audiences').get().then(snap => {
      let audienceArr = []
      snap.forEach(docRef => {
        audienceArr.push(docRef.data())
      })
      return audienceArr
    })
  }

  // getCampaigns = async (publisherId, setCampaigns) => {
  //   return await this.firestoreRoot
  //     .collection('publisher')
  //     .doc(publisherId)
  //     .collection('adCampaigns')
  //     .onSnapshot((snap)=>{
  //       console.log("snap")
  //       let newCampaigns = snap.docs.map(docRef => ({
  //           ...docRef.data(),
  //           id: docRef.id
  //       }))
  //       setCampaigns(newCampaigns)
  //     })
  // }

}
