export default {
  FIREBASE: {
    VERSION: 'v1-prod',
  },
  LOCAL_STORAGE: {
    PROMPTS_BY_EVENT_ID_PREFIX: 'prompts-by-event-id-',
    RESPONSES_BY_PROMPT_ID_PREFIX: 'responses-by-prompt-id-',
    SENTIMENT_BY_ID_PREFIX: 'sentiment-by-id-',
    VIDEO_CALLOUTS_BY_EVENT_ID_PREFIX: 'video-callouts-by-event-id-',
  },
  CHART: {
    RESOLUTION: 2,
    X_AXIS_TICK_COUNT: 7,
    COLORS: {
      AXES: '#7D8995',
      PRIMARY: '#002A5C',
      BACKGROUND: '#FFFFFF',
      PIE_CHART: ['#002A5C', '#7D8995'],
    },
    DIMENSIONS: {
      X_AXIS_INSET: 50,
      Y_AXIS_INSET: 100,
      X_AXIS_LINE_WIDTH: 1,
      Y_AXIS_LINE_WIDTH: 0.5,
      X_AXIS_TIME_SERIES_INSET: 75,
      Y_AXIS_TIME_SERIES_INSET: 25,
      X_TIME_SERIES_LINE_WIDTH: 6,
    },
    DEFAULT_SETTINGS: {
      X_AXIS_MINUTES: 7,
      X_AXIS_INCREMENT: 1,
      REVERSE_X_AXIS: true,
    },
    MAX_INTENSITY: 100,
    TEXT: {
      EVENT_START: 'Event Start',
      NOW: '<1m',
    },
  },
};
