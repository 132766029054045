import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getCurrentUserFromLocalStorage } from './Utils';

const ProtectedRoute = ({
  component: Component,
  roles = undefined,
  ...rest
}) => {
  const setComponent = (props) => {
      const currentUser = getCurrentUserFromLocalStorage();
      if (currentUser) {
        // console.log('currentUser',currentUser);
        // if (currentUser.isRegistered) {
          // if (roles) {
            // if (roles.includes(currentUser.role)) {
              return <Component {...props} />;
            // }
            // return (
            //   <Redirect
            //     to={{
            //       pathname: '/unauthorized',
            //       state: { from: props.location },
            //     }}
            //   />
            // );
          // }
          // return <Component {...props} />;
        // }
        // return (
        //   <Redirect
        //     to={{
        //       pathname: '/enrollment',
        //       state: { from: props.location },
        //     }}
        //   />
        // );
      }
      return (
        <Redirect
          to={{
            pathname: '/auth/login',
            state: { from: props.location },
          }}
        />
      );
  };

  return <Route {...rest} render={setComponent} />;
};

// eslint-disable-next-line import/prefer-default-export
export { ProtectedRoute };
