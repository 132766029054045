import React from 'react'
import { Col, Form, Input, Row, Radio, Select } from 'antd';

const { Option } = Select;
let ageArr = []

for(let i=13; i < 66; i++){
  ageArr.push(i)
}

export default function CreateNewAudience({setAudienceName, audienceName, genderValue, setGenderValue, ageValue, setAgeValue, ageEndValue, setAgeEndValue}){
  const [form] = Form.useForm();

  ageArr.push('And Older')

  const handleNameChange= (e) => {
    setAudienceName(e.target.value)
  }

  const handleGenderChange = (e) => {
    setGenderValue(e.target.value)
  }

  const onMinAgeChange = (val) => {
    setAgeValue(val)
  }

  const onMaxAgeChange = (val) => {
    // console.log('max age', val)
    setAgeEndValue(val)
  }

  return(
    <Row gutter={16} style={{width:'100%'}}>
      <Col span={24}>
        <Form
          form={form}
          layout="vertical"
          // initialValues={{ requiredMark }}
          // onValuesChange={onRequiredTypeChange}
          // requiredMark={requiredMark}
        >
          <Form.Item label="Audience Name">
            <Input placeholder="Name" onChange={handleNameChange} value={audienceName}/>
          </Form.Item>
          {/* <Form.Item label="Locations">
            <Input placeholder="Begin Typing a City, State, or Country" />
          </Form.Item> */}
          <Form.Item label="Gender">
            <Radio.Group
              onChange={handleGenderChange}
              // options={}
              defaultValue='all'
              value={genderValue}
              optionType='button'
            >
              <Radio.Button value="all">All</Radio.Button>
              <Radio.Button value='male'>Male</Radio.Button>
              <Radio.Button value='female'>Female</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item label='Age'>
            <Select 
              defaultValue={13}
              value={ageValue} 
              style={{width:120}} 
              onChange={onMinAgeChange}
              name='minAge'
            >
              {ageArr.map(age => {
                return <Option key={age} value={age}>{age}</Option>
              })}
            </Select>
            <Select 
              defaultValue='And Older'
              value={ageEndValue}
              style={{width:120}} 
              onChange={onMaxAgeChange}
              name='minAge'
            >
              {ageArr.map(age => {
                  return <Option key={age} value={age}>{age}</Option>
                })}
            </Select>
          </Form.Item>
        </Form>
      </Col>
      {/* <Col span={8}>
        Potential Reach
      </Col> */}
    </Row>
  )
}