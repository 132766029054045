import React from 'react'

import { Col, Table } from 'antd'

export default function PostedAdDetailsSmall({ prompt }){

  const columns = [
    {
      title: 'Thumbnail',
      dataIndex: 'sponsorLogo',
      key: 'sponsorLogo',
      // align: 'right',
      render: (text,record) => (
        <Col>
          <img style={{height: 50, borderRadius: 5}} src={record.sponsorLogo}/>
        </Col>
      )
    },
    // {
    //   title: 'CTA',
    //   dataIndex: 'callOutText',
    //   key: 'callOutText',
    //   // align: 'right'
    // },
    {
      title: 'Views',
      dataIndex: 'adViewers',
      key: 'adViewers',
      render: (text,record)=>{
        if(record.adViewers && record.adViewers.length > 0){
          return record.adViewers.length
        }else{
          return 0
        }
      }
    },
    {
      title: 'Clicks',
      dataIndex: 'adClickers',
      key: 'adClickers',
      render: (text,record)=>{
        if(record.adClickers && record.adClickers.length > 0){
          return record.adClickers.length
        }else{
          return 0
        }
      }
      // align: 'right'
    },
  ]

  return(
    <Table 
      columns={columns} 
      dataSource={prompt.ads} 
      rowKey="callOutText"
      pagination={false}
    />
  )

}