import React, { useContext } from 'react'
import { Row, Col, Radio, Tag, Typography, Form, Button } from 'antd'
import { FirebaseContext } from '../../utils/Firebase'

export default function AdSetFilters({
  form,
  adSetData,
  setAdSetData
}){
  const firebase = useContext(FirebaseContext)

  const handleManualAdChange = (e) => {
    e.preventDefault()
    if(e.target.value === 'manual'){
      setAdSetData({
        ...adSetData, 
        events:[],
        leagues:[],
        teams:[],
        manualAd: e.target.value
      })
      form.setFieldsValue({
        events:[],
        leagues:[],
        teams:[],
        manualAd:e.target.value
      })
    }else{
      setAdSetData({...adSetData, manualAd: e.target.value})
      form.setFieldsValue({
        manualAd:e.target.value
      })
    }
  }

  const handleEventsChange = (event,checked) => {
    let eventsArr = [...adSetData.events]
    let leaguesArr = [...adSetData.leagues]
    let teamsArr = [...adSetData.teams]
    let i = eventsArr.findIndex(selectedEvent => selectedEvent === event.event)
    if(checked){
      if(i === -1){
        eventsArr.push(event.event)
      }
      setAdSetData({...adSetData, events: eventsArr})
      form.setFieldsValue({
        events:eventsArr
      })
    }else if(!checked){
      if(i > -1){
        event.leagues.map(deselectedLeagueObj => {
          if(leaguesArr){
            let leagueIndex = leaguesArr.findIndex(selectedLeagueIdentifier => deselectedLeagueObj.identifier === selectedLeagueIdentifier)
            if(leagueIndex > -1){
              leaguesArr.splice(leagueIndex,1)
            }
          }
          if(teamsArr){
            let teamIndex = teamsArr.findIndex(teamObj => teamObj.league === deselectedLeagueObj.identifier)
            if(teamIndex > -1){
              teamsArr.splice(teamIndex,1)
            }
          }
        })
        eventsArr.splice(i,1)
        setAdSetData({...adSetData, events:eventsArr, leagues:leaguesArr, teams:teamsArr})
        form.setFieldsValue({
          events:eventsArr,
          leagues:leaguesArr,
          teams:teamsArr
        })
      }
    }
    form.validateFields(['events'])
  }

  const handleLeaguesChange = async (league,checked) => {
    let leaguesArr = [...adSetData.leagues]
    let teamsArr = [...adSetData.teams]
    let availableEventArr = [...adSetData.availableEvents]

    if(checked){
      leaguesArr.push(league.identifier)
      if(!league.teams){
        await firebase.getCollection('teams')
          .where('league', '==', league.identifier)
          .get()
          .then(snap => {
            let teamsArr = snap.docs.map(docRef => docRef.data())
            availableEventArr.forEach(eventObj => {
              let i = eventObj.leagues.findIndex(availableLeague => availableLeague.identifier === league.identifier)
              if(i > -1){
                eventObj.leagues[i].teams = teamsArr
                return eventObj
              }
            })
          })
      }
    }else{
      let leaguesArrIndex = leaguesArr.findIndex(leagueIdentifier => leagueIdentifier === league.identifier)
      if(leaguesArrIndex > -1){
        leaguesArr.splice(leaguesArrIndex,1)
      }
      if(teamsArr){
        let teamIndex = teamsArr.findIndex(teamObj => teamObj.league === league.identifier)
        if(teamIndex > -1){
          teamsArr.splice(teamIndex,1)
        }
      }
    }
    setAdSetData({
      ...adSetData,
      leagues:leaguesArr,
      teams:teamsArr,
      availableEvents:availableEventArr
    })
    form.setFieldsValue({
      leagues:leaguesArr,
      teams:teamsArr,
      availableEvents:availableEventArr
    })
    form.validateFields(['leagues'])
  }

  const handleTeamsChange = (team,checked) => {
    let teamsArr = [...adSetData.teams]
    let i = teamsArr.findIndex(teamObj => teamObj.id === team.id)
    if(checked){
      teamsArr.push(team)
    }else{
      if(i > -1){
        teamsArr.splice(i,1)
      }
    }
    setAdSetData({...adSetData, teams:teamsArr})
    form.setFieldsValue({
      teams:teamsArr,
    })
    form.validateFields(['teams'])
  }

  const handleSelectAllTeams = (league) => {
    let teamsArr = [...adSetData.teams]
    if(league.teams){
      league.teams.forEach(teamObj => teamsArr.push(teamObj))
    }
    setAdSetData({...adSetData, teams: teamsArr})
    form.setFieldsValue({
      teams:teamsArr,
    })
    form.validateFields(['teams'])
  }

  const handleClearAllTeams = (league) => {
    let teamsArr = [...adSetData.teams]
    let filteredTeams = teamsArr.filter(teamObj => teamObj.league !== league.identifier)
    setAdSetData({...adSetData, teams:filteredTeams})
    form.setFieldsValue({
      teams:filteredTeams,
    })
    form.validateFields(['teams'])
  }
  
  return(
    <Row style={adSetFiltersStyleProps}>
      <Col span={24}>
        <Typography.Title level={3} style={{margin:0}}>Ad Set Filters</Typography.Title>
        <Typography.Text type='secondary'>Define the parameters of the ad set</Typography.Text>
      </Col>
      <Col span={24} style={{marginTop:20}}>
        <Typography.Title level={5}>Ad Automation</Typography.Title>
      </Col>
      <Col span={24}>
        <Form.Item
            name="availableEvents"
          >
            <Radio.Group optionType='button' onChange={handleManualAdChange}>
              <Radio.Button value='manual'>Manual Ads</Radio.Button>
              <Radio.Button value='automated'>automated Ads</Radio.Button>
            </Radio.Group>
          </Form.Item>
      </Col>
      
      {adSetData.manualAd === 'manual'
        ? 
          null
        :
        <Row>

          {/* //////// SHOW AND SELECT EVENT TYPES ////////// */}
          {adSetData.availableEvents
            ?
              <Row style={{width:'100%'}}>
                <Col span={24}>
                  <Typography.Title level={5}>Event Types <Typography.Text type='secondary'>(Select all that apply)</Typography.Text></Typography.Title>
                </Col>
                <Col span={24} style={{ marginLeft:10 }}>
                  <Form.Item
                    name="events"
                    rules={[
                      {
                        required: true,
                        message: 'Please Select an Event type to automate this ad set',
                      },
                    ]}
                  >
                    {adSetData.availableEvents.map(event => (
                      <Tag.CheckableTag
                        id='ad-checkable-tag'
                        key={event.event}
                        checked={adSetData.events ? adSetData.events.indexOf(event.event) > -1 : false}
                        onChange={checked => handleEventsChange(event,checked)}
                      >
                        {event.event}
                      </Tag.CheckableTag>
                    ))}
                  </Form.Item>
                </Col>
              </Row>
            :
              null
          }
      
          {/* //////// SHOW AND SELECT LEAGUES ////////// */}
          {adSetData.events && adSetData.events.length > 0 && adSetData.availableEvents
            ? 
            <Form.Item
              name='leagues'
              // rules={[
              //   {
              //     required: true,
              //     message: 'Please Select a League to automate this ad set',
              //   },
              // ]}
            >
              <Row style={{width:'100%'}}>
                <Col span={24}>
                  <Typography.Title level={5} style={{marginBottom:0}}>Leagues <Typography.Text type='secondary'>(Select all that apply)</Typography.Text></Typography.Title>
                </Col>
                <Col span={24}>
                  {adSetData.events.map(selectedEvent => (
                    adSetData.availableEvents.map(availableEvent => {
                      if(availableEvent.event === selectedEvent){
                        return(
                          <Row style={{ marginLeft:10, marginTop:15 }}>
                            <Col span={24} style={{marginBottom:5}}>
                              <Tag color='blue'>
                                {availableEvent.event}
                              </Tag>
                            </Col>
                            <Col span={24} style={{ marginLeft:10 }}>
                              {availableEvent.leagues.map(league => (
                                <Tag.CheckableTag
                                  id='ad-checkable-tag'
                                  key={league.identifier}
                                  checked={adSetData.leagues.indexOf(league.identifier) > -1}
                                  onChange={checked => handleLeaguesChange(league,checked)}
                                >
                                  {league.name}
                                </Tag.CheckableTag>
                              ))}
                            </Col>
                          </Row>
                        )
                      }
                    })
                  ))}
                </Col>
              </Row>
            </Form.Item>
            :
              null
          }
    
          {/* //////// SHOW AND SELECT TEAMS ////////// */}
          {adSetData.leagues && adSetData.leagues.length > 0
            ?
              <Form.Item
                name='teams'
                // rules={[
                //   {
                //     required: true,
                //     message: 'Please Select a Team to automate this ad set',
                //   },
                // ]}
              >
                <Row style={{width:'100%'}}>
                  <Col span={24}>
                    <Typography.Title level={5} style={{marginBottom:0}}>Teams <Typography.Text type='secondary'>(Select all that apply)</Typography.Text></Typography.Title>
                  </Col>
                  <Col span={24}>
                    {adSetData.events.map(selectedEvent => (
                      adSetData.availableEvents.map(availableEvent => {
                        if(selectedEvent === availableEvent.event){
                          return adSetData.leagues.map(selectedLeague => (
                            availableEvent.leagues.map(availableLeague => {
                              if(selectedLeague === availableLeague.identifier){
                                return(
                                  <Row key={availableLeague.name} style={{ marginLeft:10, marginTop:15 }}>
                                    <Col span={24} style={{marginBottom:5}}>
                                      <Tag color='geekblue'>
                                          {availableLeague.name}
                                      </Tag>
                                      <Button onClick={()=>handleSelectAllTeams(availableLeague)} type='text'>Select All</Button>
                                      <Button onClick={()=>handleClearAllTeams(availableLeague)} type='text' style={{opacity:0.6}}>Clear</Button>
                                    </Col>
                                    <Col span={24} style={{ marginLeft:10 }}>
                                      {availableLeague.teams
                                        ?
                                          availableLeague.teams.map(team => (
                                            <Tag.CheckableTag
                                              id='ad-checkable-tag'
                                              key={team.id}
                                              checked={adSetData.teams.findIndex(teamObj => teamObj.id === team.id) > -1}
                                              onChange={checked => handleTeamsChange(team,checked)}
                                            >
                                              {team.name}
                                            </Tag.CheckableTag>
                                          ))
                                        :
                                          null
                                      }
                                    </Col>
                                  </Row>
                                )
                              }
                            })
                          ))
                        }
                      })
                    ))}
                  </Col>
                </Row>
              </Form.Item>
            :
              null
          }
        </Row>
      }
    </Row>
  )
}

const adSetFiltersStyleProps = {
  width:'100%',
  background:'white', 
  borderRadius:8, 
  boxShadow:'0px 1px 2px rgba(0,0,0,0.25)', 
  paddingTop: 15,
  paddingBottom: 20,
  paddingLeft: 20,
  paddingRight: 20,
  marginTop:20
}