import React from 'react'
import { Row, Col } from 'antd'

// COMPONENTS
import BrowseEventsCarousel from '../../components/FeaturedEventsCarousel/FeaturedEventsCarousel'
import EventRow from './EventRow'
import TodaysEventsRow from './TodaysEventsRow'

export default function BrowseEvents(){
  return(
    <Row>
      <Col span={24}>
        <BrowseEventsCarousel/>
      </Col>

      <Row style={{paddingLeft: 20, paddingBottom:20, paddingTop:20, overflow:'hidden'}}>
        <TodaysEventsRow title={`Today's Events`} type={'sport_match'} limit={100} end={1}/>

        {/* <EventRow title={'Upcoming NFL Games'} league={'nfl'}  limit={100} end={6}/> */}

        <EventRow title={'Upcoming NFL Games'} league={'nflp'}  limit={100} end={6}/>

        <EventRow title={'Upcoming NCAA Football Games'} league={'ncaa_fb'}  limit={100} end={6}/>

        <EventRow title={'Upcoming NBA Games'} league={'nba'}  limit={100} end={6}/>

        {/* <EventRow title={'Upcoming NCAA Basketball Games'} league={'ncaa_mb'}  limit={100} end={6}/> */}

        <EventRow title={'Upcoming MLB Games'} league={'mlb'}  limit={100} end={6}/>

        {/* <EventRow title={'Upcoming NHL Games'} league={'nhl'}  limit={100} end={6}/> */}

        <EventRow title={'Upcoming MLS Games'} league={'mls'}  limit={100} end={6}/>

        {/* <EventRow title={'The Grind Session Upcoming Games'} league={'grind'}  limit={100} end={6}/> */}

        {/* <EventRow title={'Upcoming Live TV'} league={'shows'}  limit={100} end={6}/> */}

        {/* <EventRow title={'Upcoming Political Debates'} type={'political_debate'}  limit={100} end={6}/> */}

        {/* <EventRow title={'Upcoming Shows'} type={'live_event'}  limit={100} end={6}/> */}
      </Row>


    </Row>
  )

}