import { DeleteTwoTone, EditTwoTone, ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Modal, Row, Table } from 'antd';
import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import CampaignFormModal from '../../modals/AdManagerModals/CampaignFormModal';
import { FirebaseContext } from '../../utils/Firebase';

const { confirm } = Modal;

const AdCampaings = ({ setSelectedCampaigns, selectedCampaigns, campaigns }) => {
  const firebase = useContext(FirebaseContext)
  const publisherId = useSelector(
    (state) => state.publishing.publishingAs?.id ?? 0
  )
  const [ modalOpen, setModalOpen ] = useState()
  const  [ modalType, setModalType ] = useState()
  const [ selectedCampaign, setSelectedCampaign ] = useState()
  const columns = [
    // {
    //   title: 'On/Off',
    //   dataIndex: 'active',
    //   key: 'active',
    //   // align: 'right',
    //   width:40,
    //   render: (text, record) => (
    //     <Switch size='small' checked={record.active} onChange={() => handleActiveChange(record)}/>
    //   ),
    // },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Objective',
      dataIndex: 'objective',
      key: 'objective',
    },
    {
      title: 'Results',
      dataIndex: 'results',
      key: 'results',
      align: 'right',
    },
    {
      title: 'Reach',
      dataIndex: 'reach',
      key: 'reach',
      align: 'right',
    },
    {
      title: 'Impressions',
      dataIndex: 'impressions',
      key: 'impressions',
      align: 'right',
    },
    {
      title: 'Cost Per Result',
      dataIndex: 'costPerResult',
      key: 'costPerResult',
      align: 'right',
    },
    // {
    //   title: 'End Date',
    //   dataIndex: 'campaignEndDate',
    //   key: 'campaignEndDate',
    //   align: 'right',
    // }
    {
      title: '',
      key: 'action',
      align: 'right',
      width:96,
      render: (text, record) => (
        <Row>
          <Button type='text' onClick={()=>handleEditCampaigns(record)} icon={<EditTwoTone/>}/>
          <Button type='text' danger onClick={()=>handleDeleteCampaigns(record)} icon={<DeleteTwoTone twoToneColor='red'/>}/>
        </Row>
      ),
    },
  ]

  // const handleActiveChange = (record) => {
  //   const query = firebase.getCollection('publisher').doc(publisherId).collection('ad-campaigns').doc(record.id)
  //   query.update({active: !record.active})
  // }

  const handleCheck = (selectedRowKeys, selectedRows) => {
    setSelectedCampaigns(selectedRows)
  }

  const handleModalOpen = (type) => {
    setSelectedCampaign({
      active:false,
      name:'',
      objective:null,
      reach:0,
      impressions: 0,
      results: 0,
      endDate: null,
    })
    setModalType(type)
    setModalOpen(true)
  }

  const handleModalCancel = () => {
    setSelectedCampaign(null)
    setModalOpen(false)
  }


  const handleEditCampaigns = (record) => {
    setModalType('edit')
    setSelectedCampaign(record)
    setModalOpen(true)
  }

  const handleDeleteCampaigns = async (record) => {
    confirm({
      title: selectedCampaigns.length > 1 ? `Are you sure you want to delete ${selectedCampaigns.length} campaigns?` : `Are you sure you want to delete ${selectedCampaigns.length} campaign?`,
      icon: <ExclamationCircleOutlined />,
      content: 'Deleting campaigns also delete all linked ad sets and ads. This cannot be undone.',
      okText:'Yes',
      onOk() {
        // Promise.all(selectedCampaigns.map( campaign => {
          firebase.getCollection('publisher').doc(publisherId).collection('ad-campaigns').doc(record.id)
          .delete()
          .then( () => {
            firebase.getCollection('publisher').doc(publisherId).collection('ad-sets')
              .where('campaignId', '==', record.id)
              .get()
              .then( snap => {
                snap.docs.map(docRef=>{
                  docRef.delete()
                })
              })
          })
        // }))
      },
      onCancel() {
        // console.log('Cancel');
      },
    });
  }

  return(
      <Row>
        <Col span={24}>
          <Row justify="space-between" align="middle">
            <Col>
              <Button type='primary' icon={<PlusOutlined/>} onClick={()=>handleModalOpen('new')}>Create New Campaign</Button>
            </Col>
          </Row>
        </Col>
        <Col span={24} style={{marginTop: 20}}>
          <Table 
            rowSelection={{
              type:'checkbox',
              onChange: (selectedRowKeys, selectedRows) => handleCheck(selectedRowKeys, selectedRows),
            }}
            columns={columns} 
            dataSource={campaigns} 
            rowKey="id"
          />
        </Col>
        <Modal
          title={modalType === 'new' ? 'Create New Campaign' : 'Edit Campaign'}
          visible={modalOpen}
          centered
          onCancel={handleModalCancel}
          width={700}
          okText='Create Campaign'
          destroyOnClose
          footer={[
            <Row justify='end'>
              <Button onClick={handleModalCancel}>Cancel</Button>
              <Button type='primary' form='newAdCampaignForm' htmlType='submit'>Submit</Button>
            </Row>
          ]}
        >
          <CampaignFormModal
            selectedCampaign={selectedCampaign}
            setModalOpen={setModalOpen}
          />
        </Modal>
      </Row>
  )
}

export default AdCampaings
