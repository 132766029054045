import { combineReducers } from 'redux';
import authUser from './auth/reducer';
import ducks from './ducks/reducer';
import publishing from './publishing/reducer';

const reducers = combineReducers({
  authUser,
  publishing,
  ducks
});

export default reducers;
