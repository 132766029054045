import React, { useEffect, useState } from 'react'
import {  Typography, Row, Col } from 'antd';
import numeral from 'numeral'

const winnerColor = '#002A5C'

export default function Rank({prompt}){
  const [ responses, setResponses ] = useState()

  // console.log('prompt',prompt)

  useEffect(()=>{
    if(!prompt || !prompt.responseCountByChoice) return
    let resCountObj = {...prompt.responseCountByChoice}    
    let publisherOrder = []
    let sortable = []

    
    for( let response in resCountObj){
      // sortable.push([response, resCountObj[response]])
      publisherOrder.push({response:response, count:resCountObj[response], rank:0})
    }

    publisherOrder.sort((a,b)=>{
      return a.count - b.count
    })
    
    publisherOrder.map((res,index)=>{
      let resIndex = publisherOrder.findIndex((element) => {
        // console.log('res', res)
        // console.log('ele', element)
        return res.response === element.response
      })
      // console.log('resIndex',resIndex)
      publisherOrder[resIndex].rank = index + 1
    })

    setResponses(publisherOrder)
  },[prompt])

  // console.log('responses',responses)

  return(
    responses 
      ? 
        responses.map(response => (
          <Row style={{ position:'relative', border:`2px solid ${winnerColor}`, borderRadius: 5, marginBottom: 10}}>
          <Row gutter={16} align='middle' style={{margin: 10}}>
            <Col>
              <Typography.Text strong style={{ fontSize:18, color: winnerColor}}>{numeral(response.rank).format('Oo')}</Typography.Text>
            </Col>
            <Col>
              <Typography.Text style={{fontSize: 18, color: winnerColor}}>{response.response}</Typography.Text>
            </Col>
          </Row>
        </Row>
          // <Row align='middle' justify='center' key={response.rank}>
          //   <Col span={20}>
          //     <Typography.Text>{response.response}</Typography.Text>
          //   </Col>
          //   <Col span={4}>
          //     <Typography.Text>{numeral(response.rank).format('Oo')}</Typography.Text>
          //   </Col>
          // </Row>
        ))
      :
        null
  )
}