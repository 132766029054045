import React from 'react'
import { Row, Col, Typography, Tooltip } from 'antd'

const winnerColor = '#002A5C'

const overlayStyle = { 
  background:'white', 
  border: `2px solid ${winnerColor}`, 
  color: winnerColor,
  fontSize: 20
}

export default function CustomPollResults({responseObj}){
  const { barWidth, response, count, image } = responseObj
  return(
    <Tooltip placement='right' title={`Response Count: ${count}`} overlayInnerStyle={overlayStyle}> 
      <Row style={{ position:'relative', border:`2px solid ${winnerColor}`, borderRadius: 5, marginBottom: 10}}>
        {image 
          ? 
            <Row>
              <img src={image} style={{width:'100%'}}/>
            </Row>
          :
            null
        }
        <Row gutter={16} align='middle' style={{margin: 10}}>
          <Col>
            <Typography.Text strong style={{ fontSize:18, color: winnerColor}}>{`${Math.round(barWidth)}%`}</Typography.Text>
          </Col>
          <Col>
            <Typography.Text style={{fontSize: 18, color: winnerColor}}>{response}</Typography.Text>
          </Col>
        </Row>
        <Col style={{ position:'absolute', height:'100%', width:`${barWidth}%`, background:winnerColor, opacity:0.2}}/>
      </Row>
    </Tooltip>
  )
}