import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import './CarouselSlideV2.css';

import * as Vibrant from 'node-vibrant';
import { connect, useSelector } from 'react-redux';
import OpineLogo from '../../assets/images/OpineLogo.svg';
import brushBg from '../../assets/images/brush-bg.png';
import { buildFeaturedTeams } from '../../utils/helpers/buildTeams';

import { Button, Modal, Row, message } from 'antd';
import AddFeedToEvent from '../../modals/AddFeedToEventModal/AddFeedToEvent';

import { getPublisherUpcomingFeeds } from '../../helpers/Firebase';
import { setCurrentFeedsList, switchCurrentFeed } from '../../redux/publishing/actions';
import { FirebaseContext } from "../../utils/Firebase";

function CarouselSlide({ event, setCurrentFeedsListAction, switchCurrentFeedAction }){
  const history = useHistory()
  const firebase = useContext(FirebaseContext)

  const publisherId = useSelector(
    (state) => state.publishing.publishingAs?.id ?? 0
  )
  const [ bgColor, setBgColor ] = useState('fff')
  const [ teamOne, setTeamOne ] = useState()
  const [ teamTwo, setTeamTwo ] = useState()
  const [ eventLocation, setEventLocation ] = useState()
  const [ submittingModal, setSubmittingModal ] = useState(false)
  const [ newFeedImg, setNewFeedImg ] = useState()
  const [ newFeedName, setNewFeedName ] = useState()
  const [ newFeedDescription, setNewFeedDescription ] = useState()
  const [ modalOpen, setModalOpen ] = useState(false)

  useEffect(()=>{
    if(!teamOne) return
    if(teamOne.arena && teamOne.city && teamOne.state){
      setEventLocation(<p id='location'><span>{teamOne.arena}</span></p>)
      // setEventLocation(<p id='location'>{teamOne.arena}, <span>{teamOne.city}</span></p>)
    // } else if(!teamOne.arena && teamOne.city && teamOne.state){
    //   setEventLocation(<p id='location'>in {teamOne.city}, <span>{teamOne.state}</span></p>)
    // } else if(!teamOne.arena && !teamOne.city && teamOne.state){
    //   setEventLocation(<p id='location'>in <span>{teamOne.state}</span></p>)
    // } else if(!teamOne.arena && teamOne.city && !teamOne.state){
    //   setEventLocation(<p id='location'>in <span>{teamOne.city}</span></p>)
    } else {
      setEventLocation(<p id='location'><span>{event.sport}</span></p>)
    }
  },[ teamOne ])

  useEffect(()=>{
    if(!event) return
    const { formattedTeamOne, formattedTeamTwo } = buildFeaturedTeams(event)
    setTeamOne(formattedTeamOne)
    setTeamTwo(formattedTeamTwo)
    if(event.parties[0].logo){
      Vibrant.from(event.parties[0].logo).getPalette((err, palette) => {
        setBgColor(palette.Muted.hex)
      })
    }
  },[event])

  const handleModalSubmit = async () => {
    // const { publisherId } = publishingAs
    setSubmittingModal(true)
    firebase.createNewRegularFeed(event, newFeedName, newFeedDescription, newFeedImg, publisherId, message)
      .then(async res=>{
        if(!res){
          setSubmittingModal(false)
          message.error('There was an error creating a new feed, please try again.')
        } else {
          await getPublisherUpcomingFeeds(publisherId).then(res => {
            switchCurrentFeedAction(res[0])
            setCurrentFeedsListAction(res, publisherId)
            console.log('res',res)
            setSubmittingModal(false)
            setNewFeedName(null)
            setNewFeedDescription(null)
            setNewFeedImg(null)
            history.push('/publisher')
          })
          .catch(err => console.log(err))
        }
      })
  }

  const handleClick = (bol) => {
    setModalOpen(bol)
  }

  return(
    teamOne && teamTwo
    ?
      <div className='carousel-slide'>
        <div className='slide-left-container'>
          <div className='slide-left-content'>
            {leagueSwitcher(event.league)}
            <div className='slide-left-broadcast-info'>
              <p id='date'>{event.formattedEventTime.day} {event.formattedEventTime.month} {event.formattedEventTime.date}</p>
              <p id='time'>at <span>{event.formattedEventTime.time}</span></p>
              {event.allBroadcasters
                ? 
                  <div className='broadcast-block'>
                    <p>Watch it on</p>
                    <div className='broadcast-block-logo'>
                      <img src={event.allBroadcasters.logoUrl} />
                    </div>
                  </div>
                :
                  // <div className='broadcast-block'>
                  //   <img src={OpineLogo} alt='Opine' />
                  // </div>
                  null
              }
            </div>
          </div>
        </div>
        <div className='slide-middle' style={{backgroundColor:`${bgColor}`, backgroundImage:`url(${event.parties[0].logo ? event.parties[0].logo : OpineLogo})`, width:'70%'}}>
          <div className='slide-middle-gradient'/>
          <Row height='100%' justify='center' align='bottom' style={{ height:'100%', position:'relative', bottom:20}}>
            <Button shape='round' ghost onClick={handleClick} style={{}}>Add a Feed</Button>
          </Row>
        </div>
        <div className='slide-right-container'>
          <div className='slide-right-content'>
            <div className='slide-right-teams'>
              <p className='team-name'>{teamTwo.nameTwo}</p>
              <p id='team-1'>{teamTwo.nameOne}</p>
              <p className='team-name'><span>at</span> {teamOne.nameTwo}</p>
              <p id='team-2'>{teamOne.nameOne}</p>
            </div>
            <div className='location-block' style={{background:`url(${brushBg})`}}>
              {eventLocation}
            </div>
          </div>
        </div>
        {event.league}
        <Modal
          title={'Add a Feed'}
          visible={modalOpen}
          centered
          onOk={handleModalSubmit}
          onCancel={()=>setModalOpen(false)}
          width={500}
          okText='Create Feed'
          confirmLoading={submittingModal}
        >
          <AddFeedToEvent 
            setFeedDescription={setNewFeedDescription}
            setFeedName={setNewFeedName}
            setFeedImg={setNewFeedImg}
            feedImg={newFeedImg}
            feedName={newFeedName}
            feedDescription={newFeedDescription}
            event={event}
            setSubmittingModal={setSubmittingModal}
          />
        </Modal>
      </div>
    :
      null
  )
}

export default connect(null,{
  setCurrentFeedsListAction:setCurrentFeedsList,
  switchCurrentFeedAction:switchCurrentFeed
})(CarouselSlide)

const leagueSwitcher = (league) => {
  const NFLCase = 'nflp',
        NBACase = 'nba',
        MLBCase = 'mlb',
        MLSCase = 'mls',
        NCAAFBCase = 'ncaa_fb',
        NCAAMBCase = 'ncaa_mb'

  switch(league){
    case NFLCase:
    return(
      <div className='slide-left-league'>
        <p>National</p>
        <p>Football</p>
        <p>League</p>
      </div>
      )

    case NBACase:
    return(
      <div className='slide-left-league'>
        <p>National</p>
        <p>Basketball</p>
        <p>Association</p>
      </div>
      )

    case MLBCase:
    return(
      <div className='slide-left-league'>
        <p>Major</p>
        <p>League</p>
        <p>Baseball</p>
      </div>
      )

    case MLSCase:
    return(
      <div className='slide-left-league'>
        <p>Major</p>
        <p>League</p>
        <p>Soccer</p>
      </div>
      )

    case NCAAFBCase:
    return(
      <div className='slide-left-league'>
        <p>NCAA</p>
        <p>Football</p>
        {/* <p>Game</p> */}
      </div>
      )

    case NCAAMBCase:
    return(
      <div className='slide-left-league'>
        <p>NCAA</p>
        {/* <p>Mens</p> */}
        <p>Basketball</p>
      </div>
      )

    default:
    return(
      <div className='slide-left-league'>
        <p>Opine</p>
        <p>Featured</p>
        <p>Event</p>
      </div>
      )
  }
}
