export const getGender = (audience) => {
  if(audience && audience.filters){
    let gender = audience.filters.filter(audience => audience.id === 'W6NUekFCgWiRHBr9pgj3')
    if(gender[0]){
      return gender[0].value
    }else{
      return 'all'
    }
  }else{
    return 'all'
  }
}

export const getMinMaxAge = (val, audience) => {
  let minAge = 'No Minimum',
      maxAge = 'No Maximum'

  if(audience && audience.filters){
    audience.filters.map(filter => {
      if(filter.id === 'GyUK3OHi7regN2sWK7ve'){
        minAge = filter.value
        maxAge = filter.endValue
      }else if(filter.id === 'AzSuVuhDexprQ7Q2usYu'){
        minAge = filter.value
      }else if(filter.id === 'mb6UDypGR8FfZKK5DkPL'){
        maxAge = filter.value
      }
    })
  }

  if(val === 'min'){
    return minAge
  }else if(val === 'max'){
    return maxAge
  }
}

export const buildAudience = (selectedAdSet, data) => {
  let name = data.audienceName ? data.audienceName : ''
  let audience = {
    ...selectedAdSet.audience, 
    name
  }
  let newFilters = []
  // BUILD GENDER FILTER
  if(data.gender && data.gender !== 'all'){
    newFilters.push({
      id: 'W6NUekFCgWiRHBr9pgj3',
      value: data.gender
    })
    audience = {...audience, filters: newFilters}
  }

  // BUILD AGE FILTERS
  if(data.minAge !== 'No Minimum' && data.maxAge !== 'No Maximum'){
    // AGE BETWEEN FILTER
    newFilters.push({
      id:'GyUK3OHi7regN2sWK7ve',
      value:data.minAge,
      endValue: data.maxAge
    })
    audience = {...audience, filters: newFilters}
  }else if(data.minAge !== 'No Minimum' && data.maxAge === 'No Maximum'){
    // AGE LESS THAN FILTER
    newFilters.push({
      id:'AzSuVuhDexprQ7Q2usYu',
      value: data.minAge
    })
    audience = {...audience, filters: newFilters}
  }else if(data.minAge === 'No Minimum' && data.maxAge !== 'No Maximum'){
    // AGE GREATER THAN
    newFilters.push({
      id:'mb6UDypGR8FfZKK5DkPL',
      value: data.maxAge
    })
    audience = {...audience, filters: newFilters}
  }
  return audience
}

export const removeSelectedEvents = async (selectedEvents,removedEvent) => {
  let newSelectedEvents = [...selectedEvents]
  let newRemovedEvent = {...removedEvent}

  let i = newSelectedEvents.findIndex(selectedEvent => {
    return newRemovedEvent.event === selectedEvent
  })
  if(i !== -1){
    newSelectedEvents.splice(i,1)
  }
  return { newSelectedEvents }
}

export const removeSelectedEventLeagues = async (selectedLeagues, removedEvent) => {
  
  let newSelectedLeagues = [...selectedLeagues]
  let leagueIndex = null
  let newRemovedEvent = {...removedEvent}

  newRemovedEvent.leagues.map(removedLeague => {
    let index = newSelectedLeagues.findIndex(selectedLeague => {
      return selectedLeague === removedLeague.identifier
    })
    if(index !== -1){
      leagueIndex = index
      newSelectedLeagues.splice(index,1)
    }
    return removedLeague
  })
  return { newSelectedLeagues, leagueIndex }
}

export const removeSelectedEventTeams = async (selectedTeams, removedEvent, leagueIndex, allEvents) => {
  const newSelectedTeams = [...selectedTeams]
  const newAllEvents = [...allEvents]
  if(leagueIndex !== -1 && leagueIndex !== null){
    let selectedLeague = removedEvent.leagues[leagueIndex]
    if(selectedLeague.teams){
      selectedLeague.teams.map(team => {
        let teamIndex = newSelectedTeams.findIndex(selectedTeam => {
          return selectedTeam === team.id
        })
        if(teamIndex !== -1){
          newSelectedTeams.splice(teamIndex,1)
        }
      })
    }

    if(removedEvent.leagues[leagueIndex].teams && removedEvent.leagues[leagueIndex].teams.length > 0){
      delete removedEvent.leagues[leagueIndex].teams
    }

    let eventIndex = newAllEvents.findIndex(event => {
      return event.event === removedEvent.event
    })
  
    if(eventIndex !== -1){
      newAllEvents.splice(eventIndex,1,removedEvent)
    }
  }
  return { newSelectedTeams, newAllEvents }
}

export const removeSelectedLeague = async ( selectedLeagues, removedLeague ) => {
  let newSelectedLeagues = [...selectedLeagues]
  let leagueIndex = null
  let i = newSelectedLeagues.findIndex(selectedLeague => {
    return selectedLeague === removedLeague.identifier
  })
  if(i !== -1){
    leagueIndex = i
    newSelectedLeagues.splice(i,1)
  }
  return { newSelectedLeagues, leagueIndex }
}

export const removeSelectedTeams = async (selectedTeams, removedLeague, allEvents) => {
  let newSelectedTeams = [...selectedTeams]

  if(removedLeague.teams && removedLeague.teams.length > 0){
    removedLeague.teams.map(team => {
      let i = newSelectedTeams.findIndex(selectedTeam => {
        return selectedTeam === team.id
      })
      if(i !== -1){
        newSelectedTeams.splice(i,1)
      }
      return newSelectedTeams
    })
  }
  let newAllEvents = allEvents.map(event => {
    let leagueIndex = event.leagues.findIndex(league => {
      return league.identifier === removedLeague.identifier
    })
    if(leagueIndex !== -1){
      console.log(leagueIndex)
      if(event.leagues[leagueIndex].teams && event.leagues[leagueIndex].teams.length > 0){
        delete event.leagues[leagueIndex].teams
      }
    }
    return event
  })
  return { newSelectedTeams, newAllEvents }
}
