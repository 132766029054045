// FIREBASE
import { message } from 'antd'
import Firebase from '../../../utils/Firebase'
const firebase = new Firebase()

export const handleWriteNewPrompt = async (prompt, collection) => {
  prompt = {...prompt, timestamp: new Date()} 
  let query = await firebase.getCollection(collection)
  return query.add(prompt)
    .then( async docRef => {
      await query.doc(docRef.id).update({id:docRef.id})
      if(collection === 'prompts'){
        message.success('Prompt Sent!')
      }else if(collection === 'prompt_drafts'){
        message.success('Prompt Saved in Drafts!')
      }else if(collection === 'scheduled_prompts'){
        message.success('Prompt Scheduled!')
      }
      return true
    })
    .catch( error => {
      console.log(`Error: ${error}`)
      message.error('Problem Uploading Prompt, please try again')
      return error
    });
}

export async function updateDraft(prompt){
  let query = await firebase.getCollection('prompt_drafts').doc(prompt.id)
  return query.set(prompt)
    .then( () => {
      message.success('Draft Updated',1)
      return true
    })
    .catch( error => {
      console.log(`Error: ${error}`)
      message.error('Error Updating Draft, please try again')
      return error
    });
}

export async function updateScheduled(prompt){
  let query = await firebase.getCollection('scheduled_prompts').doc(prompt.id)
  return query.set(prompt)
    .then( () => {
      message.success('Scheduled Prompt Updated',1)
      return true
    })
    .catch( error => {
      console.log(`Error: ${error}`)
      message.error('Error Updating Scheduled Prompt, please try again')
      return error
    });
}

export async function deleteDraft(prompt){
  let query = await firebase.getCollection('prompt_drafts').doc(prompt.id)
  return query.delete()
    .then( () => {
      message.success('Draft Deleted')
      console.log(`Doc ${prompt.id} deleted`)
      return true
    })
    .catch( error => {
      message.error('Problem Deleting Draft, please try again')
      console.log(`Error deleting document: ${error}`)
      return error
    });
}

export async function deleteScheduled(prompt){
  let query = await firebase.getCollection('scheduled_prompts').doc(prompt.id)
  return query.delete()
    .then(function() {
      message.success('Scheduled Prompt Deleted')
      console.log(`Doc ${prompt.id} deleted`)
      return true
    })
    .catch(function(error) {
      message.error('Problem Deleting Scheduled Prompt, please try again')
      console.log(`Error deleting document: ${error}`)
      return error
    });
}

// export async function deleteSponsor(prompt, collection){
//   console.log(`deleting sponsor`);

//   let query = await firebase.getCollection(collection).doc(prompt.id)
//   return query.update({
//     sponsorId: window.firebase.firestore.FieldValue.delete(),
//     sponsorLogo: window.firebase.firestore.FieldValue.delete(),
//     sponsorName: window.firebase.firestore.FieldValue.delete(),
//     sponsorUrl: window.firebase.firestore.FieldValue.delete(),
//     sponsorUrlType: window.firebase.firestore.FieldValue.delete()
//   })
//     .then(function() {
//       console.log(`sponsor deleted`);
//     })
//     .catch(function(error) {
//       console.error("Error removing document: ", error);
//     });
// }

// export async function deletePromptPhoto(prompt, collection){
//   console.log(`deleting photo`);

//   let query = await firebase.getCollection(collection).doc(prompt.id)
//   return query.update({
//     photoURL: window.firebase.firestore.FieldValue.delete()
//   })
//     .then(function() {
//       console.log(`sponsor deleted`);
//     })
//     .catch(function(error) {
//       console.error("Error removing document: ", error);
//     });
// }

// export async function deleteMediaTwoUpPhoto(prompt, collection){
//   console.log(`deleting media photos`);

//   let query = await firebase.getCollection(collection).doc(prompt.id)
//   return query.update({
//     responses: window.firebase.firestore.FieldValue.delete()
//   })
//     .then(function() {
//       console.log(`sponsor deleted`);
//     })
//     .catch(function(error) {
//       console.error("Error removing document: ", error);
//     });
// }

// export function setSnackbarMessage(collection){
//   let temporaryMsg
//   if(collection === 'scheduled_prompts'){
//     temporaryMsg = 'Prompt Scheduled Successfully!'
//   }else if(collection === 'prompt_drafts'){
//     temporaryMsg = 'Prompt Added to Drafts!'
//   } else if(collection === 'prompts'){
//     temporaryMsg = 'Prompt Posted Successfully!'
//   } else if(collection === 'error'){
//     temporaryMsg = 'Error adding prompt, please try again'
//   }
//   return temporaryMsg
// } 