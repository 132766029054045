import React, { useState } from 'react';
import './EventAnalysis.scss';

// COMPONENTS
import { Col, Empty, Layout, Row, Typography } from 'antd';
import { ClipLoader } from 'react-spinners';
import ResultCardWrapper from '../../Cards/ResultCards/ResultCardWrapper_V2';
import EventStatusBar from '../../components/EventStatusBar/EventStatusBar_V2';
import NoStatementCards from '../../components/Skeletons/NoStatementCards';
// import FeedSidebar from '../../components/FeedSidebar/FeedSidebar_V2';
import { useSelector } from 'react-redux';


// FIREBASE
import { useContext } from 'react';
import PastFeedsSidebar from '../../components/FeedSidebar/PastFeedsSidebar';
import { FirebaseContext } from '../../utils/Firebase';

const { Content, Sider } = Layout;

export default function EventAnalysis (){
  const firebase = useContext(FirebaseContext)
  const activePastFeed = useSelector(state => state.publishing.activePastFeed)
  const [ prompts, setPrompts ] = useState()
  const [ isLoading, setIsLoading ] = useState(false)
  const [ collapsed, setCollapsed ] = useState(false)
  const [ isFetchingPastFeeds, setIsFetchingPastFeeds ] = useState(false)

  // GET PROMPT HISTORY
  // useEffect(() => {
  //   if(!activePastFeed || activePastFeed === 'none' ) return
  //   const unsubscribe = firebase.getPromptsRefByFeedId(activePastFeed.id)
  //     .orderBy('timestamp', 'asc')
  //     .onSnapshot(snapshot => {
  //       const promptArr = []
  //       snapshot.forEach(snap => {
  //         if(snap.exists){
  //           let doc = snap.data()
  //           // console.log('doc', doc)
  //           promptArr.push({...doc, id:snap.id})
  //         } else {
  //           console.log('No Docs')
  //         }
  //       })
  //       setPrompts(promptArr)
  //       setIsLoading(false)
  //       },err=>console.log('error',err))
  //   return () => unsubscribe
  // },[activePastFeed])

  return (
    <Layout style={{height:'100%'}}>
      {isFetchingPastFeeds
        ? 
          <Content style={clipLoaderContainerStyle}>
            <ClipLoader/>
          </Content>
        :
          activePastFeed === null
            ? 
              <Content style={{marginTop:20}}>
                <Row align='middle' justify='center' gutter={[16,16]} style={{flexDirection:'column',  padding:24, marginTop:50}}>
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={false}/>
                  <Typography.Title level={3} style={{ lineHeight:0 }}>No Past Events Selected</Typography.Title>
                  <Typography.Text>To review past event feeds, please select a feed to the right.</Typography.Text>
                </Row>
              </Content>
            :
              <Content style={{overflowY:'scroll'}}>
                <EventStatusBar pastOrFutureEvent='past'/>
                  <Row style={{ padding: 20}}>
                    <Col span={24}> 
                      <Typography.Title level={3}>Published Prompts</Typography.Title>
                      <Row gutter={[16,16]}>
                        {prompts && prompts.length > 0 
                          ?
                            prompts.map(prompt => (
                              <Col span={8} key={prompt.id}>
                                <ResultCardWrapper 
                                  feed={activePastFeed.id} 
                                  prompt={prompt} 
                                  isLoading={isLoading} 
                                  // prevPostTimestamp={prevPostTimestamp}
                                />
                              </Col>
                            ))
                          :
                            <NoStatementCards/>
                        }
                      </Row>
                    </Col>
                  </Row>
            </Content>
      }

      <Sider
        width={350} 
        collapsible 
        collapsed={collapsed}
        onCollapse={()=>setCollapsed(!collapsed)}
        theme='light'
      >
        <PastFeedsSidebar setIsFetchingPastFeeds={setIsFetchingPastFeeds}/>
      </Sider>
    </Layout>
  );
}

const clipLoaderContainerStyle = { 
  display:'flex', 
  justifyContent:'center', 
  alignItems:'center'
}
