import moment from 'moment'
import numeral from 'numeral'
import React, { useContext, useEffect, useRef, useState } from 'react'
import './StatementCards.scss'

// import ResultCardFooter from './ResultCardFooter';
import {
  CheckCircleOutlined,
  TagOutlined,
  TeamOutlined,
} from '@ant-design/icons'
import { Card, Col, Empty, Image, Row, Typography } from 'antd'
import { useSelector } from 'react-redux'
import ResultChartSwitcher from './ResultChartSwitcher.js'

import { useHistory } from 'react-router'
import PostedAdDetailsSmall from '../../Pages/AdsManager/PostedAdDetailsSmall'
import { FirebaseContext } from '../../utils/Firebase'

// FIREBASE
// import Firebase from '../../components/Firebase'
// const firebase = new Firebase()
const KisweSvg = () => (
  <svg width="1em" height="1em" fill="currentColor" viewBox="0 0 1024 711.74">
    <polygon points="819.65 0 614.05 297.64 614.05 0 0 0 0 711.74 614.05 711.74 614.05 415.2 815.1 711.74 1024 711.74 1024 0 819.65 0" />
  </svg>
)

const COLORS = [
  '#002A5C',
  'rgba(0,0,0,0.75)',
  'rgba(0,0,0,0.5)',
  'rgba(0,0,0,0.25)',
  'rgba(0,0,0,0.1)',
]
// const COLORS = ['#056669', '#065373', '#061A73', '#002A5C', '#0D0569'];
// const COLORS = ['#1D2D60', '#40CEE2', '#FFBC47', '#F65E5D'];
// const COLORS = ['rgba(0,0,0,0.9)', 'rgba(0,0,0,0.7)', 'rgba(0,0,0,0.5)', 'rgba(0,0,0,0.3)'];
const winnerColor = '#002A5C'

export default function ResultCardWrapper({
  prompt,
  isLoading,
  feed,
  prevPostTimestamp,
}) {
  const {
    timestamp,
    answerRatePercentage,
    id,
    totalUsersReached,
    responseCount,
    huddleCount,
  } = prompt
  const cardRef = useRef(null)
  const history = useHistory()
  const firebase = useContext(FirebaseContext)
  // const { FB, postToPage } = useContext(FacebookContext)
  const publisherId = useSelector(
    (state) => state.publishing.publishingAs?.id ?? 0
  )
  // const [ newPhotoUrl, setNewPhotoUrl ] = useState()
  const [imgUrl, setImgUrl] = useState()
  const [timeBetween, setTimeBetween] = useState()
  const [kisweHover, setKisweHover] = useState(false)
  const [kisweSelection, setKisweSelection] = useState()
  const [kisweColor, setKisweColor] = useState('')
  // const [ calculatedResponseCount, setCalculatedResponseCount ] = useState(0)
  const [calculatedAnswerRate, setCalculatedAnswerRate] = useState(0)

  // useEffect(()=>{
  //   if(prompt.responseCountByChoice){
  //     let resCount = 0
  //     Object.keys(prompt.responseCountByChoice).map(function(key) {
  //       resCount += prompt.responseCountByChoice[key]
  //     });
  //     setCalculatedResponseCount(resCount)
  //   }
  // },[prompt])

  useEffect(() => {
    if (prompt.responseCount && prompt.totalUsersReached) {
      setCalculatedAnswerRate(
        Math.round((prompt.responseCount / prompt.totalUsersReached) * 100)
      )
    }
  }, [prompt])

  useEffect(() => {
    if (!prevPostTimestamp) return
    if (!prompt) return

    let time = timeBetweenPosts(timestamp, prevPostTimestamp)
    setTimeBetween(time)
  }, [prevPostTimestamp, prompt, timestamp])

  useEffect(() => {
    if (!publisherId) return
    let unsubscribe = firebase
      .getCollection('kiswe')
      .doc(publisherId)
      .onSnapshot((docRef) => {
        // console.log('updating',docRef.data())
        if (docRef.exists) {
          setKisweSelection(docRef.data().promptId)
        }
      })
    return () => unsubscribe()
  }, [prompt, setKisweSelection, publisherId])

  useEffect(() => {
    if (kisweSelection === prompt.id) {
      setKisweColor('#EF483E')
    } else if (kisweHover) {
      setKisweColor('#EF483E')
    } else {
      setKisweColor('rgba(0, 0, 0, 0.45)')
    }
  }, [prompt, kisweSelection, kisweHover])

  // useEffect(()=>{
  //   if(photoURL){
  //     const httpsRef = firebase.storage.refFromURL(photoURL)
  //     httpsRef.getDownloadURL().then( url => {
  //       setNewPhotoUrl(url)
  //     })
  //   }
  // },[photoURL])

  // const onShare = async () => {
  //   console.log('building a Shareable Image..')
  //   // console.log('cardRef',cardRef)
  //   message.loading('building a Shareable Image..', 0);
  //   const input = cardRef.current
  //   html2canvas(input)
  //   .then(async (canvas) => {
  //     const base64image = canvas.toDataURL('image/png');
  //     setImgUrl(base64image)
  //     let imgUrl = await firebase.handleFirebaseImgUpload(base64image)
  //     console.log('imgUrl',imgUrl)
  //     message.destroy()
  //     console.log(FB)
  //     FB.ui({
  //       method: 'feed',
  //       // link: 'https://opinelive.com',
  //       source: imgUrl,
  //       media:[imgUrl]
  //       // picture: imgUrl
  //     }, function(response){
  //       console.log(response)
  //     });
  //   }).catch(err => {
  //     console.log('err', err)
  //   })

  // }

  // const KisweLogo = props => <Icon theme='outlined' component={KisweSvg} {...props} />;

  // const kisweShare = () => {
  //   let query = firebase.getCollection('kiswe').doc(publisherId)
  //   query.get().then(docRef => {
  //     if(docRef.exists){
  //       query.update({promptId: prompt.id})
  //     }else{
  //       query.set({promptId: prompt.id})
  //     }
  //   })
  // }

  return (
    <Card
      // hoverable={true}
      // onClick={()=>history.push(`/eventanalysis/${prompt.id}`)}
      style={{
        width: '100%',
        borderTop: `5px solid ${winnerColor}`,
        borderBottom: `5px solid ${winnerColor}`,
      }}
      bodyStyle={{ padding: 0 }}
      // cover={prompt.photoURL ? <Image src={prompt.photoURL} /> : false}
      // actions={[
      //   <FacebookFilled key="FB" style={{fontSize:24}} onClick={() => onShare()}/>,
      //   <TwitterShareButton url={`https://publisher.opinelive.com/eventanalysis/${id}`}><TwitterOutlined key="edit" style={{fontSize:24}} onClick={() => onShare()}/></TwitterShareButton>,
      //   <LinkedinShareButton url={`https://publisher.opinelive.com/eventanalysis/${id}`}><LinkedinOutlined key="edit" style={{fontSize:24}} onClick={() => onShare()}/></LinkedinShareButton>,
      //   <Button type='text' onClick={kisweShare} icon={<KisweLogo style={{fontSize:24, color: kisweColor}}/>} onMouseOver={() => setKisweHover(true)} onMouseOut={() => setKisweHover(false)}/>,
      // ]}
      // title={timeBetween
      //   ?
      //     <Typography>
      //       <Typography.Text type='secondary'>
      //         Between Posts:
      //       </Typography.Text>
      //       <Typography.Text>
      //         {` ${timeBetween}`}
      //       </Typography.Text>
      //     </Typography>
      //   :
      //     null
      //     // <Typography.Text>First Post for this Feed</Typography.Text>
      // }
      // extra={<EllipsisOutlined key="ellipsis" style={{fontSize:24}}/>}
      loading={isLoading}
    >
      <Row ref={cardRef} style={{ padding: 24 }}>
        {prompt.photoURL ? (
          <Col
            span={24}
            style={{
              width: '100%',
              marginBottom: 10,
              borderRadius: 5,
              overflow: 'hidden',
              border: `2px solid ${winnerColor}`,
            }}
          >
            <Image src={prompt.photoURL} />
          </Col>
        ) : (
          false
        )}
        <Col span={24} style={{ marginBottom: 10 }}>
          <Typography.Title level={4}>{prompt.text}</Typography.Title>
        </Col>

        <Col style={{ width: '100%' }}>
          {prompt.responseCountByChoice ? (
            <ResultChartSwitcher prompt={prompt} />
          ) : (
            <Col span={24}>
              <Empty description="No Responses yet!" />
            </Col>
          )}
        </Col>
        <Row
          gutter={[8, 12]}
          align="middle"
          style={{ color: 'rgba(0,0,0,0.4)', marginTop: 12 }}
        >
          <Col span={24}>
            <Typography.Text type="secondary">
              <CheckCircleOutlined /> Responses
            </Typography.Text>
            <br />
            <Typography.Text className="statement-card-data-title">
              Response Count: {prompt.responseCount}
            </Typography.Text>
            <br />
            <Typography.Text className="statement-card-data-title">
              Answer Rate: {calculatedAnswerRate ? calculatedAnswerRate : 0}%
            </Typography.Text>
          </Col>
          <Col span={24}>
            <Typography.Text type="secondary">
              <TeamOutlined /> Users
            </Typography.Text>
            <br />
            <Typography.Text className="statement-card-data-title">
              Users Reached: {totalUsersReached}
            </Typography.Text>
            <br />
            <Typography.Text className="statement-card-data-title">
              Huddle Count: {huddleCount}
            </Typography.Text>
          </Col>
          {prompt && prompt.ads ? (
            <Col span={24}>
              <Typography.Text type="secondary">
                <TagOutlined />
                Ads
              </Typography.Text>
              <PostedAdDetailsSmall prompt={prompt} />
            </Col>
          ) : null}
        </Row>
      </Row>
    </Card>
  )
}

// GET TIME BETWEEN POSTS
const timeBetweenPosts = (timestamp, prevPostTimeStamp) => {
  let sec, min, hour

  let postDiff = moment
    .unix(timestamp.seconds)
    .diff(moment.unix(prevPostTimeStamp.seconds))
  if (postDiff) {
    sec = moment.duration(postDiff).get('seconds')
    min = moment.duration(postDiff).get('minutes')
    hour = moment.duration(postDiff).get('hours')
  }

  if (hour && hour === 1) {
    hour = `${numeral(hour).format('0')} hr`
  } else if (hour) {
    hour = `${numeral(hour).format('0')} hr`
  }

  // if(min === 1){
  //   min = `${numeral(min).format('00')} min`
  // } else {
  //   min = `${numeral(min).format('00')} min`
  // }

  // if(sec === 1){
  //   sec = `${numeral(sec).format('00')} sec`
  // } else {
  //   sec = `${numeral(sec).format('00')} sec`
  // }

  min = `${numeral(min).format('0')} min`
  sec = `${numeral(sec).format('00')} sec`

  if (hour) {
    return `${hour} : ${min}: ${sec}`
  } else if (!hour && min) {
    return `${min} : ${sec}`
  } else if (!hour && !min) {
    return `${sec}`
  }
}
