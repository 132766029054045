import React, { useState } from 'react'
import { Row, Col, Typography, Switch, Table, Empty, Form } from 'antd'
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';

export default function AdSelectRules({form, triggers}){
  const [ selectedRules, setSelectedRules ] = useState([])

  const columns = [
    {
      title: 'Rule',
      dataIndex: 'name',
      key: 'name',
      // sorter: (a, b) => a.name.length - b.name.length,
      // sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order,
      ellipsis: true,
    },
    {
      title: 'Description',
      dataIndex: 'id',
      key: 'id',
      // sorter: (a, b) => a.age - b.age,
      // sortOrder: sortedInfo.columnKey === 'age' && sortedInfo.order,
      ellipsis: true,
    },
    {
      title: 'Enabled',
      dataIndex: 'enabled',
      align: 'right',
      key: 1,
      render: (e, record) => {
        let checked = false
        let i = selectedRules.findIndex(id => record.id === id)
        if(i>-1){
          checked = true
        }
        return(
          <Switch
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            checked={checked}
            onChange={(event) => onChange(event,record)}
          />
        )
      }
    },
  ];

  const onChange = (val, record) => {
    let rulesArr = [...selectedRules]

    if(val){
      rulesArr.push(record.id)
    }else{
      let i = rulesArr.findIndex(id => id === record.id)
      if(i > -1){
        rulesArr.splice(i,1)
      }
    }
    setSelectedRules(rulesArr)
    form.setFieldsValue({
      rules:rulesArr
    })
    console.log('after', form.getFieldValue('rules'))
  }

  return(
    <Row style={adSelectRulesStyleProps}>
      <Col span={24}>
        <Typography.Title level={4} style={{margin:0}}>Ad Triggers</Typography.Title>
        <Typography.Text type='secondary'>Select which micro-moments trigger an ad to display</Typography.Text>
      </Col>
      <Col span={24}>
        {triggers 
          ? 
            triggers.map( rule => (
              <Row key={rule.id} style={{marginTop:20}}>
                <Col style={{padding:10}}>
                  <Typography.Text strong style={{textTransform:'uppercase'}}>{`${rule.leagueObj} Triggers`}</Typography.Text>
                  <Typography.Text type='secondary' > - {`${rule.title}`}</Typography.Text>
                </Col>
                <Col span={24}>
                  <Form.Item 
                    name='rules'
                    rules={[
                      {
                        required: true,
                        message: 'Please add an ad destination',
                      },
                    ]}
                    style={{textTransform:'capitalize'}}
                  >
                    <Table 
                      rowKey={'id'}
                      pagination={false} 
                      columns={columns} 
                      dataSource={rule.publisherEnginePrompts} 
                    />
                  </Form.Item>
                </Col>
              </Row>
            ))
          :
            <Col span={24}>
              <Empty 
                image={Empty.PRESENTED_IMAGE_SIMPLE} 
                description='Select An Ad Set to See Triggers'
              />
            </Col>
        }
      </Col>
    </Row>
  )
}

const adSelectRulesStyleProps = {
  width:'100%',
  background:'white', 
  borderRadius:8, 
  boxShadow:'0px 1px 2px rgba(0,0,0,0.25)', 
  paddingTop: 15,
  paddingBottom: 20,
  paddingLeft: 20,
  paddingRight: 20,
  marginTop:20
}