import { DeleteTwoTone, EditTwoTone, ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Col, Modal, Row, Table, Tag } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import eventsList from '../../data/eventList.json'
import AdSetModal from '../../modals/AdManagerModals/AdSetModal'
import { FirebaseContext } from '../../utils/Firebase'

const { confirm } = Modal;

export default function AdSets({ adSets, selectedCampaigns, campaigns, setSelectedAdSets, audiences }) {
  const firebase = useContext(FirebaseContext)
  const publisherId = useSelector(
    (state) => state.publishing.publishingAs?.id ?? 0
  )
  const [ filteredAdSets, setFilteredAdSets ] = useState()
  const [ modalOpen, setModalOpen ] = useState(false)
  const [ selectedAdSet, setSelectedAdSet ] = useState()
  const [ modalType, setModalType ] = useState()

  const columns = [
    // {
    //   title: 'On/Off',
    //   dataIndex: 'active',
    //   key: 'active',
    //   // align: 'right',
    //   width:40,
    //   render: (text, record) => (
    //     <Switch size='small' checked={record.active} onChange={() => handleActiveChange(record)}/>
    //   ),
    // },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Audience',
      dataIndex: ['audience','name'],
      key: ['audience','name'],
    },
    // {
    //   title: 'Duration',
    //   dataIndex: 'adSetDuration',
    //   key: 'adSetDuration',
    //   // align:'right',
    //   render: (text, record) => {
    //     if(record.duration){
    //       return <Tag key={record.duration}>{record.duration}</Tag>
    //     }else{
    //       return <Tag key={'all'}>Unset</Tag>
    //     }
    //   }
    // },
    {
      title: 'Event Types',
      dataIndex: 'eventTypes',
      key: 'eventTypes',
      // align: 'right',
      render: (text, record) => {
        if(record.sport){
          return <Tag key={record}>{record}</Tag>
        }else{
          return <Tag key={'all'}>All</Tag>
        }
      }
    },
    {
      title: 'Leagues',
      dataIndex: 'leagues',
      key: 'leagues',
      // align: 'right',
      render: (text, record) => {
        let leagues = record.leagues
        if(leagues){
          if(leagues.length > 0){
            return leagues.map(league => {
              return <Tag key={league.name}>{league.name}</Tag>
            })
          }else{
            return <Tag key={'all'}>All</Tag>
          }
        }else{
          return <Tag key={'all'}>All</Tag>
        }
      }
    },
    {
      title: 'Teams',
      dataIndex: 'teams',
      key: 'teams',
      // align: 'right',
      render: (text, record) => {
        let teams = record.teams
        if(teams){
          if(teams.length > 0){
            return teams.map(team => {
              return <Tag key={team.id}>{team.name}</Tag>
            })
          }else{
            return <Tag key={'all'}>All</Tag>
          }
        }else{
          return <Tag key={'all'}>All</Tag>
        }
      }
    },
    {
      title: 'Events',
      dataIndex: 'events',
      key: 'events',
      // align: 'right',
      render: (text, record) => {
        let events = record.events
        if(events){
          if(events.length > 0){
            return events.map(event => {
              return <Tag key={event.id}>{event}</Tag>
            })
          }else{
            return <Tag key={'all'}>All</Tag>
          }
        }else{
          return <Tag key={'all'}>All</Tag>
        }
      }
    },
    {
      title: 'Feeds',
      dataIndex: 'feeds',
      key: 'feeds',
      // align: 'right',
      render: (text, record) => {
        let feeds = record.feeds
        if(feeds){
          if(feeds.length > 0){
            return feeds.map(feed => {
              return <Tag key={feed.id}>{feed.title}</Tag>
            })
          }else{
            return <Tag key={'all'}>All</Tag>
          }
        }else{
          return <Tag key={'all'}>All</Tag>
        }
      }
    },
    // {
    //   title: 'Budget',
    //   dataIndex: 'budget',
    //   key: 'budget',
    //   align: 'right'
    // },
    // {
    //   title: 'Results',
    //   dataIndex: 'campaignResults',
    //   key: 'campaignResults',
    //   align: 'right',
    // },
    // {
    //   title: 'Reach',
    //   dataIndex: 'campaignReach',
    //   key: 'campaignReach',
    //   align: 'right',
    // },
    // {
    //   title: 'Impressions',
    //   dataIndex: 'campaignImpressions',
    //   key: 'campaignImpressions',
    //   align: 'right',
    // },
    // {
    //   title: 'Cost Per Result',
    //   dataIndex: 'campaignCostPerResult',
    //   key: 'campaignCostPerResult',
    //   align: 'right',
    // },
    // {
    //   title: 'Schedule',
    //   dataIndex: 'schedule',
    //   key: 'schedule',
    //   align: 'right',
    // },
    {
      title: '',
      key: 'action',
      align: 'right',
      width:96,
      render: (text, record) => (
        <Row>
          <Button type='text' onClick={()=>handleModalOpen('edit', record)} icon={<EditTwoTone/>}/>
          <Button type='text' danger onClick={()=>handleDeleteAdSet(record)} icon={<DeleteTwoTone twoToneColor='red'/>}/>
        </Row>
      ),
    },
  ]

  const findAudiences = (arr1, arr2) => {
    let newArr = arr1.map(adSet => {
      if(!adSet.audienceId)return adSet
      let audienceIndex = arr2.findIndex(audience => audience.id === adSet.audienceId)
      adSet = {...adSet, audience:arr2[audienceIndex] }
      return adSet
    })
    return newArr
  }

  useEffect(()=>{
    let newArr = [...adSets]
    if(adSets && audiences && adSets.length > 0 && audiences.length > 0){
      newArr = findAudiences(adSets,audiences)
    }    
    if(selectedCampaigns.length < 1){
      setFilteredAdSets(newArr)
    }else{
      let filteredArr = []
      selectedCampaigns.map(campaignDoc => {
        let results = adSets.filter(adSetDoc => {
          return adSetDoc.campaignId === campaignDoc.id
        })
        results.map(filteredAdSet => {
          filteredArr.push(filteredAdSet)
        })
      })
      let arr = findAudiences(filteredArr, audiences)
      setFilteredAdSets(arr)
    }
  },[selectedCampaigns,adSets,audiences])

  // const handleActiveChange = (record) => {
  //   const query = firebase.getCollection('publisher').doc(publisherId).collection('ad-sets').doc(record.id)
  //   query.update({active: !record.active})
  // }

  const handleCheck = (selectedRowKeys, selectedRows) => {
    setSelectedAdSets(selectedRows)
  }

  const handleModalCancel = () => {
    setSelectedAdSet(null)
    setModalOpen(false)
  }

  const handleModalOpen = (type, record) => {
    setModalType(type)
    if(record){
      setSelectedAdSet({...record,availableEvents:eventsList})
    }else{
      setSelectedAdSet({
        availableEvents:eventsList,
        name:'',
        leagues:[],
        teams:[],
        events:[],
        feeds:[],
        campaignId:'',
        manualAd: 'manual',
        audience: {
          name:'',
          filters:[]
        }
      })
    }
    setModalOpen(true)
  }

  const handleDeleteAdSet = async (record) => {
    confirm({
      title: `Are you sure you want to delete this ad set?`,
      icon: <ExclamationCircleOutlined />,
      content: 'Deleting ad sets also delete all linked ads. This cannot be undone.',
      okText:'Yes',
      onOk() {
        firebase.getCollection('publisher').doc(publisherId).collection('ad-sets').doc(record.id).delete()
      },
      onCancel() {
        // console.log('Cancel');
      },
    });
  }

  return(
    <Row>
      <Col span={24}>
        <Row justify="space-between" align="middle">
          <Col>
            <Button type='primary' icon={<PlusOutlined/>} onClick={()=>handleModalOpen('new',null)}>Create New Ad Set</Button>
          </Col>
        </Row>
      </Col>
      <Col span={24} style={{marginTop: 20}}>
        <Table 
          rowSelection={{
            type:'checkbox',
            onChange: (selectedRowKeys, selectedRows) => handleCheck(selectedRowKeys, selectedRows),
          }}
          columns={columns} 
          dataSource={filteredAdSets} 
          rowKey="name"
        />
      </Col>
      <Modal
        title={modalType === 'new' ? 'New Ad Set' : 'Edit Ad Set'}
        visible={modalOpen}
        centered={true}
        width={1000}
        bodyStyle={{background:'#f0f2f5'}}
        onCancel={handleModalCancel}
        destroyOnClose
        footer={[
          <Row justify='end'>
            <Button onClick={handleModalCancel}>Cancel</Button>
            <Button type='primary' form='newAdSetForm' htmlType='submit'>Submit</Button>
          </Row>
        ]}
      >
        <AdSetModal
          selectedAdSet={selectedAdSet}
          setSelectedAdSet={setSelectedAdSet}
          selectedCampaigns={selectedCampaigns}
          campaigns={campaigns}
          audiences={audiences}
          setModalOpen={setModalOpen}
        />
      </Modal>
    </Row>
  )
}

let tips = []

const calcTip = (bill) => {
  let tip
  if(bill >= 50 && bill <+ 300){
    tip = bill * .15 
  }else{
    tip = bill * .20
  }
  return tip
}

tips.push(calcTip(125))
tips.push(calcTip(555))
tips.push(calcTip(44))
