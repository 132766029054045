import { Row } from 'antd';
import React, { useEffect } from 'react';
import AdCreative from '../../components/NewAdComponents/AdCreativeComponentV2';
import CreateNewAudience from '../AdManagerModals/CreateNewAudienceModalV2';

export default function CreateNewAdModal({
  setFormValid,
  adObj,
  setAdObj,
  form
}){

  useEffect(()=>{
    if(!adObj.sponsorLogo || !adObj.callOutText || !adObj.sponsorUrlType ){
      setFormValid(false)
    }else{
      setFormValid(true)
    }
  },[adObj])

  return(
    <Row gutter={[16,16]}>
      <AdCreative
        adObj={adObj}
        setAdObj={setAdObj}
        form={form}
      />
      <CreateNewAudience
        adObj={adObj}
        setAdObj={setAdObj}
        setFormValid={setFormValid}
      />
    </Row>
  )
}
