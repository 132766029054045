import { message } from 'antd'
import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import {
  getPublisherPastFeeds,
  getPublisherUpcomingFeeds,
  getPublishingAs,
} from '../../helpers/Firebase'
import {
  setCurrentFeedToLocalStorage,
  setCurrentFeedsListToLocalStorage,
  setPastFeedToLocalStorage,
  setPastFeedsListToLocalStorage,
  setToLocalStorage,
} from '../../helpers/Utils'

import {
  LOGIN_USER_SUCCESS,
  SET_PAST_FEEDS_LIST,
  SWITCH_ACTIVE_EVENT,
  SWITCH_CURRENT_FEED,
  SWITCH_CURRENT_FEEDS_LIST,
  SWITCH_PAST_FEED,
  SWITCH_PUBLISHING_AS,
} from '../constants'

import {
  setCurrentFeedsListSuccess,
  setPastFeedsListSuccess,
  switchActiveEventSuccess,
  switchCurrentFeedSuccess,
  switchPastFeedSuccess,
  switchPublishingAsSuccess,
} from './actions'

export function* watchLogin() {
  yield takeEvery(LOGIN_USER_SUCCESS, getPublisherInfoOnLogin)
}

function* getPublisherInfoOnLogin({ payload }) {
  let publisher = { ...payload.user, publishingAs: payload.user.id }
  const { publishingAs } = publisher
  setToLocalStorage('opine_publishing_as', publisher)
  yield put(switchPublishingAsSuccess(publisher))
  try {
    console.log('fetching feeds')
    // eslint-disable-next-line no-use-before-define
    const feeds = yield call(switchPublishingAsAsync, publishingAs)
    if (feeds) {
      console.log('feeds', feeds)
      if (feeds[0] && feeds[0] !== null && feeds[0].length > 0) {
        setPastFeedToLocalStorage(feeds[0][0])
        yield put(switchPastFeedSuccess(feeds[0][0]))
      } else {
        setPastFeedToLocalStorage(null)
        yield put(switchPastFeedSuccess(null))
      }
      if (feeds[1] && feeds[1] !== null && feeds[1].length > 0) {
        setCurrentFeedToLocalStorage(feeds[1][0])
        yield put(switchCurrentFeedSuccess(feeds[1][0]))
      } else {
        setCurrentFeedToLocalStorage(null)
        yield put(switchCurrentFeedSuccess(null))
      }
      setPastFeedsListToLocalStorage(feeds[0])
      setCurrentFeedsListToLocalStorage(feeds[1])
      yield put(setPastFeedsListSuccess(feeds[0]))
      yield put(setCurrentFeedsListSuccess(feeds[1]))
    }
  } catch (err) {
    console.log('Feeds Error:', err)
  }
}

export function* watchSwitchPublishingAs() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(SWITCH_PUBLISHING_AS, switchPublishingAs)
}

function* switchPublishingAs({ payload }) {
  // eslint-disable-next-line no-use-before-define
  const { publishingAs } = payload.user
  try {
    const res = yield call(switchPublishingAsAsync, publishingAs)
    if (res) {
      console.log('success', res)
      setToLocalStorage('opine_publishing_as', res[0])
      setToLocalStorage('opine_current_feeds_list', res[1] ?? [])
      setToLocalStorage('opine_past_feeds_list', res[2] ?? [])
      if (res[1] && res[1].length > 0) {
        setToLocalStorage('opine_active_current_feed', res[1][0])
      }
      if (res[2] && res[2].length > 0) {
        setToLocalStorage('opine_active_current_feed', res[2][0])
      }
      yield put(switchPublishingAsSuccess(res[0]))
      yield put(setCurrentFeedsListSuccess(res[1]))
      yield put(setPastFeedsListSuccess(res[2]))
    }
  } catch (error) {
    console.log('publisher Error:', error)
    message.error(error)
  }
}

const switchPublishingAsAsync = async (publisherId) => {
  try {
    const res = []
    res[0] = await getPublishingAs(publisherId) // This is causing error in next methods because publisher loads late and next methods access publisherId
    res[1] = await getPublisherUpcomingFeeds(publisherId)
    res[2] = await getPublisherPastFeeds(publisherId)

    console.log('success publishingAs', res)
    return res
  } catch (err) {
    console.log('feed error ->', err)
    message.error(err.message)
  }
}

export function* watchCurrentFeed() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(SWITCH_CURRENT_FEED, switchCurrentFeed)
}

function* switchCurrentFeed({ payload }) {
  const { feed } = payload
  try {
    // const feed = yield call(switchCurrentFeedAsync, feedId)
    if (feed) {
      setCurrentFeedToLocalStorage(feed)
      yield put(switchCurrentFeedSuccess(feed))
    }
  } catch (error) {}
}

export function* watchPastFeed() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(SWITCH_PAST_FEED, switchPastFeed)
}

function* switchPastFeed({ payload }) {
  const { feed } = payload
  // console.log('switchPastFeed payload', payload)
  try {
    // const feed = yield call(switchPastFeedAsync, feed.id)
    if (feed) {
      setPastFeedToLocalStorage(feed)
      yield put(switchPastFeedSuccess(feed))
    }
  } catch (error) {}
}

export function* watchCurrentFeedsList() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(SWITCH_CURRENT_FEEDS_LIST, switchCurrentFeedsList)
}

function* switchCurrentFeedsList({ payload }) {
  try {
    let feedsArr =
      payload.feedsArr ?? getCurrentFeedsListAsync(payload.publisherId) ?? []
    if (feedsArr) {
      setCurrentFeedsListToLocalStorage(feedsArr)
      yield put(setCurrentFeedsListSuccess(feedsArr))
    }
  } catch (error) {}
}

const getCurrentFeedsListAsync = async (publisherId) => {
  await getPublisherUpcomingFeeds(publisherId)
}

export function* watchPastFeedsList() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(SET_PAST_FEEDS_LIST, setPastFeedsList)
}

function* setPastFeedsList({ payload }) {
  const { feedsArr } = payload
  try {
    if (feedsArr) {
      // setPastFeed(feedArr);
      setPastFeedsListToLocalStorage(feedsArr)
      yield put(setPastFeedsListSuccess(feedsArr))
    }
  } catch (error) {}
}

export function* watchActiveEvent() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(SWITCH_ACTIVE_EVENT, switchActiveEvent)
}

function* switchActiveEvent({ payload }) {
  yield put(switchActiveEventSuccess(payload.event))
}

export default function* rootSaga() {
  yield all([
    fork(watchSwitchPublishingAs),
    fork(watchCurrentFeed),
    fork(watchPastFeed),
    fork(watchPastFeedsList),
    fork(watchCurrentFeedsList),
    fork(watchLogin),
    fork(watchActiveEvent),
  ])
}
